import { FC } from "react";
import { useFormikContext } from "formik";

import Dropdown, { DropdownPropsNew } from "common/components/atoms/Dropdown/Dropdown";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { fields as f, StakeholderFormValues } from "../Stakeholder.types";

const t = createTranslation(TranslationNS.common, "molecules.stakeholder.fields");

type StakeholderFirstNameFieldProps = Omit<DropdownPropsNew, "onChange" | "options"> & {};
const StakeholderCountryField: FC<StakeholderFirstNameFieldProps> = (props) => {
  const countries = useStoreState((state) => state.common.dropdowns?.countries || []);

  const { values, errors, touched, handleChange, handleBlur } = useFormikContext<StakeholderFormValues>();

  const prefix = values.isCompanyOwned ? "isCompany." : "";
  return (
    <Dropdown
      {...props}
      isSearchable
      label={t(`${prefix}country`)}
      placeholder={t("notSelected")}
      options={countries}
      optionsIsObject
      selectedValue={values.countryId}
      error={errors.countryId}
      isTouched={touched.countryId}
      name={f.countryId}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};

export default StakeholderCountryField;
