import { FC, MouseEventHandler, useCallback, useMemo } from "react";
import cn from "classnames";
import { motion } from "framer-motion";
import { equals } from "ramda";

import { LinkHelpText, NewAvatar, Tooltip, Ui } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { ActiveUserBudgeIcon, ExclamationMarkCircle } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { createTranslation, TranslationNS } from "translation";

import { StakeholderInvitationStatus } from "../../../stakeholders-management/types";
import { CapTableServiceGetResponse } from "../../CapTable.types";
import ShareholderDetailsModal from "../../common/ShareholderDetailsModal/ShareholderDetailsModal";
import CapTableStore from "../../store";
import classes from "./Table.module.scss";

const t = createTranslation(TranslationNS.pages, "company.capTable.table.common");

export type TableRowProps = {
  data: CapTableServiceGetResponse["capTable"][number];
};

const Row: FC<TableRowProps> = ({ data }) => {
  const fNumber = useFormatNumbers();

  const { actualData, selectedRowData } = CapTableStore.useStoreState((state) => state);
  const { setSelectedRowData } = CapTableStore.useStoreActions((actions) => actions);

  const isRowSelected = useMemo(() => equals(data, selectedRowData), [data, selectedRowData]);

  const selectRow: MouseEventHandler<HTMLTableRowElement> = useCallback(
    (e) => {
      const innerTarget = e.target as any;

      if (
        innerTarget.dataset.testid === "plan-dropdown-btn" ||
        innerTarget.nodeName === "circle" ||
        innerTarget.nodeName === "svg"
      ) {
        return;
      }
      setSelectedRowData(isRowSelected ? undefined : data);
    },
    [data, isRowSelected, setSelectedRowData]
  );

  const handleClose = useCallback(() => {
    setSelectedRowData(undefined);
  }, [setSelectedRowData]);

  return (
    <>
      <motion.tr
        className={cn(classes.tableBorder, {
          [classes.company]: isRowSelected && data.isCompanyOwned,
        })}
        initial={{
          opacity: 0,
          x: -20,
        }}
        animate={{ opacity: 1, x: 0, y: 0 }}
        exit={{ opacity: 0, y: -50 }}
        transition={{ duration: 0.5 }}
        onClick={selectRow}
      >
        <td colSpan={3} style={{ width: "25%" }}>
          <div className="d-flex align-items-center">
            <NewAvatar
              imageUrl={data.avatarFilePath}
              company={data.isCompanyOwned}
              initials={data.initials}
              className={classes.avatar}
            />

            <div className="ms-2">
              <div className="d-flex align-items-center">
                <Ui.s bold className="me-1">
                  {data.shareholderName || "-"}
                </Ui.s>
                {data.invitationStatus === StakeholderInvitationStatus.active && (
                  <Tooltip popupContent={<div>Registered user</div>}>
                    <ActiveUserBudgeIcon fontSize={16} />
                  </Tooltip>
                )}
              </div>

              {data.isCompanyOwned ? (
                <Ui.s style={{ color: scssVariables.foregroundLow }}>{data.representedBy}</Ui.s>
              ) : null}
            </div>
          </div>
        </td>

        <td className="ui-s">{data.shareClasses.join(", ")}</td>

        <td className="ui-s">{data.relationship}</td>

        <td className="ui-s text-end">
          {data.hasPledges ? (
            <LinkHelpText
              title=""
              placement="top"
              className="me-1"
              overlayClass={classes["pledge-info"]}
              content={<Ui.xs bold>{t("pledgedShares")}</Ui.xs>}
            >
              <ExclamationMarkCircle className="me-0" width={16} height={16} color={scssVariables.foregroundLow} />
            </LinkHelpText>
          ) : null}

          {fNumber(data.shares, "amount")}
        </td>

        <td className="ui-s text-end">{fNumber(data.ownershipPercentage, "percent")}</td>

        <td className="ui-s text-end position-relative" style={{ width: "17%" }}>
          {fNumber(data.totalValue, "value")}
        </td>
      </motion.tr>

      <ShareholderDetailsModal
        show={Boolean(isRowSelected && selectedRowData)}
        type="actual"
        stakeholderId={data.stakeholderId}
        handleClose={handleClose}
      />
    </>
  );
};

export default Row;
