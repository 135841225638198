import { FC } from "react";
import cn from "classnames";

import { Transaction, TransactionCategory } from "../../../types";
import CapitalIncreaseHeader from "../transaction-item/transaction-headers/capital-inscrease-header/capital-increase-header";
import IssueSharesAndBuySellHeader from "../transaction-item/transaction-headers/issue-shares-and-buy-sell-header";
import classes from "./mini-transaction-item.module.scss";

type PropsTypes = {
  transaction: Transaction;
};
const MiniTransactionItem: FC<PropsTypes> = ({ transaction }) => {
  const isIssueOrBuySell =
    transaction.categoryId === TransactionCategory.Issue || transaction.categoryId === TransactionCategory.Sell;

  const isBundle = transaction.categoryId === TransactionCategory.Bundle;

  return (
    <div className={cn(classes.lightBackground, "py-2 px-3")}>
      {isIssueOrBuySell && (
        <IssueSharesAndBuySellHeader transaction={transaction} isMini className="d-flex flex-column" />
      )}
      {isBundle && <CapitalIncreaseHeader transaction={transaction} />}
    </div>
  );
};

export default MiniTransactionItem;
