import axios from "axios";
import { action, thunk } from "easy-peasy";
import { isEmpty } from "ramda";

import { CompanyModel, SigningManagerType } from "./types";

const apiAttractBase = "/api/attract";

export const company: CompanyModel = {
  // selectors
  isCompanyMenuOpen: true,
  generalInfo: null,
  bankInformation: null,
  pitch: null,
  funding: null,
  team: null,
  createdCompany: null,
  contactPersonDropDown: null,
  investors: null,
  currency: {
    id: 1,
    name: "Norwegian Krone",
    symbol: "kr",
  },
  ownershipOverview: null,
  ownershipPlans: null,
  poolsAndPrograms: null,
  HubEvents: null,
  HubCompanyList: null,
  HubCompanyDetails: null,
  valuation: null,
  isLoading: false,
  companySigningManagers: null,
  // actions
  setIsCompanyMenuOpen: action((state, payload) => {
    state.isCompanyMenuOpen = payload;
  }),
  setIsLoading: action((state, payload) => {
    state.isLoading = payload;
  }),
  setGeneralInfo: action((state, payload) => {
    state.generalInfo = payload;
  }),
  setBankInformation: action((state, payload) => {
    state.bankInformation = payload;
  }),
  setCompanyLogo: action((state, payload) => {
    if (state.generalInfo) {
      state.generalInfo.companyLogo = payload;
    }
  }),
  setPitch: action((state, payload) => {
    state.pitch = payload;
  }),
  setFunding: action((state, payload) => {
    state.funding = payload;
  }),
  setContactPersonDropDown: action((state, payload) => {
    state.contactPersonDropDown = payload.map((person) => {
      if (!isEmpty(person.firstName)) {
        return {
          name: `${person.firstName} ${person.lastName}`,
          teamMemberId: person.teamMemberId,
        };
      } else {
        return {
          name: person.email,
          teamMemberId: person.teamMemberId,
        };
      }
    });
  }),
  setTeam: action((state, payload) => {
    state.team = payload;
  }),
  setCreatedCompany: action((state, payload) => {
    state.createdCompany = payload;
  }),
  setInvestors: action((state, payload) => {
    state.investors = payload;
  }),
  setCurrency: action((state, payload) => {
    state.currency = payload;
  }),
  setOwnershipPlans: action((state, payload) => {
    state.ownershipPlans = payload;
  }),
  setOwnershipOverview: action((state, payload) => {
    state.ownershipOverview = payload;
  }),
  setPoolsAndPrograms: action((state, payload) => {
    state.poolsAndPrograms = payload;
  }),
  setHubEvents: action((state, payload) => {
    state.HubEvents = payload;
  }),
  setHubCompanyList: action((state, payload) => {
    state.HubCompanyList = payload;
  }),
  setHubCompanyDetails: action((state, payload) => {
    state.HubCompanyDetails = payload;
  }),
  setValuation: action((state, payload) => {
    state.valuation = payload;
  }),
  setCompanySigningManagers: action((state, payload) => {
    state.companySigningManagers = payload;
  }),
  //thunks
  getGeneralInfoThunk: thunk(async (actions, id) => {
    try {
      const request = await axios.get(`${apiAttractBase}/general-information/${id}`);

      if (request.status === 200) {
        actions.setGeneralInfo(request.data);
        if (request.data.logoFileDownloadPath) {
          actions.setCompanyLogo(process.env.REACT_APP_BLOB_PUBLIC_URL + request.data.logoFileDownloadPath);
        }
      }
    } catch (e) {
      console.warn(JSON.parse(JSON.stringify(e)));
    }
  }),
  getPitchThunk: thunk(async (actions, id) => {
    try {
      const request = await axios.get(`${apiAttractBase}/pitch/${id}`);

      if (request.status === 200) {
        actions.setPitch(request.data);
      }
    } catch (e) {
      console.warn(JSON.parse(JSON.stringify(e)));
    }
  }),
  getFundingThunk: thunk(async (actions, id) => {
    try {
      const request = await axios.get(`${apiAttractBase}/funding/${id}`);

      if (request.status === 200) {
        actions.setFunding(request.data);
      }
    } catch (e) {
      console.warn(JSON.parse(JSON.stringify(e)));
    }
  }),
  getTeamThunk: thunk(async (actions, id) => {
    try {
      const request = await axios.get(`${apiAttractBase}/team/${id}`);

      if (request.status === 200) {
        actions.setTeam(request.data);
      }
    } catch (e) {
      console.warn(JSON.parse(JSON.stringify(e)));
    }
  }),
  getInvestorsThunk: thunk(async (actions, id) => {
    try {
      const request = await axios.get(`${apiAttractBase}/investors/${id}`);

      if (request.status === 200) {
        actions.setInvestors(request.data);
      }
    } catch (e) {
      console.warn(JSON.parse(JSON.stringify(e)));
    }
  }),
  getOwnershipOverviewThunk: thunk(async (actions, id) => {
    try {
      actions.setIsLoading(true);
      const request = await axios.get(`/api/ownership/ownership-overview/${id}`);

      if (request.status === 200) {
        actions.setOwnershipOverview(request.data);
      }
    } catch (e) {
      console.warn(JSON.parse(JSON.stringify(e)));
    } finally {
      actions.setIsLoading(false);
    }
  }),
  getOwnershipPlansThunk: thunk(async (actions, id) => {
    try {
      actions.setIsLoading(true);
      const request = await axios.get(`/api/ownership/ownership-plan/manage/${id}`);

      if (request.status === 200) {
        actions.setOwnershipPlans(request.data);
      }
    } catch (e) {
      console.warn(JSON.parse(JSON.stringify(e)));
    } finally {
      actions.setIsLoading(false);
    }
  }),
  getPoolsAndProgramsThunk: thunk(async (actions, id, { getStoreState }) => {
    try {
      actions.setIsLoading(true);
      const request = await axios.get(`/api/pools-and-programs/${id || getStoreState().activeCompanyModel?.companyId}`);

      if (request.status === 200) {
        actions.setPoolsAndPrograms(request.data);
      }
    } catch (e) {
      console.warn(JSON.parse(JSON.stringify(e)));
    } finally {
      actions.setIsLoading(false);
    }
  }),
  getHubEventsThunk: thunk(async (actions, id) => {
    try {
      const request = await axios.get(`/api/hub/eventsForCompany/${id}`);

      if (request.status === 200) {
        actions.setHubEvents(request.data?.hubEvents);
      }
    } catch (e) {
      console.warn(JSON.parse(JSON.stringify(e)));
    }
  }),
  getHubCompanyListThunk: thunk(async (actions, id) => {
    try {
      const request = await axios.get(`/api/hub/event/${id}`);

      if (request.status === 200) {
        actions.setHubCompanyList(request.data);
      }
    } catch (e) {
      console.warn(JSON.parse(JSON.stringify(e)));
    }
  }),
  getHubCompanyDetailsThunk: thunk(async (actions, id) => {
    try {
      const request = await axios.get(`${apiAttractBase}/company-details/${id}`);

      if (request.status === 200) {
        actions.setHubCompanyDetails(request.data);
      }
    } catch (e) {
      console.warn(JSON.parse(JSON.stringify(e)));
    }
  }),
  getValuationThunk: thunk(async (actions, id) => {
    try {
      const request = await axios.get(`/api/company/valuation/${id}`);

      if (request.status === 200) {
        actions.setValuation(request.data);

        return request.data;
      }
    } catch (e) {
      console.warn(JSON.parse(JSON.stringify(e)));
    }
  }),
  deleteDraftProgramThunk: thunk(async (actions, { programId }) => {
    actions.setIsLoading(true);
    try {
      const response = await axios.delete(`/api/program/${programId}`);

      if (response.status === 200) {
        await actions.getPoolsAndProgramsThunk();
      }
    } catch (e) {
      console.warn(JSON.parse(JSON.stringify(e)));
    } finally {
      actions.setIsLoading(false);
    }
  }),
  getBankInformationThunk: thunk(async (actions, companyId, { fail }) => {
    try {
      actions.setIsLoading(true);
      const request = await axios.get(`/api/ownership/bank-information/${companyId}`);
      actions.setBankInformation(request.data);
      return request.data;
    } catch (e) {
      fail(e);
      throw e;
    } finally {
      actions.setIsLoading(false);
    }
  }),
  setBankInformationThunk: thunk(async (actions, payload, { fail }) => {
    try {
      actions.setIsLoading(true);
      await axios.post("/api/ownership/bank-information", payload);
    } catch (e) {
      fail(e);
      throw e;
    } finally {
      actions.setIsLoading(false);
    }
  }),
  getCompanySigningManagersThunk: thunk(async (actions, id, { fail }) => {
    try {
      // actions.setIsLoading(true);
      const request = await axios.get<{ managers?: SigningManagerType[] }>(`/api/ownership/plan/managers/${id}`);

      if (request.status === 200) {
        actions.setCompanySigningManagers(request.data?.managers || []);
      }
    } catch (e) {
      fail(e);
      throw e;
    } finally {
      // actions.setIsLoading(false);
    }
  }),
};
