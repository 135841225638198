import { useCallback } from "react";

import { Collapsible, Ui } from "common/components/atoms";
import { ChevronDownIcon } from "common/icons/svg";
import { CollapsibleHeaderProps } from "common/types/Collapsible.types";
import { createTranslation, TranslationNS } from "translation";

import RSAAgreementContext from "../../../contexts/RSAAgreementContext";
import PlanDetailsContent from "./PlanDetails/PlanDetailsContent";
import classes from "./RSAAgreementDetails.module.scss";

/**
 * Description: so agreement details part, just UI component to show/hide agreement details
 */

const t = createTranslation(TranslationNS.common, "organisms.agreement.agreementDetails");

const RSAAgreementDetails = () => {
  const { agreementDetails } = RSAAgreementContext.useStoreState((state) => state);

  const Header = useCallback(({ activeEventKey, onClick }: CollapsibleHeaderProps) => {
    return (
      <div className={classes["collapsible-head"]} onClick={onClick}>
        <div>
          <Ui.l bold>{t("title")}</Ui.l>

          <Ui.s color="foregroundLow" className="mt-1">
            If the agreement details below differ from the signed agreement, the signed agreement will take precedence.
          </Ui.s>
        </div>

        <ChevronDownIcon fontSize={24} className={classes["chevron"]} direction={activeEventKey ? "top" : "bottom"} />
      </div>
    );
  }, []);

  return (
    <Collapsible defaultOpen withoutDivider classNames="my-3" Header={Header}>
      <PlanDetailsContent
        planDetails={
          agreementDetails
            ? {
                ...agreementDetails,
                purchasePricePerShare: agreementDetails?.purchasePrice || 0,
                shareClassName: agreementDetails.shareClass || "",
                pricePerShare: agreementDetails?.sharePrice || 0,
                shareMarketValue: agreementDetails?.totalSharesValue || 0,
                vestingCliff: agreementDetails?.cliff || 0,
                vestingStartsAt: agreementDetails?.planStartDate,
                vestingEndsAt: agreementDetails?.planEndDate,
                vestingType: "Reverse vesting",
                currencyId: agreementDetails?.currencyId,
              }
            : null
        }
      />
    </Collapsible>
  );
};

export default RSAAgreementDetails;
