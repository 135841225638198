import { FC, useMemo, useState } from "react";
import { useFormik } from "formik";
import * as R from "ramda";

import Button from "common/components/atoms/Button/Button";
import Check from "common/components/atoms/Checks/Check";
import Dropdown from "common/components/atoms/Dropdown/Dropdown";
import PageContent from "common/layout/MainLayout/PageContent/PageContent";
import { useStoreState } from "store/store";
import { createTranslation } from "translation/helpers";

import classes from "./notifications.module.scss";

const translation = createTranslation("pages", "user.notifications");

const UserNotificationPage: FC = () => {
  const [notificationsChecked, setNotificationsChecked] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const notificationBundleDropDown = useStoreState((state) => state.common.dropdowns)?.notificationBundles;
  const { profile } = useStoreState((state) => state.account);

  async function submitHandler() {
    setIsLoading(true);
    await changeNotificationSettings();
    setIsLoading(false);
  }

  async function changeNotificationSettings() {}

  const initialValues = useMemo(
    () => ({
      notificationBundlingId: R.defaultTo(0, profile?.countryOfResidenceId),
    }),
    [profile?.countryOfResidenceId]
  );

  const { values, handleSubmit, setFieldValue } = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: submitHandler,
  });

  return (
    <PageContent>
      {translation("title")}
      <div className={classes["notifications"]}>
        <h4 className="head-xxs mb-3">{translation("notificationInformation.title")}</h4>
        <p className="paragraph-s mb-5" style={{ opacity: 0.8 }}>
          {translation("notificationInformation.subtitle")}
        </p>
        <div className="ps-3 mb-6">
          <Check
            checked={notificationsChecked}
            label={translation("notificationInformation.onEmail")}
            onChange={(e) => {
              setNotificationsChecked(e.target.checked);
            }}
          />
        </div>

        <Dropdown
          style={{ minWidth: "320px" }} // TODO WIDTH
          className="mb-10"
          label={translation("notificationInformation.bundle")}
          selectedValue={
            /* istanbul ignore next */
            !R.isNil(notificationBundleDropDown)
              ? notificationBundleDropDown?.find((el) => el.id === values?.notificationBundlingId)?.name
              : ""
          }
          onChange={(bundling) => {
            const selectedBundling = notificationBundleDropDown?.find((el) => el.name === bundling);

            setFieldValue("notificationBundlingId", selectedBundling?.id);
          }}
          options={
            /* istanbul ignore next */
            !R.isNil(notificationBundleDropDown) ? notificationBundleDropDown?.map((el) => el.name) : []
          }
        />

        <div className="d-flex flex-column" style={{ width: 120 }}>
          <Button isDisabled={isLoading} isLoading={isLoading} onClick={() => handleSubmit()}>
            {translation("notificationInformation.save")}
          </Button>
        </div>
      </div>
    </PageContent>
  );
};

export default UserNotificationPage;
