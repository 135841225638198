import { FC, useCallback, useState } from "react";

import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import { Button, Tag } from "common/components/atoms";
import { StakeholderToInvite } from "store/modelTypes";
import { useStoreActions } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { StakeholderInvitationStatus, StakeholderInvitationStatusIds } from "../../../stakeholders-management/types";

type PropsTypes = {
  stakeholder: StakeholderToInvite;
  status: StakeholderInvitationStatusIds;
  className?: string;
  handleClose: () => void;
};

const [t, tCommon] = [
  createTranslation(TranslationNS.pages, "company.capTable.shareholderDetails.invitation"),
  createTranslation(TranslationNS.common, "noAccess"),
];

const InviteButton: FC<PropsTypes> = ({ status, stakeholder, className, handleClose }) => {
  const [isResendingInvite, setIsResendingInvite] = useState(false);
  const { hasFullAccess } = useFeatures(FEATURES.stakeholdersManagement);

  const { openInviteModal, reinviteStakeholderThunk } = useStoreActions((actions) => actions.stakeholderInvitation);

  const openInviteModalHandler = useCallback(() => {
    handleClose();
    openInviteModal(stakeholder);
  }, [handleClose, openInviteModal, stakeholder]);

  const resendInviteHandler = useCallback(async () => {
    setIsResendingInvite(true);
    await reinviteStakeholderThunk(stakeholder.stakeholderId);
    setIsResendingInvite(false);
  }, [reinviteStakeholderThunk, stakeholder.stakeholderId]);

  if (status === StakeholderInvitationStatus.active)
    return (
      <Tag variant="complete" size="s">
        {t("registeredUser")}
      </Tag>
    );

  if (status === StakeholderInvitationStatus.notInvited)
    return (
      <>
        <Tag className="mb-4" size="s" variant="draft">
          {t("notInvited")}
        </Tag>
        <Button
          size="s"
          className={className}
          onClick={openInviteModalHandler}
          variant="secondary"
          isDisabled={!hasFullAccess}
          tooltipTitle={!hasFullAccess ? tCommon("viewOnly") : undefined}
          tooltipMessage={!hasFullAccess ? tCommon("tooltip") : undefined}
        >
          {t("inviteBtn")}
        </Button>
      </>
    );

  if (status === StakeholderInvitationStatus.invited)
    return (
      <>
        <Tag className="mb-4" size="s" variant="information">
          {t("invited")}
        </Tag>
        <Button
          size="s"
          className={className}
          onClick={resendInviteHandler}
          isLoading={isResendingInvite}
          variant="secondary"
          isDisabled={!hasFullAccess}
          tooltipTitle={!hasFullAccess ? tCommon("viewOnly") : undefined}
          tooltipMessage={!hasFullAccess ? tCommon("tooltip") : undefined}
        >
          {t("resendInviteBtn")}
        </Button>
      </>
    );
};

export default InviteButton;
