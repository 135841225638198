import { ChangeEvent, FC, memo, ReactNode, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import classNames from "classnames";

import { MAX_FILE_SIZE } from "common/utils/constants";
import { createTranslation, TranslationNS } from "translation";

import FileInput from "../FileInput/FileInput";
import FileItem from "../FileItem/FileItem";
import InputFeedback from "../input-blocks/input-feedback/input-feedback";
import InputHeader from "../input-blocks/input-header/input-header";
import { Ui } from "../Typography";
import classes from "./FileUploader.module.scss";

const t = createTranslation(TranslationNS.common, "components.fileMultiInput");

//  THIS type MUST be used for all of uploaded files from the API
export type UploadedFile = { fileName: string; fileId: number; downloadId?: string; documentTypeId: number };
export type FilesDataSingle = { newFile: File | null; oldFile: UploadedFile | null };
export type FilesDataMultiple = { newFiles: File[]; oldFiles: UploadedFile[] };

// create typescript conditionals for props when boolean true or false

interface FileUploaderType {
  accept?: string;
  // multiple: boolean;
  content?: ReactNode;
  className?: string;
  error?: string;
  isTouched?: boolean;
  label?: string;
  isOptional?: boolean;
  inputRef?: any;
}

interface FileMultipleUploaderProps extends FileUploaderType {
  filesOnly?: string;
  multiple: true;
  prevFileData?: UploadedFile[];
  maxFileSize?: number;
  onChange?: (filesData: FilesDataMultiple) => void;
  noRemoveRemoteData?: boolean;
}

interface FileSingleUploaderProps extends FileUploaderType {
  filesOnly?: string;
  multiple?: false;
  maxFileSize?: number;
  prevFileData?: UploadedFile | null;
  onChange?: (filesData: FilesDataSingle | null) => void;
  noRemoveRemoteData?: boolean;
}

type FileIsMultiple = {
  multiple: true;
};

type FileUploaderProps<T = any> = T extends FileIsMultiple ? FileMultipleUploaderProps : FileSingleUploaderProps;

const FileUploader: FC<FileUploaderProps> = ({
  accept = ".pdf",
  onChange,
  className,
  multiple,
  error,
  isTouched,
  prevFileData,
  content,
  label,
  maxFileSize = MAX_FILE_SIZE,
  isOptional = false,
  noRemoveRemoteData,
  inputRef,
  filesOnly,
}) => {
  const [fileData, setFileData] = useState<File[] | File | null>(multiple ? [] : null);
  const [oldFileData, setOldFileData] = useState<UploadedFile[] | UploadedFile | null>(multiple ? [] : null);
  const [hasBeenTouched, setHasBeenTouched] = useState(false);
  const prevFileDataAdded = useRef<boolean | string>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [internalError, setInternalError] = useState(error);

  // TODO: typing methods and ref in future
  useImperativeHandle(
    inputRef,
    () => {
      return {
        resetFilesData: () => {
          setFileData(multiple ? [] : null);
        },
      };
    },
    [multiple]
  );

  useEffect(() => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  }, [fileData]);

  // hack to reset internal state of component without huge refactoring
  // needed in case form need reset all values. Example is transaction with "add another" checkbox set to true

  // it will reset file data if formik touched.documents will change from true to false
  useEffect(() => {
    if (isTouched) {
      setHasBeenTouched(true);
    }
  }, [isTouched]);

  useEffect(() => {
    if (hasBeenTouched && !isTouched) {
      setFileData(multiple ? [] : null);
    }
  }, [hasBeenTouched, isTouched, multiple]);
  // hack ends

  useEffect(() => {
    setInternalError(error);
  }, [error]);
  // set default value for preloaded fileData
  useEffect(() => {
    if (prevFileData && prevFileDataAdded.current !== JSON.stringify(prevFileData)) {
      //check if array link on object changed
      prevFileDataAdded.current = JSON.stringify(prevFileData);
      setOldFileData(prevFileData);
    }
  }, [oldFileData, prevFileData]);
  // function which checks if the file fits  to list of types
  const isFileValid = useCallback(
    (file: File) => {
      // remove all spaces and split by comma of accept
      const fileTypes = accept.replace(/\s/g, "").split(",");
      const fileType = "." + file.name.split(".").pop();
      return fileTypes.includes(fileType.toLowerCase());
    },
    [accept]
  );
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.files) {
        //check if file extension is valid and fits accept types prop
        const filesArray = Array.from(event.target.files);
        const validFiles = filesArray.filter((file) => isFileValid(file));

        if (validFiles.some((el) => el.size > maxFileSize)) {
          setInternalError(t("tooBig", { fileSize: maxFileSize / 1000000 }));
          return;
        } else {
          setInternalError(error);
        }

        if (validFiles.length) {
          const newFiles = fileData instanceof Array ? [...validFiles, ...fileData] : validFiles[0];
          setFileData(newFiles);
          if (multiple) {
            onChange?.({ newFiles: newFiles as File[], oldFiles: oldFileData as UploadedFile[] });
          } else {
            onChange?.({ newFile: newFiles as File, oldFile: oldFileData as UploadedFile });
          }
        }
      }
    },
    [isFileValid, maxFileSize, error, fileData, multiple, onChange, oldFileData]
  );

  const handleOnDelete = useCallback(
    (fileId: number | string) => {
      const newFiles = fileData instanceof Array ? fileData.filter((file, index) => index !== fileId) : null;
      setFileData(newFiles);
      if (multiple) {
        onChange?.({ newFiles: newFiles as File[], oldFiles: oldFileData as UploadedFile[] });
      } else {
        onChange?.({ newFile: newFiles as File | null, oldFile: oldFileData as UploadedFile });
      }
    },
    [fileData, multiple, onChange, oldFileData]
  );

  const handleUploadedFilesOnDelete = useCallback(
    (fileId: number | string) => {
      const oldFiles = oldFileData instanceof Array ? oldFileData.filter((file) => file.fileId !== fileId) : null;

      setOldFileData(oldFiles);
      if (multiple) {
        onChange?.({ newFiles: fileData as File[], oldFiles: oldFiles as UploadedFile[] });
      } else {
        onChange?.({ newFile: fileData as File | null, oldFile: oldFiles as UploadedFile | null });
      }
    },
    [oldFileData, multiple, onChange, fileData]
  );

  return (
    <div
      ref={inputRef}
      data-testid="file-multiple-uploader-test-id"
      className={classNames(classes.fileUploader, className)}
    >
      <InputGroup className="flex-column">
        <Ui.m className={classNames("d-flex justify-content-between mb-1", classes.label)}>
          {label ? <InputHeader label={label} isOptional={isOptional} /> : <div />}
          <span className={classes.acceptTypes}>{t("filesOnly", { types: filesOnly || "Pdf" })}</span>
        </Ui.m>

        <FileInput
          hidden={!multiple && (fileData !== null || oldFileData !== null)}
          isTouched={isTouched}
          multiple={multiple}
          accept={accept}
          onChange={handleChange}
          content={content}
          ref={fileInputRef}
          className={classNames({ "is-invalid": !!internalError })}
        />
        {fileData instanceof Array
          ? fileData.map((file, index) => (
              <FileItem
                file={file}
                onRemove={() => {
                  handleOnDelete(index);
                }}
                key={index}
              />
            ))
          : fileData && (
              <FileItem
                file={fileData}
                onRemove={() => {
                  handleOnDelete(0);
                }}
              />
            )}
        {oldFileData instanceof Array
          ? oldFileData.map((file, index) => (
              <FileItem
                file={file}
                onRemove={
                  noRemoveRemoteData
                    ? undefined
                    : () => {
                        handleUploadedFilesOnDelete(file.fileId);
                      }
                }
                key={index}
              />
            ))
          : oldFileData && (
              <FileItem
                file={oldFileData}
                onRemove={() => {
                  handleUploadedFilesOnDelete(oldFileData.fileId);
                }}
              />
            )}
        <InputFeedback className={classes.error} isTouched error={internalError} />
      </InputGroup>
    </div>
  );
};

export default memo(FileUploader);
