import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCoreRowModel, getSortedRowModel, SortingState, useReactTable } from "@tanstack/react-table";
import cn from "classnames";

import { getEMPath } from "app/Router/RouterHelper";
import { Button, P, Ui } from "common/components/atoms";
import { CheckBadgeIcon, PlusIcon } from "common/icons/svg";
import { notify } from "common/utils/notify/notifyFunction";
import { createTranslation, TranslationNS } from "translation";

import MyPortfolioContext, { CurrentOwnership } from "../../MyPortfolioContext";
import OverviewTable from "../OverviewTable/OverviewTable";
import classes from "./Ownership.module.scss";
import useOwnershipTable from "./useOwnershipTable";

const t = createTranslation(TranslationNS.pages, "myPortfolio.overview.ownership");

const Ownership = () => {
  const navigate = useNavigate();

  const [sorting, setSorting] = useState<SortingState>([{ id: "name", desc: false }]);

  const setShowInviteCompanyModal = MyPortfolioContext.useStoreActions((actions) => actions.setShowInviteCompanyModal);

  const currentOwnership = MyPortfolioContext.useStoreState((state) => state.portfolio?.currentOwnership);

  const data = useMemo(() => currentOwnership || [], [currentOwnership]);

  const handleAddCompany = useCallback(() => {
    setShowInviteCompanyModal({ show: true });
  }, [setShowInviteCompanyModal]);

  const { columns } = useOwnershipTable();

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const handleClickRow = useCallback(
    (data: CurrentOwnership) => {
      if (data.hasAccessToCapTable) {
        navigate(getEMPath(["ownership", "capTable"], { companyId: data.companyId }));
      } else {
        notify(t("restrictedAccess"), true, "warning");
      }
    },
    [navigate]
  );

  return (
    <div className={classes.portfolio}>
      <div className="d-flex justify-content-between ps-3 pe-3 pt-2 pb-2">
        <div className={classes.title}>
          <Ui.xl bold>{t("title")}</Ui.xl>
          <Ui.xs className={classes.subtitle}>{t("subtitle")}</Ui.xs>
        </div>
        <div className={cn(classes.newTaxable, "d-flex align-items-center")}>
          <CheckBadgeIcon fontSize={24} />
          <Ui.s>NEW! Taxable value added</Ui.s>
        </div>
      </div>
      {data.length > 0 ? (
        <OverviewTable withHover table={table} handleClickRow={handleClickRow} />
      ) : (
        <P.s className={classes.noRecord}>{t("noRecordYet")}</P.s>
      )}
      <div className={classes.invite}>
        <Button className="ms-2" iconLeft={<PlusIcon />} variant="tertiary" onClick={handleAddCompany}>
          {t("inviteCompany")}
        </Button>
      </div>
    </div>
  );
};

export default Ownership;
