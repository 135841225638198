import { FC, useMemo } from "react";
import cn from "classnames";

import { P, Ui } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { ArrowRightIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { createTranslation, TranslationNS } from "translation";

import { Transaction, TransactionStatus } from "../../../../types";
import classes from "./deletion-body.module.scss";

type PropsTypes = {
  transaction: Transaction;
};

const t = createTranslation(TranslationNS.pages, "company.transactions.transactionItem");

const DeletionBody: FC<PropsTypes> = ({ transaction }) => {
  const fNumber = useFormatNumbers();
  const isPending = useMemo(() => transaction.statusId === TransactionStatus.Pending, [transaction]);

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between">
        <div className="w-50">
          <Ui.m bold className="mb-1" color="foregroundMedium">
            {t("beforeDeletion")}
          </Ui.m>

          <div className={cn("p-3 rounded-3", classes.borders)}>
            <Ui.xxl color="foregroundMedium" bold>
              {fNumber(transaction.numberOfSharesBefore, "amount")}
            </Ui.xxl>
            <Ui.s>{t("shares")}</Ui.s>
          </div>
        </div>

        <ArrowRightIcon className="mt-3 mx-4" fontSize="40" color={scssVariables.strokeHigh} />

        <div className="w-50">
          <Ui.m bold className="mb-1" color="foregroundMedium">
            {t("afterDeletion")}
          </Ui.m>

          <div className={cn("p-3 rounded-3", classes.borders)}>
            <Ui.xxl bold style={{ color: isPending ? scssVariables.information700 : scssVariables.positive700 }}>
              {fNumber(transaction.numberOfShares, "amount")}
            </Ui.xxl>
            <Ui.s>{t("shares")}</Ui.s>
          </div>
        </div>
      </div>

      {transaction?.description ? (
        <div className="mt-4">
          <Ui.m bold>{t("description")}</Ui.m>

          <P.s>{transaction.description}</P.s>
        </div>
      ) : null}
    </div>
  );
};

export default DeletionBody;
