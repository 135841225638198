import { forwardRef, ForwardRefRenderFunction, ReactNode } from "react";
import FormControl, { FormControlProps } from "react-bootstrap/FormControl";
import classNames from "classnames";

import { createTranslation } from "translation/helpers";

import { FolderIcon } from "../../../icons/svg";
import { Ui } from "../Typography";
import classes from "./FileInput.module.scss";

export type FileInputProps = Omit<FormControlProps, "content"> & {
  accept?: string;
  multiple?: boolean;
  error?: string;
  isTouched?: boolean;
  hidden?: boolean;
  content?: ReactNode;
};

const t = createTranslation("common", "components.fileInput");

const FileInput: ForwardRefRenderFunction<HTMLInputElement, FileInputProps> = (
  { className, content, error, isTouched, hidden, ...props },
  ref
) => {
  if (hidden) return null;
  return (
    <>
      <div className={classNames(classes["wrap"])}>
        <FormControl
          ref={ref}
          className={classNames(classes["form-control"], className, isTouched && error ? classes.error : "")}
          {...props}
          type="file"
        />

        <div className={classes["content"]}>
          <FolderIcon className={classes["icon"]} />

          <Ui.m className={classNames("ms-3", classes["label"])}>
            {content || t.el("content", { components: [<span key={1} />] })}
          </Ui.m>
        </div>
      </div>
    </>
  );
};

export default forwardRef(FileInput);
