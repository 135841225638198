import { ChangeEvent, FC, useCallback } from "react";
import { AnimatePresence, motion } from "framer-motion";

import ChecksGroup from "common/components/atoms/Checks/ChecksGroup";
import FileUploader, {
  FilesDataMultiple,
  UploadedFile,
} from "common/components/atoms/FileUploader/FileUploader";
import LinkHelpText from "common/components/atoms/LinkHelpText/LinkHelpText";
import { Ui } from "common/components/atoms/Typography";
import { DocumentStatusEnum } from "common/enums/enum";
import { InformationCircleOutlinedIcon } from "common/icons/svg";
import { createTranslation, TranslationNS } from "translation";

import { Helper } from "../../atoms";

const t = createTranslation(TranslationNS.common, "molecules.documentUploadWithStatusesSignatureEmailInvitation");

type DocumentUploadWithStatusesSignatureEmailInvitationProps = {
  setDocumentStatus: (value: number) => void;
  isNeedSignatureChecked?: boolean;
  isNeedEmailInvitationChecked?: boolean;
  setIsNeedSignatureChecked?: (value: boolean) => void;
  setIsNeedEmailInvitationChecked?: (value: boolean) => void;
  title?: string;
  error?: string;
  touched?: boolean;
  documentError?: string;
  documentTouched?: boolean;
  documentStatus?: number;
  prevData?: UploadedFile[];
  onFileUploaderChange?: (files: FilesDataMultiple) => void;
  isInviteFlowDisabled?: boolean;
  isRadioDisabled?: boolean;
  noRemoveRemoteData?: boolean;
  dataManageRef?: any;
};

const animationParams = {
  exit: {
    opacity: 0,
    y: "-100%",
    height: 0,
  },
  enter: {
    opacity: 1,
    y: 0,
    height: "auto",
  },
  open: {
    opacity: 1,
    height: "auto",
  },
  collapsed: {
    opacity: 0,
    height: 0,
  },
  transition: {
    duration: 0.8,
    ease: [0.04, 0.62, 0.23, 0.98],
  },
};

export const InformationCheckboxLabel: FC<{ title: string; content: string }> = ({ title, content }) => (
  <div className="d-flex align-items-center position-relative" style={{ width: "max-content" }}>
    <Ui.m className="font-weight-normal">{title}</Ui.m>

    <LinkHelpText type="modal" title={title} content={content}>
      <InformationCircleOutlinedIcon width={18} height={18} style={{ position: "absolute", top: 0, right: -24 }} />
    </LinkHelpText>
  </div>
);

const DocumentUploadWithStatusesSignatureEmailInvitation: FC<
  DocumentUploadWithStatusesSignatureEmailInvitationProps
> = ({
  title,
  error,
  touched,
  prevData,
  documentStatus,
  isNeedSignatureChecked,
  isNeedEmailInvitationChecked,
  setDocumentStatus,
  onFileUploaderChange,
  setIsNeedSignatureChecked,
  setIsNeedEmailInvitationChecked,
  documentError,
  documentTouched,
  isInviteFlowDisabled,
  isRadioDisabled,
  noRemoveRemoteData,
  dataManageRef,
}) => {
  const documentStatusHandler = (status: number) => {
    setDocumentStatus(status);

    if (status === DocumentStatusEnum.NO_DOCUMENT_REQUIRED) {
      setIsNeedSignatureChecked?.(false);
    }
  };

  const handleChangeManagerSignature = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        setIsNeedEmailInvitationChecked?.(true);
      }
      setIsNeedSignatureChecked?.(e.target.checked);
    },
    [setIsNeedEmailInvitationChecked, setIsNeedSignatureChecked]
  );

  return (
    <div>
      <Helper>
        <FileUploader
          multiple
          inputRef={dataManageRef}
          isOptional={documentStatus !== DocumentStatusEnum.DOCUMENT_UPLOADED}
          prevFileData={prevData}
          label={title ? title : t("uploadDocuments")}
          onChange={onFileUploaderChange}
          isTouched={documentTouched}
          error={documentError}
          noRemoveRemoteData={noRemoveRemoteData}
        />

        <ChecksGroup
          className="mt-4"
          label={t("documentsStatus")}
          error={error}
          isTouched={touched}
          isDisabled={isRadioDisabled}
        >
          <ChecksGroup.Check
            isNoFocus
            type="radio"
            disabled={isRadioDisabled}
            label={t("documentsUploaded")}
            checked={documentStatus === DocumentStatusEnum.DOCUMENT_UPLOADED}
            onChange={documentStatusHandler.bind(null, DocumentStatusEnum.DOCUMENT_UPLOADED)}
          />
          <ChecksGroup.Check
            isNoFocus
            type="radio"
            disabled={isRadioDisabled}
            label={t("willUploadLater")}
            checked={documentStatus === DocumentStatusEnum.REVIEW_LATER}
            onChange={documentStatusHandler.bind(null, DocumentStatusEnum.REVIEW_LATER)}
          />
          <ChecksGroup.Check
            isNoFocus
            type="radio"
            disabled={isRadioDisabled}
            label={t("noDocsRequired")}
            checked={documentStatus === DocumentStatusEnum.NO_DOCUMENT_REQUIRED}
            onChange={documentStatusHandler.bind(null, DocumentStatusEnum.NO_DOCUMENT_REQUIRED)}
          />
        </ChecksGroup>

        {!isInviteFlowDisabled && (
          <ChecksGroup className="mt-3">
            <AnimatePresence>
              {documentStatus !== DocumentStatusEnum.NO_DOCUMENT_REQUIRED && (
                <motion.div exit={animationParams.exit} animate={animationParams.enter} initial={animationParams.exit}>
                  <Helper.Question questionId="docNeedSignature">
                    <ChecksGroup.Check
                      isNoFocus
                      checked={isNeedSignatureChecked}
                      label={t("docNeedSignature")}
                      onChange={handleChangeManagerSignature}
                    />
                  </Helper.Question>
                  <Helper.Answer answerId={"docNeedSignature"} text={t("docNeedSignatureDescription")} />
                </motion.div>
              )}
            </AnimatePresence>
            <Helper.Question questionId="sendEmailInvitation">
              <ChecksGroup.Check
                isNoFocus
                checked={isNeedEmailInvitationChecked}
                label={t("sendEmailInvitation")}
                onChange={(e) => {
                  setIsNeedEmailInvitationChecked?.(e.target.checked);
                }}
              />
            </Helper.Question>
            <Helper.Answer answerId="sendEmailInvitation" text={t("sendEmailInvitationDescription")} />
          </ChecksGroup>
        )}
      </Helper>
    </div>
  );
};

export default DocumentUploadWithStatusesSignatureEmailInvitation;
