import { FC, useEffect, useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { useFormikContext } from "formik";
import * as R from "ramda";
import {defaultTo, isNil} from "ramda";

import { Helper, TextField, Ui } from "common/components/atoms";
import SigningManagerField from "common/components/molecules/signing-manager/SigningManagerField";
import { InstrumentTypesIdsEnum } from "common/enums/enum";
import useCurrency from "common/hooks/useCurrency";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { newPlanFieldsNames as f } from "common/plan/planFormFields";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { PlanForm } from "../../../../create-one-off-plans/types";
import classes from "./share-details.module.scss";

type PropsType = {
  shareClass?: string;
  planType?: number;
};

const t = createTranslation(TranslationNS.pages, "createPlan.basics.planSidebar");

const ShareDetails: FC<PropsType> = ({ shareClass, planType }) => {
  const fNumber = useFormatNumbers();
  const { currencySymbol } = useCurrency();
  const companySigningManagers = useStoreState((state) => state.company.companySigningManagers);

  const { values, handleChange, errors, touched, handleBlur, setFieldValue } = useFormikContext<PlanForm>();

  const isFormDisabled = !isNil(values.subscriptionAvailable) && defaultTo(0, values.subscriptionAvailable.remainingAgreements) <= 0;

  const signingManager = useMemo(
    () => companySigningManagers?.find((el) => el.userId === values.managerId),
    [companySigningManagers, values.managerId]
  );

  const planTypeValue = useMemo(() => {
    if (planType === InstrumentTypesIdsEnum.RSA) {
      return "Restricted Stock Awards";
    }
    if (planType === InstrumentTypesIdsEnum.RSU) {
      return "Restricted Stock Units";
    }

    if (planType === InstrumentTypesIdsEnum.OPTION) {
      return "Stock options";
    }
  }, [planType]);

  useEffect(() => {
    if (!isNil(signingManager)) {
      setFieldValue("signingManagerEmail", signingManager?.email);
    }
  }, [setFieldValue, signingManager, signingManager?.email, values.managerId]);

  return (
    <div className="mt-0 pb-5">
      <Row className="mb-4">
        <Col>
          <Helper.Question questionId={f.numberOfShares} type="input">
            <TextField
              type="number"
              label={t("amountOfShares")}
              value={values.numberOfShares}
              isTouched={touched.numberOfShares}
              error={errors.numberOfShares}
              name={f.numberOfShares}
              onChange={handleChange}
              onBlur={handleBlur}
              info={
                values.numberOfShares
                  ? t("purchasePricePerShare", {
                      price: fNumber(values.numberOfShares * R.defaultTo(0, values.purchasePrice), "value"),
                    })
                  : undefined
              }
              isDisabled={isFormDisabled}
            />
          </Helper.Question>
        </Col>
        <Col>
          <Helper.Question questionId={f.purchasePrice} type="input">
            <TextField
              type="number"
              label={t("purchasePrice")}
              min={0}
              step={0.1}
              isTouched={touched.purchasePrice}
              error={errors.purchasePrice}
              value={values.purchasePrice}
              name={f.purchasePrice}
              onChange={handleChange}
              onBlur={handleBlur}
              iconRight={currencySymbol}
              isDisabled={isFormDisabled}
            />
          </Helper.Question>
        </Col>
      </Row>
      <Row>
        <Col>
          <Helper.Answer
            className="mb-3"
            answerId={f.numberOfShares}
            text={t("amountOfSharesAnswer")}
            linkText={t("amountOfSharesReadMore")}
            link="/"
          />
          <Helper.Answer
            className="mb-3"
            answerId={f.purchasePrice}
            text={t("purchasePriceAnswer")}
            linkText={t("purchasePriceReadMore")}
            link="/"
          />
        </Col>
      </Row>
      <Helper.Question questionId={f.reason} type="input">
        <TextField
          type="string"
          label={t("grantReason")}
          isTouched={touched.reason}
          error={errors.reason}
          value={values.reason}
          name={f.reason}
          onChange={handleChange}
          onBlur={handleBlur}
          className="mb-3"
          isDisabled={isFormDisabled}
        />
      </Helper.Question>
      <Row>
        <Col>
          <Helper.Answer className="mt-1" answerId={f.reason} text={t("grantReasonAnswer")} />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <SigningManagerField
            selectedId={values?.managerId}
            onChange={(id) => {
              setFieldValue("managerId", id);
            }}
            isDisabled={isFormDisabled}
          />
        </Col>
      </Row>
      <div className={`${classes["neutral-plate"]} mt-3`}>
        <span className={`${classes["share-details-title"]}`}>{t("shareDetailsDescriptionTitle")}</span>
        <div className="pt-2">
          <Ui.m className="mb-1">
            {t("shareClass")}: <strong>{shareClass}</strong>
          </Ui.m>

          <Ui.m>
            {t("planType")}: <strong>{planTypeValue}</strong>
          </Ui.m>

          {values?.managerId ? (
            <Ui.m className="mt-1">
              {t("signingManager")}:{" "}
              <strong>
                {signingManager?.name} ({signingManager?.email})
              </strong>
            </Ui.m>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ShareDetails;
