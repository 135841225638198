import { FC } from "react";
import { Col, Row } from "react-bootstrap";
import { useFormikContext } from "formik";
import * as R from "ramda";

import ChecksGroup from "common/components/atoms/Checks/ChecksGroup";
import DatePicker from "common/components/atoms/DatePicker/DatePicker";
import Dropdown from "common/components/atoms/Dropdown/Dropdown";
import TextArea from "common/components/atoms/TextArea/TextArea";
import TextField from "common/components/atoms/TextField/TextField";
import useCurrency from "common/hooks/useCurrency";
import { createTranslation, TranslationNS } from "translation";

import useWarrantsEditPanel from "../useWarrantsEditPanel";

const t = createTranslation(TranslationNS.pages, "company.issueEquity.warrants.editPanel");

const Terms: FC = () => {
  const { initialValues, shareClassesCompany } = useWarrantsEditPanel();
  const { currencySymbol } = useCurrency();

  const { values, errors, touched, handleChange, setFieldValue, handleBlur } = useFormikContext<typeof initialValues>();

  const handleChangeDate = (date: Date | string, name?: string) => {
    if (name) {
      setFieldValue(name, date);
    }
  };

  return (
    <>
      <Row>
        <Col>
          <TextField
            type="number"
            label={t("numberOfShares")}
            value={values.numberOfShares}
            error={errors.numberOfShares}
            isTouched={touched.numberOfShares}
            name="numberOfShares"
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Col>
        <Col>
          <TextField
            type="number"
            value={values.strikePrice}
            error={errors.strikePrice}
            isTouched={touched.strikePrice}
            label={t("strikeExercisePrice")}
            iconRight={currencySymbol}
            name="strikePrice"
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Col>
      </Row>

      <Row className="my-5">
        <Col>
          <DatePicker
            isOptional
            isClearable
            isDateOnlyString
            label={t("expirationDate")}
            date={values.expirationDate}
            name="expirationDate"
            onChange={handleChangeDate}
          />
        </Col>
        <Col>
          <TextField
            isOptional
            type="number"
            label={t("warrantPrice")}
            value={values.warrantPrice}
            name={"warrantPrice"}
            onChange={handleChange}
            onBlur={handleBlur}
            isTouched={touched.warrantPrice}
            error={errors.warrantPrice}
            iconRight={currencySymbol}
          />
        </Col>
      </Row>

      <ChecksGroup label={t("shareClass")} className="mb-3">
        <ChecksGroup.Check
          isNoFocus
          type="radio"
          label={t("shareClassAgreed")}
          checked={values.isShareClassAgreedChecked}
          onChange={() => {
            setFieldValue("isShareClassAgreedChecked", true);
          }}
        />
        <ChecksGroup.Check
          isNoFocus
          type="radio"
          label={t("uponConversion")}
          checked={!values.isShareClassAgreedChecked}
          onChange={() => {
            setFieldValue("isShareClassAgreedChecked", false);
          }}
        />
      </ChecksGroup>

      {values.isShareClassAgreedChecked ? (
        <Row>
          <Col>
            <Dropdown
              isOptional
              label={t("shareClass")}
              placeholder={t("shareClass")}
              selectedValue={values.shareClass?.name}
              options={R.pluck("name", shareClassesCompany || [])}
              error={errors.shareClass as string}
              isTouched={Boolean(touched.shareClass)}
              onChange={(e) => {
                const selectedClass = shareClassesCompany.find((shareClass) => shareClass.name === e);
                setFieldValue("shareClass", selectedClass);
              }}
            />
          </Col>
          <Col />
        </Row>
      ) : (
        <TextArea
          isOptional
          value={values.shareClassComment}
          label={t("shareClassAccordingToAgreement")}
          placeholder={t("shareClassAccordingToAgreementDescription")}
          onChange={handleChange("shareClassComment")}
        />
      )}

      <TextArea
        isOptional
        className="my-4"
        label={t("notablePointsFromAgreement")}
        value={values.notablePointsFromAgreement}
        placeholder={t("notablePointsFromAgreementPlaceholder")}
        onChange={handleChange("notablePointsFromAgreement")}
      />
    </>
  );
};

export default Terms;
