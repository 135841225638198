import { FC, useCallback, useMemo } from "react";
import { Formik, FormikHelpers } from "formik";

import { newPlanFieldsNames as f } from "common/plan/planFormFields";
import { useOneOffPlanValidation } from "common/plan/planValidationSchema";
import usePlanInitialValues from "common/plan/usePlanInitialValues";
import { notify } from "common/utils/notify/notifyFunction";
import { PlanGetDTO, PlanPostDTO } from "store/modelTypes";
import { useStoreActions, useStoreState } from "store/store";
import { TranslationNS } from "translation";
import { createTranslation } from "translation/helpers";

import { PlanForm } from "../../../types";
import { availableSharesInProgram } from "../../../utils";
import FormData from "./existing-rsa-form-data";

type PropsType = {
  setIsSidebarOpen: (isOpen: boolean) => void;
  planToEdit: PlanGetDTO | null;
  onSave?: () => void;
};

const t = createTranslation(
  TranslationNS.pages,

  "createPlan.basics.planSidebar"
);

const ExistingRsaPlanForm: FC<PropsType> = ({ setIsSidebarOpen, planToEdit, onSave = () => undefined }) => {
  const companyId = useStoreState((state) => state.activeCompanyModel.companyId);
  const selectedProgram = useStoreState((state) => state.programModel.program);
  const { getProgramThunk } = useStoreActions((actions) => actions.programModel);
  const { setRegularPlanRSAThunk } = useStoreActions((actions) => actions.planModel);

  const planData = useMemo(() => {
    const numberOfSharesLeft = availableSharesInProgram(selectedProgram, planToEdit);

    if (planToEdit) return { ...planToEdit, numberOfSharesLeft };

    return {
      programId: selectedProgram?.programId,
      poolId: selectedProgram?.poolId,
      vestingPeriod: selectedProgram?.vestingPeriod,
      vestingInterval: selectedProgram?.vestingInterval,
      vestingTypeId: selectedProgram?.vestingTypeId,
      vestingCliff: selectedProgram?.vestingCliff,
      allowAcceleratedVesting: selectedProgram?.allowAcceleratedVesting,
      conditions: selectedProgram?.conditions.map((condition) => ({
        title: condition.title,
        description: condition.description,
        targetDate: condition.targetDate,
        numberOfOptions: 0,
      })),
      timeVestedShares: selectedProgram?.timeVestedShares,
      numberOfSharesLeft,
      purchasePrice: selectedProgram?.purchasePrice || 0,
      approvedAt: selectedProgram?.approvedAt,
    };
  }, [planToEdit, selectedProgram]);

  const initialValues = usePlanInitialValues(companyId, planData);

  const onSubmit = useCallback(
    async (values: PlanForm, helpers: FormikHelpers<PlanForm>) => {
      const createPlanDTO: PlanPostDTO = {
        ...values,
        countryId: values?.countryId || null,
        existingPlan: true,
        documentStatusId: values.documentStatusId,
        file: values?.fileAgreement?.newFile,
        oldFileId: values?.fileAgreement?.oldFile?.fileId,
      };

      const res = await setRegularPlanRSAThunk(createPlanDTO);

      if (res?.data) {
        helpers.resetForm();
        setIsSidebarOpen(false);
        helpers.setSubmitting(false);
        onSave?.();
        if (selectedProgram) {
          await getProgramThunk(selectedProgram.programId).then();
        }
        notify(t("notificationSuccess"), true, "success");
      }
    },
    [getProgramThunk, onSave, selectedProgram, setIsSidebarOpen, setRegularPlanRSAThunk]
  );

  const validation = useOneOffPlanValidation();

  const validationSchema = useMemo(() => {
    return validation.omit([
      f.retirement,
      f.disability,
      f.terminationWithCause,
      f.involuntaryTermination,
      f.voluntaryTermination,
      f.byPassingAway,
    ]);
  }, [validation]);

  return (
    <div data-testid="existing-rsa-plan-form">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        <FormData handleClose={setIsSidebarOpen} />
      </Formik>
    </div>
  );
};

export default ExistingRsaPlanForm;
