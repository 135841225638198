import { FC, useCallback, useMemo } from "react";

import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import { Button, ContextMenu, ContextMenuProps, NewAvatar, Tag, Ui } from "common/components/atoms";
import { DeleteIcon, EditIcon, MenuTabBarVerticalIcon, SendEmailInvitationIcon } from "common/icons/svg";
import { useStoreActions } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { GetStakeholdersDTO } from "../../types";

type PropsTypes = GetStakeholdersDTO[0] & {
  onEdit: (id: number) => void;
  handleRemoveStakeholder?: (stakeholderId: number) => void;
};

const t = createTranslation(TranslationNS.pages, "company.stakeholdersManagement.card");

const Row: FC<PropsTypes> = ({
  stakeholderId,
  avatarFilePath,
  initials,
  companyName,
  firstName,
  lastName,
  email,
  relationship,
  onEdit,
  hasSentInvite,
  isActiveUser,
  handleRemoveStakeholder,
  canDelete,
}) => {
  const { openInviteModal, reinviteStakeholderThunk } = useStoreActions((actions) => actions.stakeholderInvitation);
  const { hasFullAccess } = useFeatures(FEATURES.stakeholdersManagement);

  const handleEditClick = useCallback(() => {
    onEdit(stakeholderId);
  }, [onEdit, stakeholderId]);

  const handleInviteClick = useCallback(() => {
    openInviteModal({
      stakeholderId,
      firstName,
      lastName,
      email,
      relationshipId: Number(relationship),
    });
  }, [email, firstName, lastName, openInviteModal, relationship, stakeholderId]);

  const handleReinviteClick = useCallback(() => {
    reinviteStakeholderThunk(stakeholderId);
    document.body.click();
  }, [reinviteStakeholderThunk, stakeholderId]);

  const handleDeleteClick = useCallback(() => {
    handleRemoveStakeholder?.(stakeholderId);
  }, [handleRemoveStakeholder, stakeholderId]);

  const dropdownItems = useMemo(() => {
    const commonItems: ContextMenuProps["items"][0][] = [
      {
        key: "edit",
        label: t("editBtn"),
        icon: <EditIcon />,
        onClick: handleEditClick,
        isDisabled: !hasFullAccess,
      },
    ];

    if (!isActiveUser && !hasSentInvite) {
      commonItems.push({
        key: "invite",
        label: t("inviteBtn"),
        icon: <SendEmailInvitationIcon />,
        onClick: handleInviteClick,
        isDisabled: !hasFullAccess,
      });
    }

    if (!isActiveUser && hasSentInvite) {
      commonItems.push({
        key: "reinvite",
        label: t("reinviteBtn"),
        icon: <SendEmailInvitationIcon />,
        onClick: handleReinviteClick,
        isDisabled: !hasFullAccess,
      });
    }

    if (canDelete) {
      commonItems.push({
        key: "delete",
        label: "Delete",
        type: "delete",
        onClick: handleDeleteClick,
        icon: <DeleteIcon />,
        isDisabled: !hasFullAccess,
      });
    }

    return commonItems;
  }, [
    canDelete,
    handleDeleteClick,
    handleEditClick,
    handleInviteClick,
    handleReinviteClick,
    hasFullAccess,
    hasSentInvite,
    isActiveUser,
  ]);

  return (
    <tr key={stakeholderId}>
      <td>
        <div className="d-flex align-items-center">
          <NewAvatar size="m" imageUrl={avatarFilePath} initials={initials} company={!!companyName} />
          <div className="ms-2">
            {companyName ? (
              <>
                <Ui.m bold>{companyName}</Ui.m>
                <Ui.s>{`${firstName} ${lastName}`}</Ui.s>
              </>
            ) : (
              <Ui.m bold>{`${firstName} ${lastName}`}</Ui.m>
            )}
          </div>
        </div>
      </td>
      <td>
        <Ui.s>{email}</Ui.s>
      </td>
      <td>
        <Tag variant="closed">{relationship}</Tag>
        {isActiveUser && (
          <Tag className="ms-1" variant="complete">
            {t("activeUser")}
          </Tag>
        )}
        {!isActiveUser && hasSentInvite && (
          <Tag className="ms-1" variant="information">
            {t("invited")}
          </Tag>
        )}
      </td>
      <td>
        <div className="d-flex justify-content-end">
          <ContextMenu items={dropdownItems}>
            <Button
              size="s"
              isOnlyIcon
              variant="tertiary"
              data-testid="plan-dropdown-btn"
              style={{ height: 30, width: 30 }}
            >
              <MenuTabBarVerticalIcon />
            </Button>
          </ContextMenu>
        </div>
      </td>
    </tr>
  );
};

export default Row;
