import React, { FC } from "react";
import cn from "classnames";

import { Ui } from "common/components/atoms";
import { CommonFileIcon } from "common/icons/svg";
import { openRemoteDocument } from "common/utils/functions";

import { SingleCommonDocumentType } from "../../documents/DocumentsContext";
import classes from "./forms/form-sections/nominal-value-details/styles.module.scss";

type PropsTypes = {
  data: Pick<SingleCommonDocumentType, "fileName" | "downloadId" | "fileId">[];
} & JSX.IntrinsicElements["div"];

const TransactionsDocumentsList: FC<PropsTypes> = ({ data, className, ...props }) => {
  return (
    <div className={cn("mt-4", className)} {...props}>
      {data?.map((el) => (
        <div
          key={el.fileId}
          className={cn("px-3 d-flex align-items-center mb-2 cursor-pointer", classes["document-item"])}
          onClick={() => {
            openRemoteDocument(el.downloadId);
          }}
        >
          <CommonFileIcon />

          <Ui.m className="ms-2">{el.fileName}</Ui.m>
        </div>
      ))}
    </div>
  );
};

export default TransactionsDocumentsList;
