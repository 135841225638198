import { FC } from "react";

import { TransactionInstrumentsDocumentsType } from "../../../DocumentsContext";
import classes from "../../CommonStyles.module.scss";
import TransactionDocumentsTableRow from "./TransactionDocumentsTableRow";

type CommonDocumentsTableBodyProps = {
  data: TransactionInstrumentsDocumentsType["transactions"];
};

const TransactionDocumentsTableBody: FC<CommonDocumentsTableBodyProps> = ({ data }) => {
  return (
    <tbody className={classes.body}>
      {data.map((el) => (
        <TransactionDocumentsTableRow data={el} key={`Key for table inner single row is - ${el.entityId}`} />
      ))}
    </tbody>
  );
};

export default TransactionDocumentsTableBody;
