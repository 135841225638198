import { EventHandler } from "react";
import { AccordionEventKey } from "react-bootstrap/AccordionContext";

import { InstrumentTypesNamesEnum } from "common/enums/enum";
import { PlanStatuses } from "pages/equity-management/ownership/documents/documents.types";

export type CollapsibleHeaderProps = {
  onClick: EventHandler<any>;
  activeEventKey: AccordionEventKey;
};

export enum programTablePlanStatuses {
  Approved = "Approved",
  Draft = "Draft",
  Granted = "Granted",
  Active = "Active",
  PartiallyVested = "PartiallyVested",
  FullyVested = "FullyVested",
}

export type OwnershipProgramTableData = {
  id: number;
  endTime: string | number;
  // receiverName: string;
  restrictedShares: number;
  startDate: string | number;
  status: PlanStatuses;
  statusId: number;
  vestedShares: number;
  instrumentTypeName?: InstrumentTypesNamesEnum;
  instrumentTypeId?: number;
  stakeholderName: string;
  invitationDate: string;
  invitationSent: boolean;
  representedBy: string;
  stakeholderEmail: string;
  managerEmail?: string;
  none: string;
  isCompanyOwned: boolean;
  stakholderCompanyName?: string;
  sharesLostByTermination?: string;
  canDeleteAgreement?: boolean;
  exercisedShares?: number;
  optionsExpirationDate?: string;
};

export enum sortingParams {
  inc = "inc",
  desc = "desc",
}
