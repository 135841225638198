import { FC, useCallback } from "react";

import PoolFormOffcanvas, { PoolFormOffcanvasProps } from "common/pool/components/PoolFormOffcanvas/PoolFormOffcanvas";
import { useStoreActions } from "store/store";

import PoolsAndProgramsStore from "../store";

const EditPool: FC = () => {
  const editingPoolState = PoolsAndProgramsStore.useStoreState((state) => state.editingPool);
  const setEditingItemAction = PoolsAndProgramsStore.useStoreActions((actions) => actions.setEditingItem);
  const setDeletingItemAction = PoolsAndProgramsStore.useStoreActions((actions) => actions.setDeletingItem);
  const getPoolsAndProgramsThunk = useStoreActions((state) => state.company.getPoolsAndProgramsThunk);

  const buttonDeleteClickHandler = useCallback(() => {
    setDeletingItemAction(editingPoolState);
  }, [editingPoolState, setDeletingItemAction]);

  const closePoolFormOffcanvas = useCallback((): void => {
    setEditingItemAction(undefined);
  }, [setEditingItemAction]);

  const offCanvasSaveHandler = useCallback<PoolFormOffcanvasProps["onSubmit"]>(() => {
    getPoolsAndProgramsThunk().then(() => {
      closePoolFormOffcanvas();
    });
  }, [closePoolFormOffcanvas, getPoolsAndProgramsThunk]);

  const offCanvasCloseHandler = useCallback((): void => {
    closePoolFormOffcanvas();
  }, [closePoolFormOffcanvas]);

  return (
    <PoolFormOffcanvas
      show={!!editingPoolState}
      poolId={editingPoolState?.id}
      onHide={offCanvasCloseHandler}
      onSave={offCanvasSaveHandler}
      onCancel={offCanvasCloseHandler}
      onDelete={buttonDeleteClickHandler}
    />
  );
};

export default EditPool;
