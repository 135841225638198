import { FC, useCallback, useMemo, useState } from "react";
import * as R from "ramda";

import { NewAvatar, Pagination, Ui } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { CalendarIcon } from "common/icons/svg";
import { ExerciseRequestStatusEnum } from "common/types/Exxercise.types";
import { scssVariables } from "common/utils/constants";
import { transformDateToCommonDateFormat } from "common/utils/functions";
import { createTranslation, TranslationNS } from "translation";

import ExercisingContext from "../../ExercisingContext";
import ExercisingCollapsable from "../ExercisingCollapsable/ExercisingCollapsable";
import classes from "../styles.module.scss";

const t = createTranslation(TranslationNS.pages, "company.exercising.history");

const ITEMS_PER_PAGE = 10;

const History: FC = () => {
  const fNumber = useFormatNumbers();
  const [currentPage, setCurrentPage] = useState<number>(0);

  const historicExercises = ExercisingContext.useStoreState((state) => state.historicExercises).filter(
    (el) => el.exerciseRequestStatus !== ExerciseRequestStatusEnum.Declined
  );

  const splitData = useMemo(
    () => ({
      data: R.splitEvery(ITEMS_PER_PAGE, historicExercises),
      maxCount: Math.ceil(historicExercises.length / ITEMS_PER_PAGE),
    }),
    [historicExercises]
  );

  const renderHistory = useCallback(() => {
    if (!splitData.data.length) {
      return null;
    }
    return splitData.data[currentPage].map((item) => {
      return (
        <div key={item.exerciseRequestId} className={classes["row"]}>
          <NewAvatar
            company={item?.requestorIsCompanyOwned}
            imageUrl={item?.avatarFilePath}
            initials={item?.requestorIsCompanyOwned ? item?.requestorCompanyName : item.requestorName}
          />

          <div className="ms-2">
            <Ui.m bold className="mb-1">
              {item?.requestorIsCompanyOwned
                ? `${item?.requestorCompanyName} (${item.requestorName})`
                : item.requestorName}{" "}
              • {fNumber(item.numberOfOptions, "amount")} options
            </Ui.m>
            <div className="d-flex align-items-center">
              <CalendarIcon fontSize={16} color={scssVariables.foregroundLow} />

              <Ui.xs color="foregroundLow" className="ms-1">
                {transformDateToCommonDateFormat(item.requestedAt, "d. MMM yyyy").toLowerCase()}
                &nbsp;•&nbsp;
              </Ui.xs>

              <Ui.xs color="foregroundLow">
                {t("item.description", {
                  name: item.requestorName,
                  price: fNumber(item.convertPrice),
                  options: fNumber(item.numberOfOptions, "amount"),
                  // TODO: temporary fix with requirements, maybe can be improved in future
                  program: item.programName?.toLowerCase() === "one-off" ? "standalone agreement" : item.programName,
                })}
              </Ui.xs>
            </div>
          </div>
        </div>
      );
    });
  }, [currentPage, fNumber, splitData.data]);

  return (
    <ExercisingCollapsable title={t("title")} countItems={historicExercises.length} description={t("description")}>
      {!historicExercises.length ? (
        <div className="p-4">
          <Ui.s color="foregroundLow">{t("noHistory")}</Ui.s>
        </div>
      ) : (
        <>
          {renderHistory()}
          {historicExercises.length > ITEMS_PER_PAGE ? (
            <Pagination
              totalPages={splitData.maxCount}
              currentPage={currentPage}
              clickNext={setCurrentPage}
              clickPrevious={setCurrentPage}
            />
          ) : null}
        </>
      )}
    </ExercisingCollapsable>
  );
};

export default History;
