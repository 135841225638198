import { FC, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Form, Formik } from "formik";
import { isEmpty, omit } from "ramda";

import SlidePanel from "common/components/atoms/SlidePanel/SlidePanel";
import NewStakeholder from "common/components/molecules/StakeholderSection/components/NewStakeholder";
import { ToastFormikValidator } from "common/hooks/useToastFormikValidator";
import { notify } from "common/utils/notify/notifyFunction";
import { createTranslation, TranslationNS } from "translation";

import { useStakeholdersService } from "../stakeholders-management-service";
import { EditStakeholderDTO, PostStakeholderDTO } from "../types";

type PropsTypes = {
  isOpen: boolean;
  onClose: () => void;
  stakeholderId?: number;
  onCreateSuccess: (createdId?: number) => Promise<void>;
};

const t = createTranslation(TranslationNS.pages, "company.stakeholdersManagement.form");

const StakeholderForm: FC<PropsTypes> = ({ onClose, stakeholderId, onCreateSuccess }) => {
  const { companyId = "0" } = useParams<{ companyId: string }>();

  const [loading, setLoading] = useState(false);
  const { getStakeholderRequest, validationSchema, formInitialValues, postStakeholderRequest, editStakeholderRequest } =
    useStakeholdersService(companyId);

  const handleSubmitForm = useCallback(
    async (stakeholder: PostStakeholderDTO | EditStakeholderDTO) => {
      let isSuccessOrId;
      let stakeholderData = stakeholder;

      if (isEmpty(stakeholderData.dateOfBirth)) {
        stakeholderData = omit(["dateOfBirth"], stakeholderData);
      }

      if (stakeholderId) {
        isSuccessOrId = await editStakeholderRequest(stakeholderData as EditStakeholderDTO);
      } else {
        isSuccessOrId = await postStakeholderRequest(stakeholderData);
      }
      if (isSuccessOrId) {
        notify(t(stakeholderId ? "editNotificationSuccess" : "addNotificationSuccess"), true, "success", 2000);
        onClose();
        if (typeof isSuccessOrId === "number") {
          await onCreateSuccess(isSuccessOrId);
        }
        await onCreateSuccess();
      }
    },
    [editStakeholderRequest, onCreateSuccess, onClose, postStakeholderRequest, stakeholderId]
  );

  useEffect(() => {
    if (stakeholderId) {
      setLoading(true);
      getStakeholderRequest(stakeholderId).then(() => {
        setLoading(false);
      });
    }
  }, [getStakeholderRequest, stakeholderId]);

  return (
    <SlidePanel.Body isLoading={loading}>
      <Formik onSubmit={handleSubmitForm} initialValues={formInitialValues} validationSchema={validationSchema}>
        {({ isSubmitting, submitForm }) => (
          <>
            <ToastFormikValidator />
            <Form className="mt-7">
              <NewStakeholder />
            </Form>
            <SlidePanel.Actions
              primaryTitle={stakeholderId ? t("editBtn") : t("createBtn")}
              secondaryTitle={t("cancelBtn")}
              primaryAction={submitForm}
              secondaryAction={onClose}
              isLoading={isSubmitting}
            />
          </>
        )}
      </Formik>
    </SlidePanel.Body>
  );
};

const StakeholderFormSidebar: FC<PropsTypes> = ({ isOpen, onClose, stakeholderId, onCreateSuccess }) => {
  return (
    <SlidePanel show={isOpen} onHide={onClose}>
      <SlidePanel.Header
        isEdit={!!stakeholderId}
        onHide={onClose}
        closeBtn
        title={stakeholderId ? t("editTitle") : t("createTitle")}
      />
      <StakeholderForm
        isOpen={isOpen}
        onClose={onClose}
        stakeholderId={stakeholderId}
        onCreateSuccess={onCreateSuccess}
      />
    </SlidePanel>
  );
};

export default StakeholderFormSidebar;
