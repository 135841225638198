import { FC } from "react";
import { OffcanvasProps } from "react-bootstrap/Offcanvas";

import SlidePanel from "common/components/atoms/SlidePanel/SlidePanel";

import Content, { ContentProps } from "./Content/Content";

export type PoolFormOffcanvasProps = OffcanvasProps & ContentProps;

const PoolFormOffcanvas: FC<PoolFormOffcanvasProps> = ({ show, onHide, poolId, onSave, onCancel, onDelete }) => {
  return (
    <SlidePanel show={show} onHide={onHide}>
      <Content poolId={poolId} onSave={onSave} onCancel={onCancel} onDelete={onDelete} />
    </SlidePanel>
  );
};

export default PoolFormOffcanvas;
