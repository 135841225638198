import { FC, ReactNode, useCallback, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import classNames from "classnames";

import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import { Button, H, P, Ui } from "common/components/atoms";
import { EditIcon } from "common/icons/svg";
import { ShareClassServiceGetResponse } from "common/shareClass/ShareClassService";
import { ShareClassTypes } from "common/shareClass/types";
import { scssVariables } from "common/utils/constants";
import { TranslationNS } from "translation";
import { createTranslation, TElOptions } from "translation/helpers";

import Possibilities from "../Possibilities/Possibilities";
import classes from "./InfoCard.module.scss";

export type InfoCardProps = JSX.IntrinsicElements["div"] & {
  shareClass: ShareClassServiceGetResponse;
  showStatistics?: boolean;
  onEdit?(shareClass: ShareClassServiceGetResponse): void;
};

const t = createTranslation(TranslationNS.common, "shareClass.components.infoCard");

const InfoCard: FC<InfoCardProps> = ({ className, shareClass, showStatistics, onEdit, ...props }) => {
  const [isReadMoreVisible, setIsReadMoreVisible] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const { hasFullAccess } = useFeatures(FEATURES.shareClasses);

  const liquidationPreferencesList = useMemo<ReactNode[]>(() => {
    const list = t("liquidationPreferences.list", {
      shareClass,
      defaultValue: [],
      returnObjects: true,
    });

    const options: TElOptions = {
      components: [<strong key="1" />],
      values: { shareClass },
    };

    return (Array.isArray(list) ? list : [list]).map((_, index) =>
      t.el(`liquidationPreferences.list.${index}`, options)
    );
  }, [shareClass]);

  const buttonEditClickHandler = useCallback((): void => {
    onEdit?.(shareClass);
  }, [onEdit, shareClass]);

  const openModalHandler = useCallback((): void => {
    setIsModalVisible(true);
  }, []);

  const closeModalHandler = useCallback((): void => {
    setIsModalVisible(false);
  }, []);

  const refReinitialize = useCallback(
    (ref: HTMLDivElement | null) => {
      if (!ref) return;

      const isOverflowing = ref.scrollHeight > ref.clientHeight;

      if (isOverflowing) {
        setIsReadMoreVisible(true);
      } else {
        setIsReadMoreVisible(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [shareClass.purpose]
  );

  return (
    <>
      <div className={classNames("p-3", classes["card"], className)} {...props}>
        {!!onEdit && hasFullAccess ? (
          <Button
            className={`p-0 mt-2 me-3 ${classes["action-button"]}`}
            variant="tertiary"
            onClick={buttonEditClickHandler}
          >
            <EditIcon className="icon-size-3" />
          </Button>
        ) : null}

        <Ui.l bold className="mb-1">
          {shareClass.name}
        </Ui.l>
        <Ui.s className="mb-2" style={{ color: scssVariables.foregroundMedium }}>
          {shareClass.hasPreferences ? t("preferred") : t("common")}
        </Ui.s>

        <P.s className={classes["share-class-description"]}>
          <div ref={refReinitialize}>{shareClass.purpose}</div>
        </P.s>

        {isReadMoreVisible ? (
          <Ui.s className={classes["read-more"]} onClick={openModalHandler}>
            Read more
          </Ui.s>
        ) : null}

        <hr className="mt-3" />

        <Possibilities
          hasVotingRights={shareClass.hasVotingRights}
          votingRightsMultiple={shareClass.votingRightsMultiple}
          hasDividends={shareClass.hasDividends}
          hasPreferences={shareClass.hasPreferences}
          isPreferred={shareClass.shareClassTypeId === ShareClassTypes.preferred}
        />

        {shareClass.shareClassTypeId === ShareClassTypes.preferred && (
          <>
            <hr className="mt-3" />

            <Ui.s bold className="mb-1">
              {t("liquidationPreferences.title")}
            </Ui.s>

            <div>
              {liquidationPreferencesList.map((item, index) => (
                <Ui.s className="mb-1" key={index}>
                  {item}
                </Ui.s>
              ))}
            </div>
          </>
        )}
      </div>

      <Modal centered show={isModalVisible} className={classes["description-modal"]} onHide={closeModalHandler}>
        <div className={classes["content"]}>
          <H.xs className="mb-3">Description</H.xs>

          <P.m>{shareClass?.purpose}</P.m>

          <Button variant="secondary" onClick={closeModalHandler}>
            Close
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default InfoCard;
