import { ChangeEvent, FC, useCallback, useState } from "react";
import { Col } from "react-bootstrap";
import axios from "axios";
import cn from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { defaultTo } from "ramda";

import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import { Button, H, P, TextArea, Ui } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { CloseIcon, ExclamationMarkCircle } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { createTranslation, TranslationNS } from "translation";

import { CapTableStakeholderDetailsType } from "../../CapTable.types";
import CapTableStore from "../../store";
import ShareClassesTable from "./ShareClassesTable";
import classes from "./ShareholderDetailsModal.module.scss";

const [t, tCommon] = [
  createTranslation(TranslationNS.pages, "company.capTable.shareholderDetails"),
  createTranslation(TranslationNS.common, "noAccess"),
];

type Props = {
  details: CapTableStakeholderDetailsType | null;
  getData: () => void;
};

const ShareholderDetailsOwnershipTab: FC<Props> = ({ details, getData }) => {
  const { hasFullAccess } = useFeatures(FEATURES.stakeholdersManagement);
  const fNumber = useFormatNumbers();

  const isPledgedModalOpen = CapTableStore.useStoreState((state) => state.isPledgedModalOpen);
  const { setIsPledgedModalOpen } = CapTableStore.useStoreActions((actions) => actions);

  const [isPledging, setIsPledging] = useState<boolean>(false);
  const [description, setDescription] = useState<string>();

  const isCompany = Boolean(details?.isCompanyOwned);

  const closePledgedModalHandler = useCallback(() => {
    setIsPledgedModalOpen(false);
  }, [setIsPledgedModalOpen]);

  const changeDescriptionHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => setDescription(e.target.value),
    []
  );

  const pledgedSharesHandler = useCallback(async () => {
    setIsPledging(true);

    try {
      if (details?.hasPledges) {
        await axios.post("/api/equity-management/cap-table/remove-pledges", {
          stakeholderId: details?.stakeholderId,
        });
      } else {
        await axios.post("/api/equity-management/cap-table/mark-pledges", {
          stakeholderId: details?.stakeholderId,
          description: description,
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsPledging(false);
      setIsPledgedModalOpen(false);
      setDescription("");
      getData();
    }
  }, [description, details?.hasPledges, details?.stakeholderId, getData, setIsPledgedModalOpen]);

  return (
    <div className={cn("m-4 pb-3", classes["details-container"])}>
      <div className={cn(classes["head"])} style={{ backgroundColor: scssVariables.element2 }}>
        <div className="d-flex justify-content-between align-items-center px-3">
          <div className="text-start">
            <Ui.m bold>Ownership breakdown</Ui.m>
            <Ui.xs color="foregroundLow">Shares owned by this shareholder</Ui.xs>
          </div>

          {!details?.hasPledges ? (
            <Button
              size="s"
              variant="secondary"
              isDisabled={!hasFullAccess}
              isOnlyIcon={isPledgedModalOpen}
              className={cn(classes.tag, "mt-auto")}
              tooltipTitle={!hasFullAccess ? tCommon("viewOnly") : undefined}
              tooltipMessage={!hasFullAccess ? tCommon("tooltip") : undefined}
              onClick={() => {
                setIsPledgedModalOpen(isPledgedModalOpen ? false : true);
              }}
            >
              {isPledgedModalOpen ? <CloseIcon /> : <Ui.s>{t("markAsPledged")}</Ui.s>}
            </Button>
          ) : null}
        </div>

        <AnimatePresence initial={false}>
          {isPledgedModalOpen && (
            <motion.div
              className={cn(classes["pledged-container"], "mx-3 mt-2 mb-4 text-start")}
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
            >
              <H.xxs>
                {t("markUserAsPledged", {
                  user: isCompany ? details?.companyName : `${details?.firstName} ${details?.lastName}`,
                })}
              </H.xxs>

              <Col xs={6} className="my-3">
                <TextArea
                  value={description}
                  label={t("enterDescription")}
                  onChange={changeDescriptionHandler}
                  isDisabled={123 - defaultTo(0, description?.length) <= 0}
                  info={`${123 - defaultTo(0, description?.length)} characters left`}
                />
              </Col>

              <div className="d-flex">
                <Button size="s" isDisabled={isPledging} onClick={pledgedSharesHandler} className="me-2">
                  {t("markAsPledged")}
                </Button>
                <Button size="s" variant="secondary" onClick={closePledgedModalHandler} isDisabled={isPledging}>
                  {t("cancel")}
                </Button>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      <div className="px-3">
        {details?.hasPledges ? (
          <div className={cn("mt-3", classes["pledges"])}>
            <div className="d-flex ">
              <ExclamationMarkCircle fontSize={24} color={scssVariables.information900} />

              <div className="text-start ms-2">
                <Ui.m bold>Pledged shares</Ui.m>
                <P.s>{details?.pledgesDescription}</P.s>
              </div>
            </div>

            <Button variant="information" className="ms-auto" onClick={pledgedSharesHandler} iconLeft={<CloseIcon />}>
              <Ui.s>Remove pledge</Ui.s>
            </Button>
          </div>
        ) : null}

        {!!details?.shareClasses?.length && (
          <div className="d-flex justify-content-evenly mt-3" style={{ gap: 24 }}>
            <div className={classes["info-section"]}>
              <Ui.s color="foregroundLow">Ownership</Ui.s>
              <Ui.xxl bold className="mt-2">
                {fNumber(details?.sharesTotalPercentage, "percent")}
              </Ui.xxl>
            </div>
            <div className={classes["info-section"]}>
              <Ui.s color="foregroundLow">Shares</Ui.s>
              <Ui.xxl bold className="mt-2">
                {fNumber(
                  details?.shareClasses?.reduce((acc, curr) => acc + curr.shares, 0),
                  "amount"
                )}
              </Ui.xxl>
            </div>
            <div className={classes["info-section"]}>
              <Ui.s color="foregroundLow">Total value</Ui.s>
              <Ui.xxl bold className="mt-2">
                {fNumber(details?.totalSharesValue, "value")}
              </Ui.xxl>
            </div>
          </div>
        )}
        <ShareClassesTable className="mt-3" data={details?.shareClasses || []} />
      </div>
    </div>
  );
};

export default ShareholderDetailsOwnershipTab;
