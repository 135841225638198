import { FC, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useFormikContext } from "formik";
import {defaultTo, isNil} from "ramda";

import { ChecksGroup, Helper, PeriodPickerFormik } from "common/components/atoms";
import { newPlanFieldsNames as f } from "common/plan/planFormFields";
import { createTranslation, TranslationNS } from "translation";

import { PlanForm } from "../../../../../types";

const t = createTranslation(TranslationNS.pages, "createPlan.basics.planSidebar");

type ExerciseClauseProps = {
  md?: number;
};

const fieldNames = [
  { key: f.retirement, keyUnit: f.retirementTimeUnit, amount: "0" },
  { key: f.disability, keyUnit: f.disabilityTimeUnit, amount: "0" },
  { key: f.terminationWithCause, keyUnit: f.terminationWithCauseTimeUnit, amount: "0" },
  { key: f.involuntaryTermination, keyUnit: f.involuntaryTerminationTimeUnit, amount: "0" },
  { key: f.voluntaryTermination, keyUnit: f.voluntaryTerminationTimeUnit, amount: "0" },
  { key: f.byPassingAway, keyUnit: f.byPassingAwayTimeUnit, amount: "0" },
];
const ExerciseClause: FC<ExerciseClauseProps> = ({ md = 12 }) => {
  const { values, setFieldValue } = useFormikContext<PlanForm>();

  const isFormDisabled =
    !isNil(values.subscriptionAvailable) && defaultTo(0, values.subscriptionAvailable.remainingAgreements) <= 0;

  useEffect(() => {
    if (values.isExcludedClause) {
      fieldNames.forEach(({ key, keyUnit }) => {
        setFieldValue(key, null);
        setFieldValue(keyUnit, "");
      });
    } else {
      fieldNames.forEach(({ key, keyUnit, amount }) => {
        if (values[key] === null) {
          setFieldValue(key, amount);
          setFieldValue(keyUnit, "Month");
        }
      });
    }
  }, [setFieldValue, values, values.isExcludedClause]);

  return (
    <div className="mb-5">
      <ChecksGroup className="mb-5" isDisabled={isFormDisabled}>
        <ChecksGroup.Check
          type="radio"
          label={t("includeClauses")}
          checked={!values.isExcludedClause}
          onChange={() => {
            setFieldValue("isExcludedClause", false);
          }}
          disabled={isFormDisabled}
        />

        <ChecksGroup.Check
          type="radio"
          label={t("excludeClauses")}
          checked={values.isExcludedClause}
          onChange={() => {
            setFieldValue("isExcludedClause", true);
          }}
          disabled={isFormDisabled}
        />
      </ChecksGroup>

      {values.isExcludedClause ? null : (
        <>
          <Row>
            <Col md={md}>
              <Helper.Question questionId={f.retirement} type="input">
                <PeriodPickerFormik
                  label={t("retirement")}
                  className="mb-3"
                  nameAmount={f.retirement}
                  namePeriod={f.retirementTimeUnit}
                  isDisabled={isFormDisabled}
                />
              </Helper.Question>
            </Col>
          </Row>
          <Helper.Answer answerId={f.retirement} className="mb-3" text={t("retirementAnswer")} />
          <Row>
            <Col md={md}>
              <Helper.Question questionId={f.disability} type="input">
                <PeriodPickerFormik
                  label={t("disability")}
                  className="mb-3"
                  nameAmount={f.disability}
                  namePeriod={f.disabilityTimeUnit}
                  isDisabled={isFormDisabled}
                />
              </Helper.Question>
            </Col>
          </Row>
          <Helper.Answer answerId={f.disability} className="mb-3" text={t("disabilityAnswer")} />
          <Row>
            <Col md={md}>
              <Helper.Question questionId={f.terminationWithCause} type="input">
                <PeriodPickerFormik
                  label={t("terminationWithCause")}
                  className="mb-3"
                  nameAmount={f.terminationWithCause}
                  namePeriod={f.terminationWithCauseTimeUnit}
                  isDisabled={isFormDisabled}
                />
              </Helper.Question>
            </Col>
          </Row>
          <Helper.Answer answerId={f.terminationWithCause} className="mb-3" text={t("terminationWithCauseAnswer")} />

          <Row>
            <Col md={md}>
              <Helper.Question questionId={f.involuntaryTermination} type="input">
                <PeriodPickerFormik
                  label={t("involuntaryTermination")}
                  className="mb-3"
                  nameAmount={f.involuntaryTermination}
                  namePeriod={f.involuntaryTerminationTimeUnit}
                  isDisabled={isFormDisabled}
                />
              </Helper.Question>
            </Col>
          </Row>
          <Helper.Answer
            answerId={f.involuntaryTermination}
            className="mb-3"
            text={t("involuntaryTerminationAnswer")}
          />
          <Row>
            <Col md={md}>
              <Helper.Question questionId={f.voluntaryTermination} type="input">
                <PeriodPickerFormik
                  label={t("voluntaryTermination")}
                  className="mb-3"
                  nameAmount={f.voluntaryTermination}
                  namePeriod={f.voluntaryTerminationTimeUnit}
                  isDisabled={isFormDisabled}
                />
              </Helper.Question>
            </Col>
          </Row>
          <Helper.Answer answerId={f.voluntaryTermination} className="mb-3" text={t("voluntaryTerminationAnswer")} />
          <Row>
            <Col md={md}>
              <Helper.Question questionId={f.byPassingAway} type="input">
                <PeriodPickerFormik
                  label={t("byPassingAway")}
                  namePeriod={f.byPassingAwayTimeUnit}
                  nameAmount={f.byPassingAway}
                  isDisabled={isFormDisabled}
                />
              </Helper.Question>
            </Col>
          </Row>
          <Helper.Answer answerId={f.byPassingAway} className="mt-3" text={t("byPassingAwayAnswer")} />
        </>
      )}
    </div>
  );
};

export default ExerciseClause;
