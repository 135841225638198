import { FC } from "react";

import { GetStakeholdersDTO } from "../../types";
import Row from "./row";

type PropsTypes = {
  stakeholders: GetStakeholdersDTO;
  onEdit: (id: number) => void;
  handleRemoveStakeholder?: (stakeholderId: number) => void;
};

const TableBody: FC<PropsTypes> = ({ stakeholders, onEdit, handleRemoveStakeholder }) => {
  return (
    <tbody>
      {stakeholders.map((props) => (
        <Row key={props.stakeholderId} {...props} onEdit={onEdit} handleRemoveStakeholder={handleRemoveStakeholder} />
      ))}
    </tbody>
  );
};

export default TableBody;
