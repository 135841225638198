import { FC, ReactNode } from "react";
import ProgressBar, { ProgressBarProps } from "react-bootstrap/ProgressBar";
import classNames from "classnames";

import { Ui } from "common/components/atoms";
import { scssVariables } from "common/utils/constants";

import classes from "./SpentProgressBar.module.scss";

export type PoolStatusProps = JSX.IntrinsicElements["div"] &
  Pick<ProgressBarProps, "now" | "max"> & {
    title?: ReactNode;
    valueText?: ReactNode;
    withoutCounter?: boolean;
  };

const SpentProgressBar: FC<PoolStatusProps> = ({
  className,
  title,
  valueText,
  withoutCounter,
  now = 0,
  max = 100,
  ...props
}) => {
  return (
    <div className={classNames(classes["wrap"], className)} {...props}>
      <div className={classes["info"]}>
        {!!title && (
          <Ui.s bold style={{ color: scssVariables.foregroundLow, marginBottom: 4 }}>
            {title}
          </Ui.s>
        )}

        <span>
          {Math.ceil((now * 100) / (max || 1))} % {valueText ? <>{valueText}</> : null}
        </span>
      </div>

      <ProgressBar now={now} max={max} className={classes["progress"]} variant={now > max ? "danger" : undefined} />
    </div>
  );
};

export default SpentProgressBar;
