import { FC, useCallback } from "react";
import FormGroup, { FormGroupProps } from "react-bootstrap/FormGroup";
import classNames from "classnames";
import { useFormikContext } from "formik";

import DatePicker, { DatePickerProps } from "common/components/atoms/DatePicker/DatePicker";
import { PoolFormValues } from "pages/equity-management/plans/wizards/create-pool/usePoolForm";
import { createTranslation, TranslationNS } from "translation";

export type ApprovalDateFieldProps = FormGroupProps;

const t = createTranslation(TranslationNS.common, "pool.components.poolForm.approvalDateField");

const PoolApprovalDateField: FC<ApprovalDateFieldProps> = (props) => {
  const { values, setFieldValue, errors, touched, handleBlur } =
    useFormikContext<Pick<PoolFormValues, "approvalDate">>();

  const handleChange = useCallback<DatePickerProps["onChange"]>(
    (date, name) => {
      if (name) {
        setFieldValue(name, date);
      }
    },
    [setFieldValue]
  );

  return (
    <FormGroup controlId="approvalDate" {...props}>
      <DatePicker
        isDateOnlyString
        label={t("label")}
        date={values?.approvalDate}
        className={classNames("me-1")}
        name={"approvalDate"}
        onChange={handleChange}
        onBlur={handleBlur}
        error={errors.approvalDate}
        isTouched={touched.approvalDate}
      />
    </FormGroup>
  );
};

export default PoolApprovalDateField;
