import { FC } from "react";

import { Ui } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";

import classes from "../../Tables.module.scss";

const ConvertibleLoanTableFooter: FC<{ amount: number }> = ({ amount }) => {
  const fNumber = useFormatNumbers();

  return (
    <tfoot className={classes.footer}>
      <tr>
        <th className="ps-4">
          <Ui.s>Total</Ui.s>
        </th>

        <th className="fw-400 text-end">
          <Ui.s>{fNumber(amount, "value")}</Ui.s>
        </th>

        <th colSpan={5} />
      </tr>
    </tfoot>
  );
};

export default ConvertibleLoanTableFooter;
