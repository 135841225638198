import { FC, MouseEvent, useCallback, useEffect, useMemo, useState } from "react";
import { FormSelectProps } from "react-bootstrap/FormSelect";
import classNames from "classnames";
import { useFormikContext } from "formik";

import Button from "common/components/atoms/Button/Button";
import Dropdown from "common/components/atoms/Dropdown/Dropdown";
import { PlusIcon } from "common/icons/svg";
import ShareClassFormOffcanvas from "common/shareClass/components/ShareClassFormOffcanvas/ShareClassFormOffcanvas";
import ShareClassService, { ShareClassServiceCompanyShareClassesResponse } from "common/shareClass/ShareClassService";
import { ShareClass } from "common/shareClass/types";
import { createTranslation, TranslationNS } from "translation";

import { PoolFormValues } from "../../../../../pages/equity-management/plans/wizards/create-pool/usePoolForm";
import { PoolTypesOfSource } from "../../../../enums/enum";
import LabelExtra from "./LabelExtra";

export type ShareClassFieldProps = JSX.IntrinsicElements["div"] &
  Pick<FormSelectProps, "onFocus"> & {
    getShareClassesCallback?: (shareClasses: ShareClassServiceCompanyShareClassesResponse) => void;
  };

const t = createTranslation(TranslationNS.common, "pool.components.poolForm.shareClassField");

const PoolShareClassField: FC<ShareClassFieldProps> = ({ className, onFocus, getShareClassesCallback, ...props }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const [shareClasses, setShareClasses] = useState<ShareClassServiceCompanyShareClassesResponse>([]);
  const [isShareClassFormIsOpen, setIsShareClassFormIsOpen] = useState<boolean>(false);

  const { values, setFieldValue, touched, errors, handleBlur, handleChange } =
    useFormikContext<Pick<PoolFormValues, "shareSourceTypeId" | "shareClassId">>();

  const selectedShareClass = useMemo<ShareClassServiceCompanyShareClassesResponse[number] | undefined>(
    () => shareClasses.find(({ id }) => id === +values.shareClassId),
    [shareClasses, values.shareClassId]
  );

  const setShareClassFieldValue = useCallback(
    (value: string | number): void => {
      setFieldValue("shareClassId", +value);
    },
    [setFieldValue]
  );

  useEffect(() => {
    setFieldValue("allowedNumberOfShares", 2000000000);
  }, [setFieldValue]);

  const addShareClassButtonClickHandler = useCallback((e: MouseEvent<HTMLButtonElement>): void => {
    setIsShareClassFormIsOpen(true);

    e.currentTarget.blur();
  }, []);

  const shareClassFormSaveHandler = useCallback(
    (shareClass: ShareClass): void => {
      setShareClasses((state) => [...state, shareClass]);

      setShareClassFieldValue(shareClass.id);

      setIsShareClassFormIsOpen(false);
    },
    [setShareClassFieldValue]
  );

  const shareClassFormHideHandler = useCallback((): void => {
    setIsShareClassFormIsOpen(false);
  }, []);

  useEffect(() => {
    if (shareClasses.length === 0) {
      setLoading(true);
      ShareClassService.companyShareClasses()
        .then(({ data }) => {
          setShareClasses(data);
          getShareClassesCallback?.(data);
          if (!values.shareClassId) {
            setShareClassFieldValue(data[0]?.id);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [getShareClassesCallback, setShareClassFieldValue, shareClasses.length, values.shareClassId]);

  return (
    <>
      <div className={classNames("d-flex flex-column position-relative", className)} {...props}>
        <LabelExtra shareClassId={selectedShareClass?.id} />
        <Dropdown
          label={t("label")}
          selectedValue={values.shareClassId}
          optionsIsObject
          name={"shareClassId"}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.shareClassId}
          isTouched={touched.shareClassId}
          options={shareClasses}
          isLoading={loading}
          isDisabled={loading}
        />

        {!!values.shareSourceTypeId && +values.shareSourceTypeId === PoolTypesOfSource.new && (
          <div>
            <Button
              variant="tertiary"
              size="s"
              className="mt-1"
              iconLeft={<PlusIcon />}
              onClick={addShareClassButtonClickHandler}
            >
              {t("addNewClass")}
            </Button>
          </div>
        )}
      </div>

      <ShareClassFormOffcanvas
        show={isShareClassFormIsOpen}
        onSave={shareClassFormSaveHandler}
        onHide={shareClassFormHideHandler}
        onCancel={shareClassFormHideHandler}
      />
    </>
  );
};

export default PoolShareClassField;
