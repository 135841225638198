import { useCallback } from "react";

import { Collapsible, Ui } from "common/components/atoms";
import { ChevronDownIcon } from "common/icons/svg";
import { CollapsibleHeaderProps } from "common/types/Collapsible.types";
import { createTranslation, TranslationNS } from "translation";

import SOAgreementContext from "../../../contexts/SOAgreementContext";
import PlanDetailsContent from "./planDetails/PlanDetailsContent";
import classes from "./SOAgreementDetails.module.scss";

/**
 * Description: so agreement details part, just UI component to show/hide agreement details
 */

const t = createTranslation(TranslationNS.common, "organisms.agreement.agreementDetails");

const SOAgreementDetails = () => {
  const { agreementDetails } = SOAgreementContext.useStoreState((state) => state);

  const Header = useCallback(({ activeEventKey, onClick }: CollapsibleHeaderProps) => {
    return (
      <div className={classes["collapsible-head"]} onClick={onClick}>
        <div>
          <Ui.l bold>{t("title")}</Ui.l>

          <Ui.s color="foregroundLow" className="mt-1">
            If the agreement details below differ from the signed agreement, the signed agreement will take precedence.
          </Ui.s>
        </div>

        <ChevronDownIcon fontSize={24} className={classes["chevron"]} direction={activeEventKey ? "top" : "bottom"} />
      </div>
    );
  }, []);

  return (
    <Collapsible defaultOpen withoutDivider Header={Header} classNames="mt-3">
      <PlanDetailsContent
        agreementDetails={
          agreementDetails
            ? {
                ...agreementDetails,
                pricePerShare: agreementDetails?.sharePrice || 0,
                shareMarketValue: agreementDetails?.vestedSharesValue || 0,
                vestingCliff: agreementDetails?.cliff || 0,
                shareClassName: agreementDetails?.shareClass || "",
                vestingStartsAt: agreementDetails?.planStartDate,
                vestingEndsAt: agreementDetails?.planEndDate,
                exerciseExpiryDate: agreementDetails?.optionsExpirationDate,
              }
            : null
        }
        excludePostTerminationClauses={Boolean(agreementDetails?.excludePostTerminationClauses)}
      />
    </Collapsible>
  );
};

export default SOAgreementDetails;
