import { FC, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import cn from "classnames";

import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import { LoaderContent } from "common/components/atoms";
import Tag from "common/components/atoms/Tag/Tag";
import ToggleButton from "common/components/atoms/ToggleButton/ToggleButton";
import useCheckAdmin from "common/hooks/useCheckAdmin";
import useCheckSubscription from "common/hooks/useCheckSubscription";
import useDocumentTitleUpdate from "common/hooks/useDocumentTitleUpdate";
import PageContent from "common/layout/MainLayout/PageContent/PageContent";
import { createTranslation, TranslationNS } from "translation";

import InviteStakeholdersModal from "../stakeholders-management/components/invite-stakeholders-modal/invite-stakeholders-modal";
import Actual from "./Actual/Actual";
import classes from "./CapTable.module.scss";
import AddMoreShareholders from "./common/AddMoreShareholders/AddMoreShareholders";
import FullyDiluted from "./FullyDiluted/FullyDiluted";
import InviteShareholderModal from "./InviteShareholderModal/InviteShareholderModal";
import CapTableStore from "./store";

const CapTableVariants = {
  Diluted: "Diluted",
  Actual: "Actual",
} as const;

type CapTableVariantsType = keyof typeof CapTableVariants;

const t = createTranslation(TranslationNS.pages, "company.capTable");
const tCommon = createTranslation(TranslationNS.common, "noAccess");

const CapTable: FC = () => {
  useDocumentTitleUpdate(t("title"));

  const { companyId } = useParams<{ companyId: string }>();

  const { isLoading } = CapTableStore.useStoreState((state) => state);
  const { dilutedData } = CapTableStore.useStoreState((state) => state);
  const { getActualDataThunk, getDilutedDataThunk } = CapTableStore.useStoreActions((actions) => actions);

  const [dataAccepted, setDataAccepted] = useState<boolean>(false);
  const [capTableVariant, setCapTableVariant] = useState<CapTableVariantsType>(CapTableVariants.Actual);

  const isSubscriptionActive = useCheckSubscription();
  const isAdmin = useCheckAdmin();

  const fetchCaptableData = useCallback(() => {
    try {
      if (companyId) {
        capTableVariant === CapTableVariants.Actual
          ? getActualDataThunk(+companyId)
          : getDilutedDataThunk({ companyId: +companyId, valuation: dilutedData?.defaultSimulatedValuation });
      }
    } finally {
      setDataAccepted(true);
    }
  }, [capTableVariant, companyId, dilutedData?.defaultSimulatedValuation, getActualDataThunk, getDilutedDataThunk]);

  useEffect(() => {
    fetchCaptableData();
    // eslint-disable-next-line
  }, [capTableVariant, companyId]);

  useEffect(() => {
    if (capTableVariant === CapTableVariants.Diluted && dataAccepted) {
      fetchCaptableData();
    }
  }, [capTableVariant, dataAccepted, fetchCaptableData]);

  const capTableActualAccess = useFeatures(FEATURES.capTable);
  const capTableDilutedAccess = useFeatures(FEATURES.fullyDilutedCapTable);

  return (
    <PageContent data-testid="cap-table-page-test-id" className={classes["cap-table-page"]}>
      <PageContent.Header className="d-flex align-items-center">
        <div className="d-flex align-items-center">
          <PageContent.Header.Title className="me-2">{t("title")}</PageContent.Header.Title>
          {!capTableActualAccess.hasFullAccess && <Tag variant="access">{tCommon("viewOnly")}</Tag>}
        </div>

        <ToggleButton
          className={cn(classes["toggle-actions"], {
            [classes["no-subcription"]]: isAdmin ? false : !isSubscriptionActive,
          })}
          firstVariant={{ title: t("toggleViewBtn.actual"), value: CapTableVariants.Actual }}
          secondVariant={{
            title: t("toggleViewBtn.fullyDiluted"),
            value: CapTableVariants.Diluted,
            tooltipMessage: isAdmin
              ? false
              : !isSubscriptionActive
              ? t.el("featureBlocked", {
                  values: {
                    link: "hello@unlisted.ai",
                  },
                  components: [<a key={1} href="mailto:hello@unlisted.ai" style={{ textDecoration: "underline" }} />],
                })
              : null,
          }}
          selected={capTableVariant}
          onClick={(selectedVariant) => setCapTableVariant(selectedVariant as CapTableVariantsType)}
          isDisabled={isAdmin ? false : !capTableDilutedAccess.hasViewAccess || !isSubscriptionActive}
          dataTestId="toggle-mode"
        />
      </PageContent.Header>
      <AddMoreShareholders />
      <LoaderContent show={isLoading}>
        <div className={classes["cap-table"]}>
          <div>
            {capTableVariant === CapTableVariants.Actual && <Actual />}
            {capTableVariant === CapTableVariants.Diluted && <FullyDiluted />}
          </div>
        </div>
      </LoaderContent>

      <InviteShareholderModal />
      <InviteStakeholdersModal onClose={fetchCaptableData} />
    </PageContent>
  );
};

export default CapTable;
