import { FC, ReactNode, useMemo, useState } from "react";
import cn from "classnames";

import { CloseIcon, InformationCircleOutlinedIcon, WarningIcon } from "common/icons/svg";

import { scssVariables } from "../../../utils/constants";
import Button from "../Button/Button";
import { P, Ui } from "../Typography";
import classes from "./InfoAlert.module.scss";

export enum AlertTypes {
  Info = "Info",
  Success = "Success",
  Warning = "Warning",
  Neutral = "Neutral",
}

export type InfoAlertProps = {
  height?: number;
  className?: string;
  title?: string;
  content?: ReactNode;
  customContent?: ReactNode;
  type?: keyof typeof AlertTypes;
  closable?: boolean;
};

const InfoAlert: FC<InfoAlertProps> = ({
  height,
  title,
  content,
  className,
  customContent,
  type = AlertTypes.Info,
  closable,
}) => {
  const [isOpen, setIsOpen] = useState(true);

  function setClass(type: keyof typeof AlertTypes) {
    switch (type) {
      case AlertTypes.Info: {
        return "info-alert-type-info";
      }
      case AlertTypes.Success: {
        return "info-alert-type-success";
      }
      case AlertTypes.Warning: {
        return "info-alert-type-warning";
      }
      case AlertTypes.Neutral: {
        return "info-alert-type-neutral";
      }
    }
  }

  const Icon = useMemo(() => {
    switch (type) {
      case AlertTypes.Info: {
        return InformationCircleOutlinedIcon;
      }
      case AlertTypes.Success: {
        return InformationCircleOutlinedIcon;
      }
      case AlertTypes.Warning: {
        return WarningIcon;
      }
      case AlertTypes.Neutral: {
        return InformationCircleOutlinedIcon;
      }
      default:
        return InformationCircleOutlinedIcon;
    }
  }, [type]);

  if (!isOpen) return null;

  return (
    <div
      data-testid="info-alert-container-test-id"
      // @ts-ignore
      className={cn("d-flex", classes["info-alert"], classes[setClass(type)], className)}
      style={{ height }}
    >
      {customContent ? (
        <>
          {customContent}
          {closable && (
            <div className="ms-5">
              <Button variant="dark" size="s" isOnlyIcon className="p-0" onClick={() => setIsOpen(false)}>
                <CloseIcon />
              </Button>
            </div>
          )}
        </>
      ) : (
        <div className={cn("d-flex justify-content-center flex-column", className)}>
          <div className="d-flex px-1 pb-1">
            <Icon width={24} height={24} color={scssVariables.information900} />
            {title ? (
              <Ui.m bold className="ms-2">
                {title}
              </Ui.m>
            ) : null}
          </div>

          <P.s className="ms-6">{content}</P.s>

          {closable && (
            <div className="ms-5">
              <Button variant="tertiary" isOnlyIcon className="p-0" onClick={() => setIsOpen(false)}>
                <CloseIcon />
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default InfoAlert;
