import { FC, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import cn from "classnames";
import { useFormikContext } from "formik";

import { Button, Ui } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { CheckIcon, LoadingIcon, ShareClassesIcon, WarningIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { transformDateToCommonDateFormat } from "common/utils/functions";
import { createTranslation, TranslationNS } from "translation";

import { useTransactionsService } from "../../../../transactions-service";
import { AvailableShareClass } from "../../../../types";
import { BuySellFields } from "../../buy-sell/form-fields";
import { ShareDetailsFieldsType } from "../shares-details/types";
import { TransactionDetailsFieldsType } from "../transaction-details/types";
import classes from "./styles.module.scss";
import { SellerDetailsFieldsType } from "./types";

type PropsTypes = {
  setSelectedShareClass: (shareClass: AvailableShareClass | null) => void;
  selectedShareClassId: number | null;
  editingTransactionId?: number;
};

const t = createTranslation(TranslationNS.pages, "company.transactions.buySellForm.sellerDetails");

const AvailableShareClassesList: FC<PropsTypes> = ({
  setSelectedShareClass,
  selectedShareClassId,
  editingTransactionId,
}) => {
  const fNumber = useFormatNumbers();
  const { companyId = "0" } = useParams<{ companyId: string }>();
  const [isLoading, setIsLoading] = useState(false);

  const { values, setFieldValue } = useFormikContext<
    SellerDetailsFieldsType & TransactionDetailsFieldsType & ShareDetailsFieldsType
  >();

  const [availableShareClasses, setAvailableShareClasses] = useState<AvailableShareClass[]>([]);

  const { getAvailableShareClasses } = useTransactionsService(companyId);

  useEffect(() => {
    if (values.sellerStakeholderId && values.transactedAt) {
      setIsLoading(true);

      const requestBody = {
        companyId: Number(companyId),
        stakeholderId: values.sellerStakeholderId,
        transactionDate: values.transactedAt,
      };

      getAvailableShareClasses(
        editingTransactionId ? { ...requestBody, transactionId: editingTransactionId } : requestBody
      ).then((shareClasses) => {
        setAvailableShareClasses(shareClasses);

        const selectedShareClassInTheNewList = shareClasses.find(
          (shareClass) => shareClass.shareClassId === values.shareClassId
        );

        if (!selectedShareClassInTheNewList) {
          setSelectedShareClass(null);
          setFieldValue(BuySellFields.numberOfShares, 0);
          setFieldValue(BuySellFields.shareClassId, 0);
        }

        if (selectedShareClassInTheNewList) {
          setSelectedShareClass(selectedShareClassInTheNewList);
          setFieldValue(BuySellFields.shareClassId, selectedShareClassInTheNewList.shareClassId);
        }

        if (shareClasses?.length === 1) {
          setSelectedShareClass(shareClasses[0]);
          setFieldValue(BuySellFields.shareClassId, shareClasses[0].shareClassId);
        }
      });
      setIsLoading(false);
    } else {
      setAvailableShareClasses([]);
    }
  }, [
    companyId,
    editingTransactionId,
    getAvailableShareClasses,
    selectedShareClassId,
    setFieldValue,
    setSelectedShareClass,
    values.sellerStakeholderId,
    values.shareClassId,
    values.transactedAt,
  ]);

  const setShareClass = useCallback(
    (shareClass: AvailableShareClass) => {
      setFieldValue(BuySellFields.shareClassId, shareClass.shareClassId);
      setFieldValue(BuySellFields.numberOfShares, 0);
      setSelectedShareClass(shareClass);
    },
    [setFieldValue, setSelectedShareClass]
  );

  if (!values.sellerStakeholderId) {
    return null;
  }

  const isDateValid = !isNaN(Date.parse(values.transactedAt || new Date().toDateString()));

  return (
    <div className={cn("pt-3", classes.topDivider)}>
      {isLoading && <LoadingIcon />}

      {values.sellerStakeholderId && !values.transactedAt && (
        <div className="d-flex align-items-center">
          <div className={classes.warningIcon}>
            <WarningIcon color={scssVariables.warning900} />
          </div>
          <Ui.m className="text-low ms-2">{t("dateNotSelected")}</Ui.m>
        </div>
      )}

      {values.sellerStakeholderId && isDateValid && availableShareClasses.length === 0 && (
        <>
          <div className="d-flex align-items-center mb-2">
            <div className={classes.warningIcon}>
              <WarningIcon color={scssVariables.warning900} />
            </div>
            <Ui.m className="text-low ms-2">{t("emptyState")}</Ui.m>
          </div>
          <Ui.xs className="text-low">{`${t("transactionDate")} ${transformDateToCommonDateFormat(
            values.transactedAt
          )}`}</Ui.xs>
        </>
      )}

      {availableShareClasses.length > 0 && (
        <div>
          {availableShareClasses.map((shareClass, index) => (
            <div
              className={cn("d-flex justify-content-between", { "mb-4": index !== availableShareClasses.length - 1 })}
              key={shareClass.shareClassName}
            >
              <div className="d-flex">
                <div className={classes.shareClassIcon}>
                  <ShareClassesIcon color={scssVariables.foregroundLow} />
                </div>
                <div className="ms-2">
                  <Ui.m bold>{shareClass.shareClassName}</Ui.m>
                  <Ui.s className="text-low">
                    {fNumber(shareClass.sharesAvailable, "amount")} {t("sharesAvailable")} • {shareClass.shareClassType}
                  </Ui.s>
                </div>
              </div>

              <Button
                size="s"
                variant={shareClass.shareClassId === selectedShareClassId ? "success" : "secondary"}
                onClick={() => setShareClass(shareClass)}
                iconLeft={shareClass.shareClassId === selectedShareClassId ? <CheckIcon /> : undefined}
              >
                {shareClass.shareClassId === selectedShareClassId ? t("selectedBtn") : t("selectBtn")}
              </Button>
            </div>
          ))}

          {isDateValid && !selectedShareClassId && (
            <Ui.xs className="text-low mt-4">{`${t("availableOn")} ${transformDateToCommonDateFormat(
              values.transactedAt
            )}`}</Ui.xs>
          )}
        </div>
      )}
    </div>
  );
};

export default AvailableShareClassesList;
