import { FC, memo, useMemo } from "react";
import { Col, Row } from "react-bootstrap";

import { Check, ChecksGroup, Dropdown, TextField, Tooltip, Ui } from "common/components/atoms";
import { QuestionCircleIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { StakeholderFormType } from "./invite-stakeholders-modal";

type PropsTypes = {
  index: number;
  stakeholder: StakeholderFormType;
  isTouched?: boolean;
  error?: string;
  setFieldValue: (name: string, value: any) => void;
};

const t = createTranslation(TranslationNS.pages, "company.stakeholdersManagement.multipleInvitationModal");

const StakeholderRow: FC<PropsTypes> = ({ index, stakeholder, error, isTouched, setFieldValue }) => {
  const accessRoles = useStoreState((store) => store.common.dropdowns?.accessRoles);

  const hintFeaturesList = useMemo(() => {
    return accessRoles?.find((role) => role.id === stakeholder.roleId)?.featureAccesses || [];
  }, [accessRoles, stakeholder.roleId]);

  const roleName = useMemo(() => {
    return accessRoles?.find((role) => role.id === stakeholder.roleId)?.name || "";
  }, [accessRoles, stakeholder.roleId]);

  return (
    <Row className={error ? "mb-3" : "mb-2"}>
      <Col>
        <ChecksGroup>
          <Check
            name={index.toString()}
            label={
              stakeholder?.companyName ? (
                <div>
                  <Ui.s bold>{stakeholder.companyName}</Ui.s>
                  <Ui.s>{stakeholder.name}</Ui.s>
                </div>
              ) : (
                <Ui.s>{stakeholder.name}</Ui.s>
              )
            }
            checked={stakeholder.isSelected}
            onChange={(e) => setFieldValue(index.toString(), { ...stakeholder, isSelected: e.target.checked })}
          />
        </ChecksGroup>
      </Col>
      <Col>
        <TextField
          placeholder={t("emailPlaceholder")}
          name={index.toString()}
          value={stakeholder.email}
          onChange={(e) => setFieldValue(index.toString(), { ...stakeholder, email: e.target.value })}
          isTouched={isTouched}
          error={error}
          isDisabled={!stakeholder.isSelected}
        />
      </Col>
      <Col className="d-flex">
        <Dropdown
          className="me-1"
          name="roleId"
          options={accessRoles || []}
          optionsIsObject
          selectedValue={stakeholder.roleId}
          onChange={(e) => setFieldValue(index.toString(), { ...stakeholder, roleId: e.target.value })}
          isDisabled={!stakeholder.isSelected}
        />
        <Tooltip
          popupContent={
            <div className="d-flex flex-column align-items-start">
              <Ui.xs bold className="ps-half mb-half">
                {roleName}
              </Ui.xs>
              <ul className="d-flex flex-column align-items-start ps-2 mb-0">
                <li>
                  <Ui.xs>{t("tooltipOwnership")}</Ui.xs>
                </li>
                {hintFeaturesList.map((feature) => (
                  <li key={feature}>
                    <Ui.xs>{feature}</Ui.xs>
                  </li>
                ))}
              </ul>
            </div>
          }
          placement="right"
        >
          <QuestionCircleIcon color={scssVariables.foregroundLow} fontSize={40} className="my-auto" />
        </Tooltip>
      </Col>
    </Row>
  );
};

export default memo(StakeholderRow);
