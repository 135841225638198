import React, { FC, memo, useCallback } from "react";
import classNames from "classnames";
import * as R from "ramda";

import { Ui } from "common/components/atoms/Typography";
import { FilledArrowDownIcon } from "common/icons/svg";
import { OwnershipProgramTableData, sortingParams } from "common/types/Collapsible.types";

import { CurrentSortValue } from "./ProgramContainer";
import classes from "./ProgramContainer.module.scss";

type ProgramTableHeadProps = {
  rows: { key: string; value: string }[];
  sortedValue: CurrentSortValue;
  setSortedValue: (e: CurrentSortValue) => void;
};

const ProgramTableHead: React.FC<ProgramTableHeadProps> = ({ rows, sortedValue, setSortedValue }) => {
  return (
    <thead className={classes.programTableHead}>
      <tr>
        {rows.map((el) => (
          <HeadItem key={el.key} item={el} setSortedValue={setSortedValue} sortedValue={sortedValue} />
        ))}
      </tr>
    </thead>
  );
};

type HeadItemProps = {
  item: { key: string; value: string };
  sortedValue: CurrentSortValue;
  setSortedValue: (e: CurrentSortValue) => void;
};

const HeadItem: FC<HeadItemProps> = ({ item, setSortedValue, sortedValue }) => {
  const handleSort = useCallback(() => {
    setSortedValue({
      field: item.key as keyof OwnershipProgramTableData,
      type: sortedValue.type === sortingParams.inc ? sortingParams.desc : sortingParams.inc,
    });
  }, [item.key, setSortedValue, sortedValue.type]);

  const isSortedAndInc = R.and(sortedValue.field === item.key, sortedValue.type === sortingParams.inc);

  return (
    <th>
      <div className="d-flex align-items-center cursor-pointer" onClick={handleSort}>
        <Ui.xs style={{ margin: "0 5px 0 0", fontWeight: 400 }}>{item.value}</Ui.xs>
        <div className="d-flex flex-column justify-content-center">
          <span
            className={classNames(classes.sort, {
              [classes.asc]: isSortedAndInc,
              [classes.active]: sortedValue.field === item.key,
            })}
          >
            <FilledArrowDownIcon />
          </span>
        </div>
      </div>
    </th>
  );
};

export default memo(ProgramTableHead);
