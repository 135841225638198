import { FC, useCallback, useEffect, useRef, useState } from "react";
import cn from "classnames";
import { and, flatten, isEmpty, isNil } from "ramda";

import CollapsibleContainer from "common/components/atoms/Collapsible/Collapsible";
import Table from "common/components/atoms/Table/Table";
import { Ui } from "common/components/atoms/Typography";
import { ChevronRightSmallIcon } from "common/icons/svg";
import { CollapsibleHeaderProps } from "common/types/Collapsible.types";
import { scssVariables } from "common/utils/constants";
import { createTranslation, TranslationNS } from "translation";

import DocumentsContext, { DocumentFilters } from "../../DocumentsContext";
import classes from "../CommonStyles.module.scss";
import EmptyDocumentsRow from "../EmptyDocumentsRow";
import StandaloneDocumentsTableBody from "./StandaloneDocumentsTableBody";

const t = createTranslation(TranslationNS.pages, "company.documents");

const StandaloneDocuments: FC = () => {
  const { filteredDocuments, searchValue, filterValue } = DocumentsContext.useStoreState((state) => state);
  const ref = useRef<HTMLDivElement>(null);
  const collapseRef = useRef<any>(null);
  const [hasClicked, setHasClicked] = useState<boolean>(false);

  const DocumentsHeader = useCallback(
    ({ activeEventKey, onClick }: CollapsibleHeaderProps) => (
      <div className={classes["header"]} onClick={onClick} ref={ref}>
        <div className="d-flex flex-column">
          <div className="d-flex align-items-center">
            <Ui.xl bold>{t("standAloneDocs.title")}</Ui.xl>
            {and(!isNil(filteredDocuments), !isEmpty(filteredDocuments)) && (
              <div className={classes["documents-rounded-container"]}>
                <Ui.xs>
                  {
                    flatten(
                      filteredDocuments.standaloneDocuments.map((el) =>
                        el.documents.filter((item) =>
                          item.fileName?.toLocaleLowerCase()?.includes(searchValue?.toLowerCase())
                        )
                      )
                    ).length
                  }
                </Ui.xs>
              </div>
            )}
          </div>

          <Ui.xs style={{ color: scssVariables.foregroundLow }}>{t("standAloneDocs.description")}</Ui.xs>
        </div>

        <div data-testid="company-documents-open-table-test-id" className="d-flex align-items-center">
          <ChevronRightSmallIcon
            className={cn(classes["icon"], {
              [classes["open"]]: Boolean(activeEventKey),
            })}
          />
        </div>
      </div>
    ),
    [filteredDocuments, searchValue]
  );

  useEffect(() => {
    if (!searchValue.trim()) {
      setHasClicked(false);

      if (collapseRef?.current?.state?.status === "entered") {
        ref.current?.click();
      }
    }
  }, [searchValue]);

  useEffect(() => {
    if (filteredDocuments.standaloneDocuments.length && searchValue.trim() && !hasClicked) {
      ref.current?.click();
      setHasClicked(true);
    }
  }, [filteredDocuments.standaloneDocuments.length, hasClicked, searchValue]);

  if (isEmpty(filteredDocuments.standaloneDocuments) && (searchValue || filterValue !== DocumentFilters.showAll)) {
    return null;
  }

  return (
    <CollapsibleContainer className="mt-3" Header={DocumentsHeader} collapseRef={collapseRef}>
      <div className={cn("d-flex flex-column", classes["documents-content"])}>
        {filteredDocuments.standaloneDocuments.length ? (
          <Table
            className={classes.table}
            headComponent={null}
            bodyComponent={<StandaloneDocumentsTableBody data={filteredDocuments.standaloneDocuments} />}
          />
        ) : (
          <table>
            <tbody>
              <EmptyDocumentsRow iconClassName="ms-4" label={searchValue?.trim() ? t("noResults") : undefined} />
            </tbody>
          </table>
        )}
      </div>
    </CollapsibleContainer>
  );
};

export default StandaloneDocuments;
