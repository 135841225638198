import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { getPath } from "app/Router/RouterHelper";
import { LoaderContent } from "common/components/atoms";
import Button from "common/components/atoms/Button/Button";
import WithRouteAnimation from "common/HOC/WithRouteAnimation";
import { ArrowLeftIcon, ArrowRightIcon } from "common/icons/svg";
import { useStoreActions, useStoreState } from "store/store";

import { ShareClasses } from "../../PostponedComponents/onboard-manager-wizard/wizard-steps";
import IncludeTransactionModal from "../company-information/IncludeTransactionModal/IncludeTransactionModal";
import classes from "./OnboardCompanyShareClasses.module.scss";

const OnboardCompanyShareClasses = () => {
  const { companyId } = useParams<{ companyId?: string }>();
  const navigate = useNavigate();
  const company = useStoreState((state) => state.companyOnboardingModel.company);
  const shareClassesList = useStoreState((state) => state.companyOnboardingModel.companyShareClasses);
  const { getShareClassesThunk } = useStoreActions((actions) => actions.companyOnboardingModel);
  const [loading, setLoading] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const handleManageModal = useCallback(() => {
    setShowModal((prev) => !prev);
  }, []);

  useEffect(() => {
    if (companyId) {
      setLoading(true);
      getShareClassesThunk(Number(companyId)).finally(() => setLoading(false));
    }
  }, [companyId, getShareClassesThunk]);

  const handleClickBack = useCallback(() => {
    navigate(getPath(["onboard", "company", "companyInformation"], { companyId }));
  }, [companyId, navigate]);

  const handleClickContinue = useCallback(() => {
    // handleManageModal();
    navigate(getPath(["onboard", "company", "importStakeholders"], { companyId }));
  }, [companyId, navigate]);

  const onAddShareClass = useCallback(() => {
    if (company?.companyId) getShareClassesThunk(company?.companyId).then();
  }, [company?.companyId, getShareClassesThunk]);

  return (
    <div className={classes.wrap}>
      <LoaderContent show={loading}>
        <ShareClasses
          shareClasses={shareClassesList}
          onSubmit={() => null}
          companyId={company?.companyId || 0}
          onAddShareClass={onAddShareClass}
        />
        <div className="d-flex justify-content-between">
          <Button variant="secondary" onClick={handleClickBack} isOnlyIcon>
            <ArrowLeftIcon />
          </Button>

          <Button onClick={handleClickContinue} isOnlyIcon>
            <ArrowRightIcon />
          </Button>
        </div>
        <IncludeTransactionModal show={showModal} handleClose={handleManageModal} />
      </LoaderContent>
    </div>
  );
};

export default WithRouteAnimation(OnboardCompanyShareClasses);
