import { useEffect, useState } from "react";

const useDebounce = <T>(value: T, delay: number, preventDebounce: boolean = false) => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    if (preventDebounce) return;

    const timerId = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(timerId);
    };
  }, [value, delay, preventDebounce]);

  return debouncedValue;
};

export default useDebounce;
