import { FC, useCallback, useContext, useEffect, useState } from "react";
import { AccordionContext } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { useTransactionsService } from "../../../../transactions-service";
import { SplitPreviewGetResponseDTO } from "../../../../types";
import { splitPreviewDefault } from "../../../forms/form-sections/split-details/split-details";
import SplitOverview from "../../../forms/form-sections/split-details/split-overview";

type PropsTypes = {
  multiplier: number;
  transactionId: number;
  isConfirmed: boolean;
  description?: string;
  eventKey: string;
};

const SplitBody: FC<PropsTypes> = ({ multiplier, transactionId, isConfirmed, description, eventKey }) => {
  const { companyId = "0" } = useParams();
  const { activeEventKey } = useContext(AccordionContext);
  const { getSplitPreview, getSplitSnapshot } = useTransactionsService(companyId);

  const [splitData, setSplitData] = useState<SplitPreviewGetResponseDTO>(splitPreviewDefault);
  const [isLoading, setIsLoading] = useState(true);

  const getSplitData = useCallback(async () => {
    setIsLoading(true);
    if (isConfirmed) {
      setSplitData((await getSplitSnapshot(transactionId)) || splitPreviewDefault);
    } else {
      setSplitData((await getSplitPreview(multiplier)) || splitPreviewDefault);
    }

    setIsLoading(false);
  }, [getSplitPreview, getSplitSnapshot, isConfirmed, multiplier, transactionId]);

  useEffect(() => {
    // check if accordion body is opened
    if (eventKey === activeEventKey) {
      getSplitData();
    }
  }, [activeEventKey, eventKey, getSplitData]);

  return (
    <SplitOverview
      splitPreview={splitData}
      isConfirmed={isConfirmed}
      isLoading={isLoading}
      description={description}
      multiplier={multiplier}
    />
  );
};

export default SplitBody;
