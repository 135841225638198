import { FC, useCallback } from "react";
import { useParams } from "react-router-dom";

import { notify } from "common/utils/notify/notifyFunction";
import { createTranslation, TranslationNS } from "translation";

import { useTransactionsService } from "../../../transactions-service";
import { NominalValueGetResponseDTO } from "../../../types";
import ChangeNominalValueForm from "./change-nominal-value-form";

const t = createTranslation(TranslationNS.pages, "company.transactions");

type Props = {
  isOpen: boolean;
  dataToEdit: NominalValueGetResponseDTO | null;
  onSubmit: (transactionId?: string, keepFormOpen?: boolean) => void;
  onClose: () => void;
  isDisabled: boolean;
  minTransactionDate?: string;
  setIsNominalValueDataUpdated?: (val: boolean) => void;
  initialNominalValue?: number;
};

const ChangeNominalValueContainer: FC<Props> = ({
  isOpen,
  onSubmit,
  dataToEdit,
  onClose,
  setIsNominalValueDataUpdated,
  minTransactionDate,
  initialNominalValue,
}) => {
  const { companyId = "0" } = useParams<{ companyId: string }>();
  const { postChangeNominalValue } = useTransactionsService(companyId);

  const handleSubmitNominalValueForm = useCallback(
    async (changeValueData: FormData) => {
      const createdSuccessfully = await postChangeNominalValue(changeValueData);

      setIsNominalValueDataUpdated?.(Boolean(createdSuccessfully));

      if (createdSuccessfully) {
        notify(t("changeNominalValueForm.successUpdated"), true, "success", 5000, false, "top-center");

        onSubmit(String(0));
        return true;
      } else {
        return false;
      }
    },
    [postChangeNominalValue, setIsNominalValueDataUpdated, onSubmit]
  );

  return (
    <>
      {isOpen ? (
        <ChangeNominalValueForm
          initialNominalValue={initialNominalValue}
          onSubmit={handleSubmitNominalValueForm}
          minTransactionDate={minTransactionDate}
          onClose={onClose}
        />
      ) : null}

      {dataToEdit ? (
        <ChangeNominalValueForm
          dataToEdit={dataToEdit}
          initialNominalValue={initialNominalValue}
          onSubmit={handleSubmitNominalValueForm}
          minTransactionDate={minTransactionDate}
          onClose={onClose}
        />
      ) : null}
    </>
  );
};

export default ChangeNominalValueContainer;
