import { CSSProperties, FC, ReactNode } from "react";
import cn from "classnames";

import { scssVariables, SCSSVariablesType } from "common/utils/constants";

const sizes = ["xxl", "xl", "l", "m", "s", "xs"] as const;

type UiPropsTypes = {
  children?: ReactNode;
  className?: string;
  tag?: "div" | "span";
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
  color?: keyof typeof SCSSVariablesType | CSSProperties["color"];
} & Omit<JSX.IntrinsicElements["div"], "color">;
type Sizes = (typeof sizes)[number];
type UiGroup = Record<Sizes, FC<UiPropsTypes>>;

const uiGenerator = (size: Sizes) => {
  const Ui: FC<UiPropsTypes> = ({
    children,
    className,
    tag = "div",
    bold,
    style,
    italic,
    underline,
    color,
    ...props
  }) => {
    const currentColor = color
      ? scssVariables[color as unknown as SCSSVariablesType]
        ? scssVariables[color as unknown as SCSSVariablesType]
        : color
      : style?.color;

    switch (tag) {
      case "div":
        return (
          <div
            className={cn(`ui-${size}`, className, { bold: bold, italic: italic, underline: underline })}
            style={{ color: currentColor, ...style }}
            {...props}
          >
            {children}
          </div>
        );
      case "span":
        return (
          <span
            className={cn(`ui-${size}`, className, { bold: bold, italic: italic, underline: underline })}
            style={{ color: currentColor, ...style }}
            {...props}
          >
            {children}
          </span>
        );
    }
  };

  return Ui;
};

const Ui: UiGroup = sizes.reduce((acc, size) => {
  return {
    ...acc,
    [size]: uiGenerator(size),
  };
}, {} as UiGroup);

export default Ui;
