import { FC } from "react";

import { Tag, Ui } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { CalendarIcon, CommonFileIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { Transaction, TransactionStatus } from "pages/equity-management/ownership/transactions/types";
import { createTranslation, TranslationNS } from "translation";

import formatTransactionDate from "../../../format-transaction-date";

type PropsTypes = {
  transaction: Transaction;
};

const tTransactions = createTranslation(TranslationNS.pages, "company.transactions");
const t = createTranslation(TranslationNS.pages, "company.transactions.transactionItem");

const DeletionHeader: FC<PropsTypes> = ({ transaction }) => {
  const isPending = transaction.statusId === TransactionStatus.Pending;

  const fNumber = useFormatNumbers();
  return (
    <div>
      <div className="d-flex align-items-center mb-1">
        <Ui.m bold>{(transaction.fromName ? `${transaction.fromName} • ` : "") + t("deleteAllShares")}</Ui.m>
        <Ui.m bold className="mx-1">
          •
        </Ui.m>
        <Ui.m bold className="text-decoration-line-through">
          {`${fNumber(transaction.numberOfSharesBefore, "amount")} ${t("existingShares")}`}
        </Ui.m>
      </div>

      <div className="d-flex" style={{ color: scssVariables.foregroundLow }}>
        <Tag className="me-2" variant={isPending ? "closed" : "complete"}>
          <Ui.xs>{transaction.categoryName}</Ui.xs>
        </Tag>

        {isPending ? (
          <Tag variant="information" className="d-flex align-items-center me-2">
            <CalendarIcon height={16} width={16} color={scssVariables.foregroundLow} className="me-half" />
            <Ui.xs>{formatTransactionDate(transaction.transactedAt) + ` • ${tTransactions("pending")}`}</Ui.xs>
          </Tag>
        ) : (
          <div className="d-flex align-items-center me-2">
            <CalendarIcon height={16} width={16} color={scssVariables.foregroundLow} className="me-half" />
            <Ui.xs>{formatTransactionDate(transaction.transactedAt)}</Ui.xs>
          </div>
        )}

        {transaction.description && (
          <div className="d-flex align-items-center me-2">
            <CommonFileIcon height={16} width={16} color={scssVariables.foregroundLow} />
          </div>
        )}
      </div>
    </div>
  );
};

export default DeletionHeader;
