import { FC, useCallback, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useNavigate } from "react-router-dom";
import { useFormikContext } from "formik";

import { getEMPath } from "app/Router/RouterHelper";
import { Button } from "common/components/atoms";
import Helper from "common/components/atoms/Helper/Helper";
import Spinner from "common/components/atoms/Spinner/Spinner";
import { H, P, Ui } from "common/components/atoms/Typography";
import { useWizard } from "common/layout/WizardLayout/hooks";
import WizardContent from "common/layout/WizardLayout/WizardContent/WizardContent";
import IsParticipatingField from "common/shareClass/components/ShareClassForm/IsParticipatingField";
import ParticipatingCapField from "common/shareClass/components/ShareClassForm/ParticipatingCapField";
import SeniorityLevelField from "common/shareClass/components/ShareClassForm/SeniorityLevelField";
import SeniorityLevelMultipleField from "common/shareClass/components/ShareClassForm/SeniorityLevelMultipleField";
import { createTranslation, TranslationNS } from "translation";

import CreateShareClassStore from "../store";
import { PreferredPreferencesFormData } from "./PreferredPreferences";
import Sidebar from "./Sidebar";

const t = createTranslation(TranslationNS.pages, "createShareClass.preferredPreferences");

const PreferredPreferencesForm: FC<{
  setIsParticipating: (value: boolean) => void;
}> = ({ setIsParticipating }) => {
  const navigate = useNavigate();

  const { prevWizardStep } = useWizard();

  const updateFormDataAction = CreateShareClassStore.useStoreActions((actions) => actions.updateFormData);

  const { isSubmitting, values, setFieldValue, handleSubmit } = useFormikContext<PreferredPreferencesFormData>();

  const buttonBackClickHandler = useCallback((): void => {
    updateFormDataAction(values);

    prevWizardStep();

    navigate(getEMPath(["createShareClass", "main"]));
  }, [navigate, prevWizardStep, updateFormDataAction, values]);

  useEffect(() => {
    if (!values.isParticipating) {
      setFieldValue("participatingCap", null);
    }

    setIsParticipating(values.isParticipating);
  }, [values.isParticipating, setFieldValue, setIsParticipating]);

  return (
    <>
      <WizardContent.Content>
        <H.xs className="text-left mb-5">{t("title")}</H.xs>

        <Form onSubmit={handleSubmit}>
          <div className="mb-5">
            <Ui.l bold className="mb-1">
              {t("sections.seniorityLevel.title")}
            </Ui.l>
            <P.m className="mb-3">{t("sections.seniorityLevel.description")}</P.m>

            <Row>
              <Col md={6}>
                <SeniorityLevelField />
              </Col>
            </Row>
          </div>

          <div className="mb-5">
            <Ui.l bold className="mb-1">
              {t("sections.seniorityLevel.seniorityLevelMultiple.label")}
            </Ui.l>
            <P.m className="mb-3">{t("sections.seniorityLevel.seniorityLevelMultiple.description")}</P.m>

            <Row>
              <Col md={6}>
                <SeniorityLevelMultipleField />
              </Col>
            </Row>
          </div>

          <div className="mb-8">
            <Ui.l bold className="mb-1">
              {t("sections.participating.title")}
            </Ui.l>

            <P.m className="mb-3">{t("sections.participating.description")}</P.m>
            <Helper>
              <Row>
                <Col md={3}>
                  <Helper.Question questionId={"isParticipating"} type="switcher">
                    <IsParticipatingField />
                  </Helper.Question>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Helper.Answer
                    answerId={"isParticipating"}
                    text={t("isParticipatingAnswer")}
                    link="/"
                    linkText={t("isParticipatingReadMore")}
                  >
                    {t.el("isParticipatingAnswer.1", { components: [<p key={0} />] })}
                    {t.el("isParticipatingAnswer.2", { components: [<p key={0} />] })}
                    {t.el("isParticipatingAnswer.3", { components: [<p key={0} />] })}
                    {t.el("isParticipatingAnswer.4", { components: [<p key={0} />] })}
                    {t.el("isParticipatingAnswer.5", { components: [<p key={0} />] })}
                  </Helper.Answer>
                </Col>
              </Row>
              {values.isParticipating && (
                <>
                  <Row>
                    <Col className="mt-5" md={6}>
                      <Helper.Question questionId={"participatingCap"} type="input">
                        <ParticipatingCapField />
                      </Helper.Question>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Helper.Answer
                        className={"mt-3"}
                        answerId={"participatingCap"}
                        text={t("participatingCapAnswer")}
                        linkText={t("participatingCapReadMore")}
                        link="/"
                      />
                    </Col>
                  </Row>
                </>
              )}
            </Helper>
          </div>

          <div className="d-flex">
            <WizardContent.Controls.BackButton className="ms-auto" onClick={buttonBackClickHandler} />

            <Button className="ms-2" type="submit">
              {t("controls.createShareClass")}
            </Button>
          </div>
        </Form>
      </WizardContent.Content>

      <Sidebar />

      {isSubmitting ? <Spinner /> : null}
    </>
  );
};

export default PreferredPreferencesForm;
