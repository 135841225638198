import { FC, useCallback, useState } from "react";
import classNames from "classnames";

import { H, P } from "common/components/atoms/Typography";
import { StepWrapperProps } from "common/layout/WizardSinglePage/StepWrapper/StepWrapper";
import InfoCard from "common/shareClass/components/InfoCard/InfoCard";
import { scssVariables } from "common/utils/constants";
import { notify } from "common/utils/notify/notifyFunction";
import { ShareClassGetDTO } from "store/modelTypes";
import { createTranslation, TranslationNS } from "translation";

import {
  AttractInvestorsOnboardingDTO,
  EquityManagementOnboardingDTO,
  ManagerOnboarding,
  OnSubmit,
} from "../../../PostponedComponents/onboard-manager-wizard/types";
import classes from "../OnboardCompanyShareClasses.module.scss";
import AddShareClassCard from "./add-share-class-card/add-share-class-card";
import CreateShareClass from "./create-share-class/create-share-class/create-share-class";

type PropsTypes = {
  shareClasses: ShareClassGetDTO[];
  onSubmit: () => void;
  onAddShareClass: OnSubmit;
  companyId: number;
  stepRef?: StepWrapperProps["stepRef"];
  isDisabled?: boolean;
};

const t = createTranslation(TranslationNS.pages, "managerOnboarding.shareClassesStep");

const ShareClasses: FC<PropsTypes> = ({ shareClasses, onSubmit, onAddShareClass, companyId }) => {
  const [isCreateShareClassFormOpen, setIsCreateShareClassFormOpen] = useState(false);
  const [shareClassToEdit, setShareClassToEdit] = useState<ShareClassGetDTO | null>(null);

  const addShareClassHandler = useCallback(() => {
    setShareClassToEdit(null);
    setIsCreateShareClassFormOpen(true);
  }, []);

  const saveShareClassHandler = useCallback(
    (newValues: ManagerOnboarding | AttractInvestorsOnboardingDTO | EquityManagementOnboardingDTO) => {
      setIsCreateShareClassFormOpen(false);
      onAddShareClass(newValues);
    },
    [onAddShareClass]
  );

  const cancelShareClassHandler = useCallback(() => {
    setIsCreateShareClassFormOpen(false);
  }, []);

  const handleSubmit = useCallback(() => {
    onSubmit();
    notify(t("successNotification"), true, "success");
  }, [onSubmit]);

  const handleShareClassEdit = useCallback((data: ShareClassGetDTO) => {
    setShareClassToEdit(data);
    setIsCreateShareClassFormOpen(true);
  }, []);

  return (
    <div className="d-flex flex-column">
      <H.s className="mb-1">{t("title")}</H.s>
      <P.m className="mb-5 text-grey">{t("subtitle")}</P.m>
      <div className={classNames("d-flex flex-wrap gap-3 py-2 px-4 mb-5 border-3", classes["share-classes-container"])}>
        {shareClasses.map((shareClass) => (
          <InfoCard key={shareClass.id} shareClass={shareClass} onEdit={handleShareClassEdit} />
        ))}
        <AddShareClassCard handleClick={addShareClassHandler} />
        <CreateShareClass
          shareClass={shareClassToEdit}
          companyId={companyId}
          show={isCreateShareClassFormOpen}
          onSave={saveShareClassHandler}
          onCancel={cancelShareClassHandler}
        />
      </div>
      <div className={classes.info}>
        <div className="ui-s mb-1">
          <b>{t("paragraphTitle")}</b>
        </div>
        <P.s>{t("paragraph")}</P.s>
      </div>
    </div>
  );
};

export default ShareClasses;
