import { FC } from "react";

import SingleAgreementDetailSection from "../../../common/details/SingleAgreementDetailSection";
import useRSAAgreementDetails, { RSAPlanDetails } from "../useRSAAgreementDetails";
import classes from "./PlanDetailsContent.module.scss";
type PlanDetailsContentProps = {
  planDetails: RSAPlanDetails | null;
};

const PlanDetailsContent: FC<PlanDetailsContentProps> = ({ planDetails }) => {
  const { agreementItems } = useRSAAgreementDetails(planDetails);
  return (
    <div className={classes["content"]}>
      {agreementItems.map((item, index) => (
        <SingleAgreementDetailSection
          key={index}
          title={item.title}
          subTitle={item.description}
          infoContent={item.content}
        />
      ))}
    </div>
  );
};

export default PlanDetailsContent;
