import React, { FC, ReactNode } from "react";
import classNames from "classnames";

import { LinkHelpText, Ui } from "common/components/atoms";
import { QuestionCircleIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { createTranslation, TranslationNS } from "translation";

import classes from "../styles.module.scss";

const t = createTranslation(TranslationNS.common, "organisms.agreement.agreementDetails");

const SingleAgreementDetailSection: FC<{
  title?: string | ReactNode | JSX.Element;
  subTitle: string;
  infoContent: string;
}> = ({ title, subTitle, infoContent }) => {
  return (
    <div className={classNames(classes["single"], "text-truncate px-2 m-0")}>
      <div className=" d-block">
        <Ui.s className="text-truncate fw-500">
          {title}
        </Ui.s>

        <Ui.xs style={{ color: scssVariables.foregroundLow }}>{subTitle}</Ui.xs>
      </div>

      <LinkHelpText title={t("infoWidget.title", { infoTitle: subTitle })} content={infoContent}>
        <QuestionCircleIcon />
      </LinkHelpText>
    </div>
  );
};

export default SingleAgreementDetailSection;
