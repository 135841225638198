import { FC, memo } from "react";
import { ProgressBar } from "react-bootstrap";
import classNames from "classnames";

import { Ui } from "common/components/atoms/Typography";
import { scssVariables } from "common/utils/constants";
import { createTranslation, TranslationNS } from "translation";

import classes from "./poolStatus.module.scss";

type PoolStatusProps = {
  numberOfSharesLeft: number;
  numberOfShares: number;
};

const t = createTranslation(TranslationNS.pages, "company.grantOneOffRSAPlan.sharesDetails");
const PoolStatus: FC<PoolStatusProps> = ({ numberOfSharesLeft, numberOfShares }) => {
  if (numberOfSharesLeft === -1) {
    return null;
  }
  const percent = +(numberOfSharesLeft <= 0 ? 100 : (numberOfShares * 100) / numberOfSharesLeft).toFixed(2);
  return (
    <div className={classNames(classes["chart"], "ms-4", "pb-1")}>
      <div className="me-2">
        <Ui.xs bold style={{ color: scssVariables.foregroundMedium }}>
          {t("poolStatus")}
        </Ui.xs>

        <Ui.xs style={{ color: scssVariables.foregroundMedium }}>
          {t.el("percentageOfTotal", {
            components: [<strong key={1} />],
            values: {
              percentage: percent,
            },
          })}
        </Ui.xs>
      </div>

      <ProgressBar max={100} now={percent} className={classes["progress"]} />
    </div>
  );
};

export default memo(PoolStatus);
