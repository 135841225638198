import { FC, useCallback, useRef } from "react";
import classNames from "classnames";
import { and, isEmpty, isNil } from "ramda";

import { CloseIcon, SearchIcon } from "common/icons/svg";

import TextField, { TextFieldProps } from "../TextField/TextField";
import classes from "./SearchField.module.scss";

const SearchField: FC<TextFieldProps & { wrapperClassname?: string; customClear?: () => void }> = ({
  className,
  value,
  wrapperClassname,
  customClear,
  ...props
}) => {
  const ref = useRef<HTMLInputElement>(null);

  const clearInput = useCallback(() => {
    if (customClear) {
      customClear();
      return;
    }

    // @ts-ignore
    props?.onChange?.({ target: { value: "" } });
  }, [customClear, props]);

  return (
    <div className={classNames("position-relative d-flex align-items-center", wrapperClassname)}>
      <TextField
        ref={ref}
        value={value}
        iconLeft={<SearchIcon />}
        className={classNames(classes["search-input"], className)}
        {...props}
      />

      {and(!isEmpty(value), !isNil(value)) ? (
        <CloseIcon className={classes["input-remove-icon"]} onClick={clearInput} />
      ) : null}
    </div>
  );
};

export default SearchField;
