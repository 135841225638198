export type Stakeholder = {
  stakeholderId: number;
  firstName: string;
  lastName: string;
  email: string;
  initials: string;
  relationship: string;
  companyName?: string;
  avatarFilePath?: string;
  isActiveUser?: boolean;
  hasSentInvite?: boolean;
  canDelete?: boolean;
};

export const StakeholderInvitationStatus = {
  notInvited: 0,
  invited: 1,
  active: 2,
} as const;

export type StakeholderInvitationStatusIds =
  (typeof StakeholderInvitationStatus)[keyof typeof StakeholderInvitationStatus];

export type GetStakeholderDTO = Omit<Stakeholder, "relationship" | "initials" | "avatarFilePath"> & {
  dateOfBirth?: string;
  address: string;
  phoneNumber: string;
  countryId?: number;
  organizationNumber: string;
  businessPostAddress?: string;
  relationshipTypeId: number;
};
export type GetStakeholdersDTO = Stakeholder[];

export type PostStakeholderDTO = Omit<GetStakeholderDTO, "stakeholderId"> & { companyId: number };
export type EditStakeholderDTO = PostStakeholderDTO & { stakeholderId: number };
