import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ColumnDef } from "@tanstack/react-table";

import {
  Actions,
  CheckboxControllerInput,
  CheckboxInput,
  DateInput,
  SelectInput,
  TextInput,
} from "common/components/atoms/ImportTable";
import Ui from "common/components/atoms/Typography/Ui";
import useCurrencyById from "common/hooks/useCurrencyById";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { ShareClassGetDTO } from "store/modelTypes";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { LegalEntityEnum } from "../components/importOwnership.types";
import classes from "./ImportOwnership.module.scss";
import { fields, OwnershipFormValues } from "./useImportOwnershipForm";

const t = createTranslation(TranslationNS.pages, "onboard.company.importOwnership");

const useImportOwnershipColumns = (shareClasses: ShareClassGetDTO[], withTransaction = false, currencyId?: number) => {
  const fNumber = useFormatNumbers();
  const { i18n } = useTranslation();

  const countries = useStoreState((state) => state.common.dropdowns)?.countries;
  const relationshipTypes = useStoreState((state) => state.common.dropdowns)?.relationshipTypes;
  const [countOfActiveCheckboxes, setCountOfActiveCheckboxes] = useState(0);
  const { currencySymbol } = useCurrencyById(currencyId);

  const countriesWithEmpty = useMemo(() => {
    const notSelected = {
      id: 0,
      name: t("notSelected"),
    };
    return countries ? countries : [notSelected];
  }, [countries]);

  const shareClassesWithEmpty = useMemo(() => {
    const notSelected = {
      id: "",
      name: t("notSelected"),
    };
    return shareClasses ? shareClasses.map((item) => ({ id: item.name, name: item.name })) : [notSelected];
  }, [shareClasses]);

  const legalEntity = useMemo(() => {
    return [
      {
        id: LegalEntityEnum.PERSON,
        name: t("personally"),
      },
      {
        id: LegalEntityEnum.COMPANY,
        name: t("company"),
      },
    ];
  }, []);

  const columns = useMemo<ColumnDef<OwnershipFormValues>[]>(() => {
    const notAllowDeleteComponent = (
      <div style={{ maxWidth: 130 }} className="text-start">
        <Ui.xs bold>Cannot delete</Ui.xs>
        <Ui.xs>Stakeholder is part of a transaction.</Ui.xs>
      </div>
    );

    const cells: ColumnDef<OwnershipFormValues>[] = [
      {
        header: (props) => {
          return (
            <CheckboxControllerInput
              canDoActionField={fields.canDeleteStakeholder}
              columnId={props.column.id}
              allActionsCallback={setCountOfActiveCheckboxes}
            />
          );
        },
        accessorKey: "checkbox",
        cell: (props) => (
          <CheckboxInput
            canDoActionField={fields.canDeleteStakeholder}
            rowIndex={props.row.index}
            columnId={props.column.id}
            popupContent={notAllowDeleteComponent}
          />
        ),
        maxSize: 38,
      },
      {
        header: t("fields.ownershipType") + " *",
        accessorKey: fields.isCompanyOwned,
        cell: (props) => (
          <SelectInput
            placeholder={t("notSelected")}
            rowIndex={props.row.index}
            columnId={props.column.id}
            options={legalEntity}
          />
        ),
        minSize: 140,
        footer: (props) => {
          const totalRows = props.table.getFilteredRowModel().rows.length;
          return (
            <span>{`${totalRows} ${
              withTransaction
                ? totalRows === 1
                  ? t("stakeholder")
                  : t("stakeholders")
                : totalRows === 1
                ? t("shareholder")
                : t("shareholders")
            }`}</span>
          );
        },
      },
      {
        header: t("fields.companyName") + " *",
        accessorKey: fields.companyName,
        cell: (props) => (
          <TextInput
            disabledWhen={LegalEntityEnum.PERSON}
            nameWhen={fields.isCompanyOwned}
            rowIndex={props.row.index}
            columnId={props.column.id}
            placeholder={t("fields.companyName")}
          />
        ),
        minSize: withTransaction ? 150 : 110,
      },
      {
        header: t("fields.organizationNumber"),
        accessorKey: fields.organizationNumber,
        cell: (props) => (
          <TextInput
            disabledWhen={LegalEntityEnum.PERSON}
            nameWhen={fields.isCompanyOwned}
            rowIndex={props.row.index}
            columnId={props.column.id}
            placeholder={t("fields.organizationNumber")}
          />
        ),
        minSize: withTransaction ? 150 : 110,
      },
      {
        header: t("fields.firstName") + " *",
        accessorKey: fields.firstName,
        cell: (props) => (
          <TextInput rowIndex={props.row.index} columnId={props.column.id} placeholder={t("fields.firstNameLabel")} />
        ),
        minSize: withTransaction ? 150 : 130,
      },
      {
        header: t("fields.lastName") + " *",
        accessorKey: fields.lastName,
        cell: (props) => (
          <TextInput rowIndex={props.row.index} columnId={props.column.id} placeholder={t("fields.lastNameLabel")} />
        ),
        minSize: withTransaction ? 160 : 130,
      },
      {
        header: t("fields.email"),
        accessorKey: fields.email,
        cell: (props) => (
          <TextInput rowIndex={props.row.index} columnId={props.column.id} placeholder={t("fields.emailLabel")} />
        ),
        minSize: withTransaction ? 160 : 130,
      },
      {
        header: t("fields.dateOfOwnership") + " *",
        accessorKey: fields.dateOfOwnership,
        cell: (props) => <DateInput rowIndex={props.row.index} columnId={props.column.id} placeholder="dd.mm.yyyy" />,
        minSize: 168,
      },
      {
        header: t("fields.shares") + " *",
        accessorKey: fields.numberOfShares,
        cell: (props) => (
          <TextInput
            rowIndex={props.row.index}
            columnId={props.column.id}
            className={classes.shareClassAlign}
            type="number"
            placeholder={t("fields.shares")}
          />
        ),
        footer: ({ table }) => {
          const total = table
            .getFilteredRowModel()
            .rows.reduce((sum, row) => +sum + +(row.original.numberOfShares || 0), 0);
          return (
            <div className={"text-end"}>
              <span>{withTransaction ? "" : fNumber(total, "amount")}</span>
            </div>
          );
        },
        minSize: 80,
        meta: {
          headClass: "text-end",
        },
      },
      {
        header: t("fields.shareClass") + " *",
        accessorKey: fields.shareClassName,
        cell: (props) => (
          <SelectInput
            rowIndex={props.row.index}
            columnId={props.column.id}
            options={shareClassesWithEmpty}
            isSearchable
            placeholder={t("notSelected")}
          />
        ),
        minSize: 140,
      },
      {
        header: t("fields.purchasePrice"),
        accessorKey: fields.averageSharePrice,
        cell: (props) => (
          <TextInput
            rowIndex={props.row.index}
            columnId={props.column.id}
            className={classes.shareClassAlign}
            type="number"
            placeholder={t("fields.purchasePrice")}
            iconRight={currencySymbol}
          />
        ),
        size: 120,
        minSize: 120,
        meta: {
          headClass: "text-end",
        },
      },
      {
        header: t("fields.address"),
        accessorKey: fields.address,
        cell: (props) => (
          <TextInput rowIndex={props.row.index} columnId={props.column.id} placeholder={t("fields.address")} />
        ),
        minSize: withTransaction ? 216 : 110,
      },
      {
        header: t("fields.country"),
        accessorKey: fields.countryId,
        cell: (props) => (
          <SelectInput
            isSearchable
            rowIndex={props.row.index}
            columnId={props.column.id}
            options={countriesWithEmpty}
            placeholder={t("notSelected")}
            isClearable
          />
        ),
        minSize: 140,
      },
      {
        header: t("fields.relation") + " *",
        accessorKey: fields.relationshipTypeId,
        cell: (props) => (
          <SelectInput rowIndex={props.row.index} columnId={props.column.id} options={relationshipTypes || []} />
        ),
        minSize: 130,
      },
      {
        header: t("fields.phoneNumber"),
        accessorKey: fields.phoneNumber,
        cell: (props) => (
          <TextInput rowIndex={props.row.index} columnId={props.column.id} placeholder={t("fields.phoneNumber")} />
        ),
        minSize: withTransaction ? 216 : 110,
      },
      {
        accessorKey: "actions",
        header: "",
        cell: ({ row, table }) => (
          <Actions
            rowIndex={row.index}
            updateData={table.options.meta?.updateData}
            globalFilter={table.getState().globalFilter}
            canDoActionField={fields.canDeleteStakeholder}
            popupContent={notAllowDeleteComponent}
          />
        ),
        maxSize: 42,
      },
    ];

    if (withTransaction) {
      return cells.filter(
        (cell: any) =>
          cell.accessorKey !== fields.numberOfShares &&
          cell.accessorKey !== fields.dateOfOwnership &&
          cell.accessorKey !== fields.shareClassName &&
          cell.accessorKey !== fields.averageSharePrice
      );
    }
    return cells;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    i18n.language,
    countriesWithEmpty,
    currencySymbol,
    legalEntity,
    relationshipTypes,
    shareClassesWithEmpty,
    withTransaction,
  ]);

  return { columns, countOfActiveCheckboxes };
};

export default useImportOwnershipColumns;
