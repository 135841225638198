import { Action, ActionOn, Thunk } from "easy-peasy";

import { ShareholderGetDTO } from "store/modelTypes";

import { StakeholderInvitationStatusIds } from "../stakeholders-management/types";

export type CapTable = {
  shareholderName: string;
  representedBy: string | null;
  initials: string;
  shares: number;
  ownershipPercentage: number;
  totalValue: number;
  shareClasses: string[];
  relationship: string;
  stakeholderId: number;
  isCompanyOwned: boolean;
  hasPledges: boolean;
  avatarFilePath?: string;
  invitationStatus: StakeholderInvitationStatusIds;
};

export type FullyDilutedCapTable = {
  stakeholderName: string;
  shareholderName?: string;
  representedBy: string | null;
  initials: string;
  shares: number;
  ownershipPercentage: number;
  shareClasses: string[];
  instrument: string;
  stakeholderId: number;
  sharesFullyDiluted: number;
  ownershipPercentageFullyDiluted: number;
  differenceFullyDiluted: number;
  relationship: string;
  isCompanyOwned: boolean;
  hasPledges: boolean;
  avatarFilePath?: string;
  invitationStatus: StakeholderInvitationStatusIds;
};

type CapTableSummary = {
  numberOfShares: number;
  valuation: number;
  percentage: number;
  sharesFullyDiluted?: number;
  ownershipFullyDiluted?: number;
  differenceFullyDiluted?: number;
};

type RelationshipOverview = {
  id: number;
  name: string;
  percentage: number;
};

type ShareClassDistribution = {
  shareClassId: number;
  shareClassName: string;
  percentage: number;
};

type TopStakeholders = {
  stakeholderId: number;
  name: string;
  percentage: number;
  numberOfShares: number;
};

export type CapTableServiceGetResponse = {
  capTable: CapTable[];
  capTableByShareholder: CapTable[];
  capTableSummary: CapTableSummary;
  relationshipOverview: RelationshipOverview[];
  shareClassDistribution: ShareClassDistribution[];
  topStakeholders: TopStakeholders[];
  isCapTableLive: boolean;
  hasCompanyInvitation: boolean;
  showAddShareholderBanner: boolean;
};

export type DilutedCapTableServiceGetResponse = {
  capTable: FullyDilutedCapTable[];
  capTableByShareHolder: FullyDilutedCapTable[];
  capTableSummary: CapTableSummary;
  defaultSimulatedValuation?: number;
  relationshipOverview: RelationshipOverview[];
  shareClassDistribution: ShareClassDistribution[];
  topStakeholders: TopStakeholders[];
  issuedUnissuedDistribution: IssuedUnissuedDistribution[];
  isCapTableLive: boolean;
  showAddShareholderBanner: boolean;
};

export type IssuedUnissuedDistribution = {
  name: string;
  shares: number;
};

export type CapTableIsLivePostRequest = {
  companyId: number;
};

export interface CapTableStoreModel {
  searchValue: string;
  setSearchValue: Action<this, this["searchValue"]>;

  isLoading: boolean;
  isValuationLoading: boolean;
  setIsValuationLoading: Action<this, boolean>;
  shareholderId: number | undefined;
  setIsLoading: Action<this, boolean>;

  finishSetupMode: boolean;
  setFinishSetupMode: Action<this, this["finishSetupMode"]>;

  actualData: CapTableServiceGetResponse;
  setActualData: Action<this, this["actualData"]>;
  getActualDataThunk: Thunk<this, number>;
  isCaptableLiveThunk: Thunk<this, number>;

  dilutedData: DilutedCapTableServiceGetResponse;
  setDilutedData: Action<this, this["dilutedData"]>;
  getDilutedDataThunk: Thunk<this, { companyId: number; valuation?: number }>;

  isPledgedModalOpen: boolean;
  setIsPledgedModalOpen: Action<this, this["isPledgedModalOpen"]>;

  offCanvasOpen: boolean;
  setOffCanvasOpen: Action<this, { open: boolean; shareholderId?: number }>;
  shareholder: null | ShareholderGetDTO;
  setShareholder: Action<this, ShareholderGetDTO | null>;

  removeOffCanvasOpen: boolean;
  removeShareholder: CapTableServiceGetResponse["capTable"][number] | null;
  setRemoveOffCanvasOpen: Action<
    this,
    {
      open: boolean;
      data?: CapTableServiceGetResponse["capTable"][number] | null;
    }
  >;

  selectedActualGroup: CapTableActualGroupedValues;
  setSelectedActualGroup: Action<this, this["selectedActualGroup"]>;
  selectedActualGroupChangeHandler: ActionOn<this>;

  selectedDilutedGroup: CapTableDilutedGroupedValues;
  setSelectedDilutedGroup: Action<this, this["selectedDilutedGroup"]>;
  selectedDilutedGroupChangeHandler: ActionOn<this>;

  selectedRowData?:
    | CapTableServiceGetResponse["capTable"][number]
    | DilutedCapTableServiceGetResponse["capTable"][number];
  setSelectedRowData: Action<this, this["selectedRowData"]>;

  setShowAddShareholderBanner: Action<this, boolean>;
}

export const capTableActualGroupedValues = {
  default: "default",
  shareholder: "shareholder",
  shareClass: "shareClass",
  relationship: "relationship",
} as const;

export const capTableDilutedGroupedValues = {
  default: "default",
  stakeholder: "stakeholder",
  shareholder: "shareholder",
  shareClass: "shareClass",
  relationship: "relationship",
  instrument: "instrument",
} as const;

export type CapTableActualGroupedValues = keyof typeof capTableActualGroupedValues;

export type CapTableDilutedGroupedValues = keyof typeof capTableDilutedGroupedValues;

export type SingleShareClassData = {
  ownership: number;
  shareClassId: number;
  shareClassName: string;
  shares: number;
  shareNumbers: string[];
};
export type CapTableStakeholderDetailsType = {
  address?: string;
  avatarFilePath?: string;
  businessPostAddress: string | null;
  companyName: string | null;
  countryId: number;
  email?: string;
  firstName: string;
  hasPledges: boolean;
  initials: string;
  isCompanyOwned: boolean;
  lastName: string;
  organizationNumber: string | null;
  phoneNumber?: string;
  pledgesDescription: string | null;
  relationshipTypeId: number;
  stakeholderId: number;
  dateOfBirth?: string;
  invitationStatus: StakeholderInvitationStatusIds;
  shareClasses: SingleShareClassData[];
  totalSharesValue: number;
  sharesTotalPercentage: number;
  financialInstruments: {
    typeId: number;
    amount: number;
    expiryDate: string | null;
    financialInstrumentId: number;
    instrumentName: string;
    numberOfShares: number;
    stake: number;
  }[];
  transactions: {
    categoryId: number;
    categoryName: string;
    currencySymbol: string;
    description: string | null;
    numberOfShares: number;
    sharePrice: number;
    stakeholderAvatar: string | null;
    stakeholderInitials: string;
    stakeholderIsBuying: boolean;
    stakeholderIsCompanyOwned: boolean;
    stakeholderName: string;
    stakeholderRepresentativeName: string;
    statusId: number;
    transactedAt: string;
    transactedAvatar: string | null;
    transactedInitials: string;
    transactedIsCompanyOwned: boolean;
    transactedName: string;
    transactedRepresentativeName: string;
    transactionId: number;
    transactionTotal: number;
    transactionTypeId: number;
    typeName: string;
  }[];
};
