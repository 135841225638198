import { FC, useCallback, useState } from "react";
import { motion } from "framer-motion";

import { Button } from "common/components/atoms";
import { ChevronDownIcon, ChevronUpIcon } from "common/icons/svg";
import { createTranslation, TranslationNS } from "translation";

import { StakeholderField } from "../../Stakeholder.types";

const t = createTranslation(TranslationNS.common, "molecules.stakeholder");

export const animationStakeholder = {
  open: {
    display: "block",
    opacity: 1,
    height: "auto",
    transition: {
      height: { duration: 0.5 },
      opacity: { duration: 0.5 },
    },
    transitionEnd: {
      overflow: "auto",
    },
  },
  close: {
    opacity: 0,
    height: 0,
    overflow: "hidden",
    transition: {
      height: { duration: 0.5 },
      opacity: { duration: 0.5 },
    },
    transitionEnd: {
      display: "none",
    },
  },
};

type RenderStakeholderFieldsProps = {
  topFields: StakeholderField[];
  otherFields: StakeholderField[];
  isDisabled?: boolean;
};
const RenderStakeholderFields: FC<RenderStakeholderFieldsProps> = ({ topFields, otherFields, isDisabled }) => {
  const [showAdditionFields, setShowAdditionFields] = useState(false);

  const handleShow = useCallback(() => {
    setShowAdditionFields((prevState) => !prevState);
  }, []);

  return (
    <div>
      <div>
        {topFields.map(({ key, Component, meta }) => (
          <Component key={key} className="mb-4" {...meta} isDisabled={isDisabled} />
        ))}
      </div>
      {!!otherFields.length && (
        <div>
          <motion.div initial="close" variants={animationStakeholder} animate={showAdditionFields ? "open" : "close"}>
            {otherFields.map(({ key, Component, meta }) => (
              <Component key={key} className="mb-4" {...meta} isDisabled={isDisabled} />
            ))}
          </motion.div>
          <Button
            variant="secondary"
            size="s"
            onClick={handleShow}
            isDisabled={isDisabled}
            iconRight={!showAdditionFields ? <ChevronDownIcon /> : <ChevronUpIcon />}
          >
            {!showAdditionFields ? t("showAdditionalFields") : t("hideAdditionalFields")}
          </Button>
        </div>
      )}
    </div>
  );
};

export default RenderStakeholderFields;
