import { FC, Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import * as R from "ramda";

import { Ui } from "common/components/atoms";
import { ShareClassesIcon, UsersIcon } from "common/icons/svg";
import { sortCaptableData } from "common/utils/functions";

import { capTableActualGroupedValues, CapTableServiceGetResponse } from "../../CapTable.types";
import CapTableStore from "../../store";
import Row from "./Row";
import { ActualCapTableSortValue } from "./Table";
import classes from "./Table.module.scss";

type PropsTypes = {
  sortedValue: ActualCapTableSortValue;
};

const Body: FC<PropsTypes> = ({ sortedValue }) => {
  const [classesState, setClassesState] = useState<Record<string, boolean>>({});
  const [relationshipsState, setRelationshipsState] = useState<Record<string, boolean>>({});

  const capTableDataStore = CapTableStore.useStoreState((state) => state.actualData.capTable);
  const capTableByShareholder = CapTableStore.useStoreState((state) => state.actualData.capTableByShareholder);
  const searchValue = CapTableStore.useStoreState((state) => state.searchValue);
  const selectedGroupStore = CapTableStore.useStoreState((state) => state.selectedActualGroup);

  const filteredData = useMemo(() => {
    if (R.isEmpty(searchValue)) {
      return capTableDataStore;
    }

    return capTableDataStore.filter(
      (el) =>
        el.shareholderName.toLowerCase().includes(searchValue.toLowerCase()) ||
        el?.representedBy?.toLowerCase().includes(searchValue.toLowerCase()) ||
        el.shareClasses.filter((item) => item.toLowerCase().includes(searchValue.toLowerCase())).length > 0
    );
  }, [capTableDataStore, searchValue]);

  const filteredStakeholderData = useMemo(() => {
    if (R.isEmpty(searchValue)) {
      return capTableByShareholder;
    }

    return capTableByShareholder.filter(
      (el) =>
        el.shareholderName.toLowerCase().includes(searchValue.toLowerCase()) ||
        el?.representedBy?.toLowerCase().includes(searchValue.toLowerCase()) ||
        el.shareClasses.filter((item) => item.toLowerCase().includes(searchValue.toLowerCase())).length > 0
    );
  }, [capTableByShareholder, searchValue]);

  const sortedData = useMemo(() => {
    return sortCaptableData({
      data: filteredData,
      sortedField: sortedValue.field,
      sortType: sortedValue.type,
    });
  }, [sortedValue, filteredData]);

  const sortedShareholdersData = useMemo(() => {
    return sortCaptableData({
      data: filteredStakeholderData,
      sortedField: sortedValue.field,
      sortType: sortedValue.type,
    });
  }, [sortedValue, filteredStakeholderData]);

  const isDefaultValueSelected = useMemo<boolean>(
    () => selectedGroupStore === capTableActualGroupedValues.default,
    [selectedGroupStore]
  );

  const isShareholderValueSelected = useMemo<boolean>(
    () => selectedGroupStore === capTableActualGroupedValues.shareholder,
    [selectedGroupStore]
  );

  const isRelationshipValueSelected = useMemo<boolean>(
    () => selectedGroupStore === capTableActualGroupedValues.relationship,
    [selectedGroupStore]
  );
  const isClassesValueSelected = useMemo<boolean>(
    () => selectedGroupStore === capTableActualGroupedValues.shareClass,
    [selectedGroupStore]
  );

  const allRelationships = useMemo(
    () =>
      filteredData.reduce((prev, { relationship: currRelationship }) => {
        prev[currRelationship] = sortCaptableData({
          data: filteredData.filter(({ relationship }) => relationship === currRelationship),
          sortedField: sortedValue.field,
          sortType: sortedValue.type,
        });

        return prev;
      }, {} as { [relationship: string]: CapTableServiceGetResponse["capTable"] }),
    [filteredData, sortedValue]
  );

  const allClasses = useMemo(
    () =>
      R.uniq(R.flatten(R.pluck("shareClasses", filteredData))).reduce((acc, curr) => {
        acc[curr] = sortCaptableData({
          data: filteredData.filter((item) => item.shareClasses.includes(curr)),
          sortedField: sortedValue.field,
          sortType: sortedValue.type,
        });

        return acc;
      }, {} as { [className: string]: CapTableServiceGetResponse["capTable"] }),
    [filteredData, sortedValue]
  );

  const renderDefaultTable = useCallback(() => {
    return (
      <AnimatePresence>
        {sortedData.map((item) => (
          <Row key={`${item.stakeholderId} ${item.shareClasses.join("")}`} data={item} />
        ))}
      </AnimatePresence>
    );
  }, [sortedData]);

  const renderShareholdersTable = useCallback(
    () => (
      <AnimatePresence>
        {sortedShareholdersData.map((item) => (
          <Row key={`${item.stakeholderId} ${item.shareClasses.join("")}`} data={item} />
        ))}
      </AnimatePresence>
    ),
    [sortedShareholdersData]
  );
  const renderRelationshipTable = useCallback(
    () =>
      Object.keys(allRelationships).map((el, index) => (
        <Fragment key={index}>
          <AnimatePresence>
            <motion.tr
              className={classes.tableBorder}
              key="relationship-key"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              onClick={() => {
                setRelationshipsState((state) => ({
                  ...state,
                  [el]: !state[el],
                }));
              }}
            >
              <td colSpan={8}>
                <div className="d-flex align-items-center">
                  <UsersIcon />
                  <Ui.s className="ms-2 fw-500">{el}</Ui.s>
                </div>
              </td>
            </motion.tr>

            <AnimatePresence>
              {relationshipsState[el] &&
                allRelationships[el].map((item) => (
                  <Row key={`${item.stakeholderId} ${item.shareClasses.join("")}`} data={item} />
                ))}
            </AnimatePresence>
          </AnimatePresence>
        </Fragment>
      )),
    [allRelationships, relationshipsState]
  );

  const renderClassesTable = useCallback(
    () =>
      Object.keys(allClasses).map((el, index) => (
        <Fragment key={index}>
          <AnimatePresence>
            <motion.tr
              className={classes.tableBorder}
              key="classes-key"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              onClick={() => {
                setClassesState((state) => ({ ...state, [el]: !state[el] }));
              }}
            >
              <td colSpan={8}>
                <div className="d-flex align-items-center">
                  <ShareClassesIcon />
                  <Ui.s className="ms-2 fw-500">{el}</Ui.s>
                </div>
              </td>
            </motion.tr>

            <AnimatePresence>
              {classesState[el] &&
                allClasses[el].map((item) => (
                  <Row key={`${item.stakeholderId} ${item.shareClasses.join("")}`} data={item} />
                ))}
            </AnimatePresence>
          </AnimatePresence>
        </Fragment>
      )),
    [allClasses, classesState]
  );

  useEffect(() => {
    setRelationshipsState(
      Object.keys(allRelationships).reduce((acc, el) => {
        acc[el] = true;

        return acc;
      }, {} as { [key: string]: boolean })
    );

    setClassesState(
      Object.keys(allClasses).reduce((acc, el) => {
        acc[el] = true;

        return acc;
      }, {} as { [key: string]: boolean })
    );
  }, [allClasses, allRelationships]);

  return (
    <tbody>
      {isDefaultValueSelected && renderDefaultTable()}
      {isShareholderValueSelected && renderShareholdersTable()}
      {isRelationshipValueSelected && renderRelationshipTable()}
      {isClassesValueSelected && renderClassesTable()}
    </tbody>
  );
};

export default Body;
