import { FC, useCallback } from "react";
import { useParams } from "react-router-dom";

import { notify } from "common/utils/notify/notifyFunction";
import { createTranslation, TranslationNS } from "translation";

import { useTransactionsService } from "../../../transactions-service";
import { IssueSharesGetResponseDTO, TransactionStatus } from "../../../types";
import CreateIssueSharesForm from "./create-issue-shares-form";
import EditIssueSharesForm from "./edit-issue-shares-form";
import { EditIssueSharesFields } from "./form-fields";

type PropsTypes = {
  isIssueSharesFormOpen: boolean;
  issueSharesTransactionToEdit: IssueSharesGetResponseDTO | null;
  onSubmit: (transactionId?: string, keepFormOpen?: boolean) => void;
  onClose: () => void;
  isDisabled?: boolean;
};

const t = createTranslation(TranslationNS.pages, "company.transactions");

const IssueSharesContainer: FC<PropsTypes> = ({
  isIssueSharesFormOpen,
  issueSharesTransactionToEdit,
  onSubmit,
  onClose,
  isDisabled,
}) => {
  const { companyId = "0" } = useParams<{ companyId: string }>();
  const { postIssueSharesTransaction, editPendingIssueSharesTransaction, editConfirmedIssueSharesTransaction } =
    useTransactionsService(companyId);

  const handleSubmitCreateIssueSharesForm = useCallback(
    async (transaction: FormData, restartForm?: boolean) => {
      const transactionId = await postIssueSharesTransaction(transaction);

      if (transactionId) {
        notify(t("successTransactionCreation"), true, "success", 5000, false, "top-center");

        onSubmit(String(transactionId), restartForm);
        return true;
      } else {
        return false;
      }
    },
    [onSubmit, postIssueSharesTransaction]
  );

  const handleSubmitEditIssueSharesForm = useCallback(
    async (transaction: FormData) => {
      const editedSuccessfully =
        issueSharesTransactionToEdit?.statusId === TransactionStatus.Confirmed
          ? await editConfirmedIssueSharesTransaction(transaction)
          : await editPendingIssueSharesTransaction(transaction);

      if (editedSuccessfully) {
        notify(t("successTransactionEditing"), true, "success", 5000, false, "top-center");
        if (issueSharesTransactionToEdit?.transactionBundleId) {
          onSubmit(String(issueSharesTransactionToEdit.transactionBundleId));
        }
        onSubmit(String(transaction.get(EditIssueSharesFields.transactionId)) || undefined);
        return true;
      } else {
        return false;
      }
    },
    [
      editConfirmedIssueSharesTransaction,
      editPendingIssueSharesTransaction,
      issueSharesTransactionToEdit?.statusId,
      issueSharesTransactionToEdit?.transactionBundleId,
      onSubmit,
    ]
  );

  return (
    <>
      {isIssueSharesFormOpen && (
        <CreateIssueSharesForm onSubmit={handleSubmitCreateIssueSharesForm} onClose={onClose} isDisabled={isDisabled} />
      )}

      {issueSharesTransactionToEdit && (
        <EditIssueSharesForm
          onSubmit={handleSubmitEditIssueSharesForm}
          onClose={onClose}
          transactionToEdit={issueSharesTransactionToEdit}
        />
      )}
    </>
  );
};

export default IssueSharesContainer;
