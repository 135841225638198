import { FC, memo, useCallback, useMemo } from "react";
import cn from "classnames";
import { motion } from "framer-motion";
import { equals } from "ramda";

import { LinkHelpText, NewAvatar, Tag, Tooltip, Ui } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { ActiveUserBudgeIcon, ExclamationMarkCircle, FilledArrowDownIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { createTranslation, TranslationNS } from "translation";

import { StakeholderInvitationStatus } from "../../../stakeholders-management/types";
import { DilutedCapTableServiceGetResponse } from "../../CapTable.types";
import ShareholderDetailsModal from "../../common/ShareholderDetailsModal/ShareholderDetailsModal";
import CapTableStore from "../../store";
import classes from "./Table.module.scss";

const t = createTranslation(TranslationNS.pages, "company.capTable.table.common");

export type TableRowProps = {
  data: DilutedCapTableServiceGetResponse["capTable"][number];
};

const Row: FC<TableRowProps> = ({ data }) => {
  const { selectedRowData } = CapTableStore.useStoreState((state) => state);
  const { setSelectedRowData } = CapTableStore.useStoreActions((actions) => actions);
  const fNumber = useFormatNumbers();

  const isRowSelected = useMemo<boolean>(() => equals(data, selectedRowData), [data, selectedRowData]);

  const selectRow = useCallback((): void => {
    setSelectedRowData(isRowSelected ? undefined : data);
  }, [data, isRowSelected, setSelectedRowData]);

  const handleClose = useCallback(() => {
    setSelectedRowData(undefined);
  }, [setSelectedRowData]);

  return (
    <>
      <motion.tr
        className={cn(classes.tableBorder, {
          selected: isRowSelected,
          [classes.selected]: isRowSelected,
          [classes.company]: isRowSelected && data.isCompanyOwned,
          [classes.active]: !isRowSelected,
        })}
        initial={{
          opacity: 0,
          x: -20,
        }}
        animate={{ opacity: 1, x: 0, y: 0 }}
        exit={{ opacity: 0, y: -50 }}
        transition={{ duration: 0.5 }}
        onClick={selectRow}
      >
        <td style={{ width: "25%" }}>
          <div className="d-flex align-items-center">
            <NewAvatar
              imageUrl={data.avatarFilePath}
              company={data.isCompanyOwned}
              initials={data.initials}
              className={classes.avatar}
            />

            <div className="ms-2">
              <div className="d-flex align-items-center">
                <Ui.s bold className="me-1">
                  {data.stakeholderName || "-"}
                </Ui.s>
                {data.invitationStatus === StakeholderInvitationStatus.active && (
                  <Tooltip popupContent={<div>Registered user</div>}>
                    <ActiveUserBudgeIcon fontSize={16} />
                  </Tooltip>
                )}
              </div>

              {data.isCompanyOwned ? (
                <Ui.s style={{ color: scssVariables.foregroundLow }}>{data.representedBy}</Ui.s>
              ) : null}
            </div>
          </div>
        </td>

        <td className="ui-s">{data.shareClasses.join(",") || "-"}</td>

        <td className="ui-s">{data.instrument || "-"}</td>

        <td className="ui-s text-end" colSpan={2}>
          {data.hasPledges ? (
            <LinkHelpText
              title=""
              placement="top"
              className="me-1"
              overlayClass={classes["pledge-info"]}
              content={<Ui.xs bold>{t("pledgedShares")}</Ui.xs>}
            >
              <ExclamationMarkCircle className="me-0" width={16} height={16} color={scssVariables.foregroundLow} />
            </LinkHelpText>
          ) : null}

          {fNumber(data.shares, "amount")}
        </td>

        <td className="ui-s text-end">{fNumber(data.ownershipPercentage, "percent")}</td>

        <td
          className="ui-s text-end"
          style={{
            width: "15%",
          }}
        >
          {fNumber(data.sharesFullyDiluted, "amount")}
        </td>

        <td
          className="ui-s text-end"
          style={{
            width: "15%",
          }}
        >
          <div className="d-flex align-items-center justify-content-end">
            <FilledArrowDownIcon
              width={10}
              height={10}
              direction={data.ownershipPercentage < data.ownershipPercentageFullyDiluted ? "top" : "bottom"}
              className={classes["diluted-arrow"]}
              color={
                data.ownershipPercentage < data.ownershipPercentageFullyDiluted
                  ? scssVariables.positive500
                  : scssVariables.warning500
              }
            />
            <span
              style={{
                color:
                  data.ownershipPercentage < data.ownershipPercentageFullyDiluted
                    ? scssVariables.positive700
                    : scssVariables.warning500,
              }}
            >
              {fNumber(data.ownershipPercentageFullyDiluted, "percent")}
            </span>
          </div>
        </td>

        <td className="ui-s text-end position-relative" style={{ width: "15%" }}>
          {data.differenceFullyDiluted ? (
            <Tag variant="complete" size="s">
              {`+ ${fNumber(data.differenceFullyDiluted, "amount")}`}
            </Tag>
          ) : (
            "-"
          )}
        </td>
      </motion.tr>

      <ShareholderDetailsModal
        show={Boolean(isRowSelected && selectedRowData)}
        type="diluted"
        stakeholderId={data.stakeholderId}
        handleClose={handleClose}
      />
    </>
  );
};

export default memo(Row);
