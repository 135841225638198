import { FC } from "react";

import { PoolDocumentsType } from "../../../DocumentsContext";
import classes from "../../CommonStyles.module.scss";
import PoolDocumentsTableRow from "./PoolDocumentsTableRow";

type ProgramDocumentsTableBodyProps = {
  data: PoolDocumentsType["pools"];
};

const PoolDocumentsTableBody: FC<ProgramDocumentsTableBodyProps> = ({ data }) => {
  return (
    <tbody className={classes["body"]}>
      {data.map((el, index) => (
        <PoolDocumentsTableRow key={`Key for table inner single row is - ${index}`} item={el} />
      ))}
    </tbody>
  );
};

export default PoolDocumentsTableBody;
