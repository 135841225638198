import { FC } from "react";

import Table from "common/components/atoms/Table/Table";

import { GetStakeholdersDTO } from "../../types";
import TableBody from "./body";
import TableHead from "./header";
import classes from "./table-view.module.scss";

type PropsTypes = {
  stakeholders: GetStakeholdersDTO;
  handleOpenEditSidebar: (id: number) => void;
  handleRemoveStakeholder?: (stakeholderId: number) => void;
};

const TableView: FC<PropsTypes> = ({ stakeholders, handleRemoveStakeholder, handleOpenEditSidebar }) => {
  return (
    <Table
      className={classes["table"]}
      headComponent={<TableHead />}
      bodyComponent={
        <TableBody
          stakeholders={stakeholders}
          onEdit={handleOpenEditSidebar}
          handleRemoveStakeholder={handleRemoveStakeholder}
        />
      }
    />
  );
};

export default TableView;
