import { FC } from "react";
import { useFormikContext } from "formik";

import { Helper } from "common/components/atoms";
import { InstrumentTypesIdsEnum } from "common/enums/enum";
import { createTranslation, TranslationNS } from "translation";

import ProgramHelper from "../../../../../wizards/create-program/components/ProgramHelper";
import SpentProgressBar from "../../../../../wizards/create-program/components/SpentProgressBar/SpentProgressBar";
import ProgramExercisePriceField from "../../../../../wizards/create-program/programFields/ProgramExercisePriceField";
import ProgramNumberOfShares from "../../../../../wizards/create-program/programFields/ProgramNumberOfShares";
import ProgramOptionsExpirationDateFields from "../../../../../wizards/create-program/programFields/ProgramOptionsExpirationDateFields";
import ProgramPoolField from "../../../../../wizards/create-program/programFields/ProgramPoolField";
import ProgramPurchasePriceField from "../../../../../wizards/create-program/programFields/ProgramPurchasePriceField";
import ProgramTypeField from "../../../../../wizards/create-program/programFields/ProgramTypeField";
import { CreateProgramFormData } from "../../../../../wizards/create-program/useProgramForm";

const t = createTranslation(TranslationNS.pages, "company.poolsAndPrograms.editProgram");

type ShareDetailsFieldsProps = {
  companyId?: string;
  poolIsDisabled?: boolean;
};
const ShareDetailsFields: FC<ShareDetailsFieldsProps> = ({ companyId, poolIsDisabled }) => {
  const { values } = useFormikContext<CreateProgramFormData>();

  return (
    <Helper>
      <ProgramHelper colInputSize={6} questionId={"poolId"}>
        <ProgramPoolField companyId={companyId} isDisabled={poolIsDisabled} />
      </ProgramHelper>

      <ProgramTypeField />

      <ProgramHelper className="mt-4" colInputSize={6} questionId={["numberOfShares"]}>
        <ProgramNumberOfShares />
        <SpentProgressBar
          className="mt-1"
          title={t("poolStatus.title")}
          valueText={t("poolStatus.valueText")}
          now={values.numberOfShares || 0}
          max={values?.numberOfSharesLeft || 0}
        />
      </ProgramHelper>

      <ProgramHelper className="mt-4" colInputSize={6} questionId={["exercisePrice", "purchasePrice"]}>
        {values.programTypeId === InstrumentTypesIdsEnum.OPTION ? <ProgramExercisePriceField /> : null}
        <ProgramPurchasePriceField />
      </ProgramHelper>

      {values.programTypeId === InstrumentTypesIdsEnum.OPTION && (
        <ProgramHelper className="mt-4" colInputSize={8} questionId={"optionsExpiration"}>
          <ProgramOptionsExpirationDateFields />
        </ProgramHelper>
      )}
    </Helper>
  );
};
export default ShareDetailsFields;
