import { FC, useMemo } from "react";
import { PointTooltip } from "@nivo/line";
import cn from "classnames";
import { format } from "date-fns";
import * as R from "ramda";

import { P, StepChart, Ui } from "common/components/atoms";
import {
  setMissedQuarters,
  splitExercisedAndAvailableForPurchaseDataPoints,
  transformChartAxisToYearsOrQuarters,
} from "common/helpers";
import useFormatNumbers, { formatNumber } from "common/hooks/useFormatNumbers";
import EmptyChart from "common/icons/empty_chart.png";
import EmptyContainer from "common/icons/empty_container.png";
import { stepPoint } from "common/types/Charts.types";
import { scssVariables } from "common/utils/constants";
import { CommonOwnershipPlanDetails } from "store/types";
import { createTranslation, TranslationNS } from "translation";

import { FilledArrowDownIcon } from "../../../../../../icons/svg";
import classes from "./VestedAndExercisedChart.module.scss";

type PropsTypes = {
  stockOptionsPlan: CommonOwnershipPlanDetails | null;
  className?: string;
  isLosted?: boolean;
};

const t = createTranslation(TranslationNS.common, "organisms.agreement.vestedAndExercisedChart");

const VestedAndExercisedChart: FC<PropsTypes> = ({ isLosted = false, className, stockOptionsPlan }) => {
  const fNumber = useFormatNumbers();

  const data = useMemo(() => {
    if (
      !R.isNil(stockOptionsPlan) &&
      (!R.isEmpty(stockOptionsPlan.restrictedDataPoints) || !R.isEmpty(stockOptionsPlan.vestedDataPoints))
    ) {
      let restrictedValues = [];

      if (stockOptionsPlan.restrictedDataPoints?.[0]?.x) {
        restrictedValues.push({
          x: stockOptionsPlan.restrictedDataPoints[0]?.x,
          y: stockOptionsPlan.vestedDataPoints?.[stockOptionsPlan.vestedDataPoints?.length - 1]?.y,
        });
      }

      restrictedValues.push(...stockOptionsPlan.restrictedDataPoints);

      if (
        stockOptionsPlan.restrictedDataPoints?.[0]?.x &&
        stockOptionsPlan.restrictedDataPoints?.[0]?.x !== R.last(stockOptionsPlan.vestedDataPoints)?.x
      ) {
        restrictedValues = R.insert(0, R.last(stockOptionsPlan.vestedDataPoints), restrictedValues) as stepPoint[];
      }

      return [
        {
          id: "Shares vested",
          color: scssVariables.positive050,
          data: setMissedQuarters(stockOptionsPlan.vestedDataPoints),
        },
        {
          id: "Total",
          color: isLosted ? scssVariables.critical700 : scssVariables.positive900,
          data: setMissedQuarters(restrictedValues),
        },
      ];
    }
  }, [isLosted, stockOptionsPlan]);

  const bottomValues = useMemo(() => {
    if (!data) {
      return [];
    }

    return transformChartAxisToYearsOrQuarters({
      data: R.concat(data?.[0]?.data, data?.[1]?.data) || [],
    });
  }, [data]);

  const marginLeft =
    formatNumber(Math.max(...(R.pluck("y", stockOptionsPlan?.restrictedDataPoints || []) as number[]))).length * 8 + 10;

  const renderTooltip: PointTooltip = ({ point }) => {
    return (
      <div className={classes.tooltip}>
        <Ui.xs bold>{point?.data?.y as number}</Ui.xs>
        <div className={classes.triangle}>
          <FilledArrowDownIcon width={25} height={10} stroke="white" direction="bottom" />
        </div>
      </div>
    );
  };

  return (
    <div className={cn(className, classes.container)}>
      {R.isNil(data) || !data.length ? null : (
        <Ui.xs className={classes["label"]} style={{ color: scssVariables.foregroundMedium }}>
          {t("shares")}
        </Ui.xs>
      )}

      <Ui.m bold>{t("optionsVestedAndExercised")}</Ui.m>

      <Ui.xs className="mb-5" style={{ color: scssVariables.foregroundMedium }}>
        {t("disaplyingTheProcess")}
      </Ui.xs>

      {R.isNil(data) || !data.length ? (
        <>
          <div className={classes["empty-image-container"]}>
            <img alt="" src={EmptyContainer} />
            <img alt="" src={EmptyChart} />
          </div>

          <P.m className="mt-5">{t("empty")}</P.m>
        </>
      ) : (
        <StepChart
          height={200}
          disableLegend
          gridYValues={4}
          gridXValues={5}
          showPointOnHover
          tooltip={renderTooltip}
          data={splitExercisedAndAvailableForPurchaseDataPoints(
            R.defaultTo([], data),
            R.defaultTo(0, stockOptionsPlan?.availableForPurchaseShares)
          )}
          axisLeft={{
            tickSize: 0,
            tickValues: 4,
            tickPadding: 10,
            format: (value) => fNumber(value, "amount"),
          }}
          margin={{ left: marginLeft, right: 40, top: 20, bottom: 30 }}
          axisBottom={{
            tickSize: 0,
            renderTick: (tick) => {
              const isTickVisible = bottomValues?.includes(tick?.value);

              return isTickVisible ? (
                <foreignObject width={80} height={15} x={tick?.x - 40} y={tick?.y + 16}>
                  <Ui.xs style={{ color: scssVariables.foregroundMedium }}>
                    {format(new Date(tick.value), "MMM dd, yyyy")}
                  </Ui.xs>
                </foreignObject>
              ) : (
                <></>
              );
            },
          }}
        />
      )}

      {R.isNil(data) || !data.length ? null : (
        <div
          className={cn("ms-2 mt-4", classes["legend"], {
            [classes["is-losted"]]: isLosted,
          })}
        >
          <div>
            <span className="me-1" />
            <Ui.xs>{t("vestedAndExercised")}</Ui.xs>
          </div>

          <div className="ms-4">
            <span className="me-1" />
            <Ui.xs>{t("availableForPurchase")}</Ui.xs>
          </div>

          <div className="ms-4">
            <span className="me-1" />
            <Ui.xs>{t("waitingToVest")}</Ui.xs>
          </div>
        </div>
      )}
    </div>
  );
};

export default VestedAndExercisedChart;
