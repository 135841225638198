import { FC } from "react";

import { Ui } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";

import classes from "../../Tables.module.scss";

const ConvertibleNoteTableFooter: FC<{ investment: number }> = ({ investment }) => {
  const fNumber = useFormatNumbers();

  return (
    <tfoot className={classes.footer}>
      <tr>
        <th className="ps-4">
          <Ui.s>Total</Ui.s>
        </th>

        <th className="fw-400 text-end">
          <Ui.s>{fNumber(investment, "value")}</Ui.s>
        </th>

        <th colSpan={5} />
      </tr>
    </tfoot>
  );
};

export default ConvertibleNoteTableFooter;
