import { isNil } from "ramda";
import React from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import ConfettiExplosion from "react-confetti-explosion";

import { getPath } from "app/Router/RouterHelper";
import { ROUTER_V2 } from "app/Router/RouterV2.types";
import { Button, H, P } from "common/components/atoms";
import { ChevronRightIcon, UnlistedIcon } from "common/icons/svg";
import MainLayout from "common/layout/MainLayout/MainLayout";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";
import SubscriptionImage from "common/icons/subscription-accepted.png";

import classes from "./SubscriptionAccepted.module.scss";

const t = createTranslation(TranslationNS.pages, "public.subscriptionAccepted");

const SubscriptionAccepted = () => {
  const navigate = useNavigate();

  const authenticated = useStoreState((state) => state.authentication.isAuthenticated);

  const invitedUser = useStoreState((state) => state.invitedUser?.invitedUser);

  const navigateToDashboard = () => {
    if (!isNil(invitedUser)) {
      navigate(getPath(["onboard", "company", "companyInformation"], { companyId: invitedUser?.companyId }));
      return;
    }

    navigate(ROUTER_V2.user.dashboard, {
      replace: true,
    });
  };

  return (
    <MainLayout className="flex-fill">
      <div className={classes["icon"]}>
        <UnlistedIcon width="100%" height="100%" />
      </div>

      <div className={classes["container"]}>
        <ConfettiExplosion />

        <img src={SubscriptionImage} alt="subscription-accepted" className={classes.image} />

        <H.xs className="my-3">{t("title")}</H.xs>

        <P.m className="text-center" color="foregroundMedium">
          {t("description")}
        </P.m>

        <P.m className="mt-3 text-center" color="foregroundMedium">
          {t("description1")}
        </P.m>

        <div className="d-flex mt-auto">
          {authenticated ? (
            <Button iconRight={<ChevronRightIcon />} onClick={navigateToDashboard}>
              {t("continue")}
            </Button>
          ) : (
            <Link to="https://www.unlisted.ai/kontakt-oss">
              <Button variant="secondary">{t("contactUs")}</Button>
            </Link>
          )}
        </div>
      </div>
    </MainLayout>
  );
};

export default SubscriptionAccepted;
