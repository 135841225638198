import { FC, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { getEMPath } from "app/Router/RouterHelper";
import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import Button from "common/components/atoms/Button/Button";
import LoaderContainer from "common/components/atoms/LoaderContainer/LoaderContainer";
import Tag from "common/components/atoms/Tag/Tag";
import useDocumentTitleUpdate from "common/hooks/useDocumentTitleUpdate";
import PageContent from "common/layout/MainLayout/PageContent/PageContent";
import InfoCard from "common/shareClass/components/InfoCard/InfoCard";
import { ShareClassServiceGetResponse } from "common/shareClass/ShareClassService";
import { createTranslation, TranslationNS } from "translation";

import EditShareClass from "./EditShareClass/EditShareClass";
import { ShareClassesStore } from "./store";
import EmptyState from "./Widgets/EmptyState";

const [t, tCommon] = [
  createTranslation(TranslationNS.pages, "company.shareClasses"),
  createTranslation(TranslationNS.common, "noAccess"),
];

const ShareClassesList: FC = () => {
  useDocumentTitleUpdate(t("title"));

  const shareClassesState = ShareClassesStore.useStoreState((state) => state.shareClassesWithDetails);

  const setEditingShareClassIdAction = ShareClassesStore.useStoreActions((actions) => actions.setEditingShareClassId);

  const infoCardEditHandler = useCallback(
    (shareClass: ShareClassServiceGetResponse): void => {
      setEditingShareClassIdAction(shareClass.id);
    },
    [setEditingShareClassIdAction]
  );

  return (
    <>
      {shareClassesState && shareClassesState.length === 1 && <EmptyState />}
      <div className="d-flex flex-wrap gap-3">
        {shareClassesState.map((shareClass) => (
          <InfoCard key={shareClass.id} showStatistics shareClass={shareClass} onEdit={infoCardEditHandler} />
        ))}
      </div>
    </>
  );
};

const Content: FC = () => {
  const navigate = useNavigate();
  const { companyId } = useParams();

  const [loading, setLoading] = useState<boolean>(true);

  const fetchShareClassesWithDetailsThunk = ShareClassesStore.useStoreActions(
    (actions) => actions.fetchShareClassesWithDetails
  );

  const { hasFullAccess } = useFeatures(FEATURES.shareClasses);

  const createShareClassButtonClickHandler = useCallback((): void => {
    navigate(getEMPath(["createShareClass", "main"]));
  }, [navigate]);

  useEffect(() => {
    setLoading(true);
    fetchShareClassesWithDetailsThunk(companyId).finally(() => {
      setLoading(false);
    });
  }, [companyId, fetchShareClassesWithDetailsThunk]);

  return (
    <>
      <PageContent>
        <PageContent.Header>
          <div className="d-flex  align-items-center">
            <PageContent.Header.Title className="me-2">{t("title")}</PageContent.Header.Title>
            {!hasFullAccess && <Tag variant="access">{tCommon("viewOnly")}</Tag>}
          </div>

          <Button
            isFocusDisabled
            isDisabled={!hasFullAccess}
            tooltipTitle={!hasFullAccess ? tCommon("viewOnly") : undefined}
            tooltipMessage={!hasFullAccess ? tCommon("tooltip") : undefined}
            onClick={createShareClassButtonClickHandler}
          >
            {t("addShareClass")}
          </Button>
        </PageContent.Header>
        <LoaderContainer loading={loading}>
          <ShareClassesList />
        </LoaderContainer>
      </PageContent>
      <EditShareClass />
    </>
  );
};

const ShareClasses: FC = () => {
  return (
    <ShareClassesStore.Provider>
      <Content />
    </ShareClassesStore.Provider>
  );
};

export default ShareClasses;
