import { FC, useCallback } from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import classNames from "classnames";
import { Form, useFormikContext } from "formik";
import { isNil } from "ramda";

import { getPath } from "app/Router/RouterHelper";
import { Button, DatePicker, DatePickerProps, Divider, Dropdown, H, P, TextField, Ui } from "common/components/atoms";
import { ToastFormikValidator } from "common/hooks/useToastFormikValidator";
import { ArrowLeftIcon, ArrowRightIcon } from "common/icons/svg";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import classes from "./CompanyInformation.module.scss";
import { fields, OnboardingCompanyBasicFormValues } from "./useCompanyInformationForm";

const t = createTranslation(TranslationNS.pages, "onboard.company.basic");

const CompanyInformationForm: FC = () => {
  const { companyId } = useParams<{ companyId?: string }>();

  const countries = useStoreState((state) => state.common.dropdowns)?.countries;
  const currencies = useStoreState((state) => state.common.dropdowns)?.currencies;
  const { bregg, uniMicro } = useStoreState((state) => state.companyOnboardingModel.integrations);
  const companyType = useStoreState((state) => state.companyOnboardingModel.companyType);

  const { values, errors, touched, handleChange, handleBlur, setFieldValue, isSubmitting, handleSubmit } =
    useFormikContext<OnboardingCompanyBasicFormValues>();

  const handleChaneDate = useCallback<DatePickerProps["onChange"]>(
    (date, name) => {
      if (name) {
        setFieldValue(name, date);
      }
    },
    [setFieldValue]
  );

  const CurrencySymbol = useCallback(() => {
    return (
      <Ui.m className={classes.currency}>{currencies?.find((el) => el.id === values.currencyId)?.symbol || ""}</Ui.m>
    );
  }, [currencies, values.currencyId]);

  const isThirdPartyIntegration = bregg || uniMicro;

  return (
    <div className={classes.wrap}>
      <ToastFormikValidator showAlert={false} />
      <div>
        <H.s className="mb-2">{t("header")}</H.s>
        <P.m className={classNames(classes.subheader)}>
          {t(isNil(isThirdPartyIntegration) ? "subheaderWithoutPreFill" : "subheader")}
        </P.m>

        <Row className="mt-6">
          <Col>
            <TextField
              type="text"
              value={values.name}
              className="mb-4"
              label={`${t("form.companyName")}`}
              error={errors.name}
              isTouched={touched.name}
              name={fields.name}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Col>
          <Col>
            <TextField
              isOptional
              type="text"
              value={values.organizationNumber}
              label={t("form.organizationNumber")}
              name={fields.organizationNumber}
              onChange={handleChange}
              error={errors.organizationNumber}
              isTouched={touched.organizationNumber}
              onBlur={handleBlur}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Dropdown
              isSearchable
              label={`${t("form.companyLocation")}`}
              searchPlaceholder={t("form.countryPlaceholder")}
              selectedValue={+values.headquartersCountryId || 0}
              error={errors.headquartersCountryId}
              isTouched={touched.headquartersCountryId}
              optionsIsObject
              name={fields.headquartersCountryId}
              options={countries || []}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Col>
          <Col>
            <TextField
              type="text"
              value={values.city}
              label={t("form.companyCity")}
              name={fields.city}
              onChange={handleChange}
              error={errors.city}
              isTouched={touched.city}
              onBlur={handleBlur}
            />
          </Col>
        </Row>
        <Divider className="mt-6 mb-6" />

        <H.xxs className="mb-2">{t("form.enterValuation")}</H.xxs>

        <P.m className={classNames(classes.subheader)}>
          {t(
            isNil(isThirdPartyIntegration)
              ? "form.enterValuationDescriptionWithoutPreFill"
              : "form.enterValuationDescription"
          )}
        </P.m>

        <Row className="mb-4 mt-4">
          <Col>
            <Dropdown
              label={`${t("form.valuationCurrency")}`}
              selectedValue={+(values?.currencyId || 0)}
              error={errors.currencyId}
              isTouched={touched.currencyId}
              optionsIsObject
              name={fields.currencyId}
              options={currencies || []}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Col>
          <Col>
            <TextField
              type="number"
              placeholder={t("form.valuationPlaceholder")}
              value={values.valuation}
              label={t("form.valuation")}
              name={fields.valuation}
              onChange={handleChange}
              error={errors.valuation}
              isTouched={touched.valuation}
              iconRight={errors.valuation && touched.valuation ? undefined : <CurrencySymbol />}
              onBlur={handleBlur}
            />
          </Col>
        </Row>
        <Row className="mb-8">
          <Col>
            {companyType === "few-owners" ? (
              <TextField
                type="number"
                name={fields.numberOfShares}
                label={t("form.numberOfShares")}
                onChange={handleChange}
                value={values.numberOfShares}
                error={errors.numberOfShares}
                isTouched={touched.numberOfShares}
                onBlur={handleBlur}
                placeholder={t("form.numberOfSharesPlaceholder")}
              />
            ) : (
              <TextField
                placeholder={t("form.nominalShareValuePlaceholder")}
                type="number"
                name={fields.nominalShareValue}
                label={t("form.nominalShareValue")}
                onChange={handleChange}
                value={values.nominalShareValue}
                error={errors.nominalShareValue}
                isTouched={touched.nominalShareValue}
                onBlur={handleBlur}
                iconRight={errors.valuation && touched.valuation ? undefined : <CurrencySymbol />}
              />
            )}
          </Col>
          <Col>
            <DatePicker
              isClearable
              isDateOnlyString
              label={t("form.foundingDate")}
              date={values.foundingDate}
              onBlur={handleBlur}
              isTouched={touched.foundingDate}
              error={errors.foundingDate}
              onChange={handleChaneDate}
              name={fields.foundingDate}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            {!companyId && (
              <Link to={getPath(["onboard", "company", "setup"])}>
                <Button isDisabled={isSubmitting} isLoading={isSubmitting} variant="secondary" isOnlyIcon>
                  <ArrowLeftIcon />
                </Button>
              </Link>
            )}
          </Col>
          <Col className="text-end">
            <Form onSubmit={handleSubmit}>
              <Button isDisabled={isSubmitting} isLoading={isSubmitting} type="submit" isOnlyIcon>
                <ArrowRightIcon />
              </Button>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default CompanyInformationForm;
