import { useEffect } from "react";
import { Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { defaultTo, isNil } from "ramda";

import { Button, H } from "common/components/atoms";
import { StripePricingTable } from "common/components/molecules";
import useStripeSubscription from "common/hooks/useStripeSubscription";
import { ArrowLeftIcon } from "common/icons/svg";
import { InvitedUser } from "store/InvitedUserModel";
import { useStoreActions, useStoreState } from "store/store";

import classes from "./PricingTable.module.scss";

const PricingTable = () => {
  const navigate = useNavigate();
  const { companyId } = useParams<{ companyId?: string }>();

  const { subscription, connectCompanySubscription } = useStripeSubscription(Number(companyId));

  const account = useStoreState((state) => state.account);
  const invitedUser = useStoreState((state) => state.invitedUser);
  const setInvitedUser = useStoreActions((actions) => actions.invitedUser.setInvitedUser);

  // adding companyId info for invitedUser entity
  useEffect(() => {
    if (companyId) {
      if (
        isNil(invitedUser?.invitedUser?.companyId) ||
        defaultTo(0, invitedUser?.invitedUser?.companyId) !== Number(companyId)
      ) {
        const initialInvitedUser = defaultTo({}, invitedUser.invitedUser) as InvitedUser;

        setInvitedUser({ ...initialInvitedUser, companyId: Number(companyId) });
      }
    }
  }, [companyId, invitedUser.invitedUser, setInvitedUser]);

  // if user has just 1 company and existing invitationID - triggering API call to apply existing subscription
  // with current company id and connecting subscription with stripe and unlisted API
  useEffect(() => {
    if (
      !subscription?.hasSubscription &&
      account?.user?.companies?.length === 1 &&
      invitedUser?.invitedUser?.entityId
    ) {
      connectCompanySubscription();
    }
  }, [
    account?.user?.companies?.length,
    connectCompanySubscription,
    invitedUser?.invitedUser?.entityId,
    subscription?.hasSubscription,
  ]);

  return (
    <div className={classes.wrap}>
      <H.s className="mb-2">Pick a plan</H.s>
      {!isNil(companyId) ? <StripePricingTable companyId={String(companyId)} /> : null}

      <Row className="mt-3">
        <Button
          variant="secondary"
          isOnlyIcon
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowLeftIcon />
        </Button>
      </Row>
    </div>
  );
};

export default PricingTable;
