import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ColumnDef } from "@tanstack/react-table";
import cn from "classnames";

import { LinkHelpText, TextField, Ui } from "common/components/atoms";
import {
  Actions,
  CheckboxControllerInput,
  CheckboxInput,
  SelectInput,
  TextInput,
} from "common/components/atoms/ImportTable";
import tableClasses from "common/components/atoms/ImportTable/inputs/inputs.module.scss";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { CheckIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { LegalEntityEnum } from "../../components/importOwnership.types";
import classes from "../../import-ownership/ImportOwnership.module.scss";
import { fields } from "../../import-ownership/useImportOwnershipForm";
import { FoundersFormValues } from "./FoundersTable";

const t = createTranslation(TranslationNS.pages, "onboard.company.importFounders");

const useFoundersColumns = () => {
  const fNumber = useFormatNumbers();
  const { i18n } = useTranslation();

  const countries = useStoreState((state) => state.common.dropdowns)?.countries;
  const company = useStoreState((state) => state.companyOnboardingModel.company);
  const [countOfActiveCheckboxes, setCountOfActiveCheckboxes] = useState(0);

  const countriesWithEmpty = useMemo(() => {
    const notSelected = {
      id: 0,
      name: t("notSelected"),
    };
    return countries ? [notSelected, ...countries] : [notSelected];
  }, [countries]);

  const legalEntity = useMemo(() => {
    return [
      {
        id: -1,
        name: t("notSelected"),
      },
      {
        id: LegalEntityEnum.PERSON,
        name: t("personally"),
      },
      {
        id: LegalEntityEnum.COMPANY,
        name: t("company"),
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const columns = useMemo<ColumnDef<FoundersFormValues>[]>(() => {
    const cells: ColumnDef<FoundersFormValues>[] = [
      {
        header: (props) => {
          return (
            <CheckboxControllerInput
              canDoActionField={fields.canDeleteStakeholder}
              columnId={props.column.id}
              allActionsCallback={setCountOfActiveCheckboxes}
            />
          );
        },
        accessorKey: "checkbox",
        cell: (props) => (
          <CheckboxInput
            canDoActionField={fields.canDeleteStakeholder}
            rowIndex={props.row.index}
            columnId={props.column.id}
          />
        ),
        maxSize: 38,
      },
      {
        header: `${t("fields.ownershipType")} *`,
        accessorKey: fields.isCompanyOwned,
        cell: (props) => <SelectInput rowIndex={props.row.index} columnId={props.column.id} options={legalEntity} />,
        minSize: 140,
        footer: (props) => {
          const totalRows = props.table.getFilteredRowModel().rows.length;
          return <span>{`${totalRows} ${totalRows === 1 ? t("founder") : t("founders")}`}</span>;
        },
      },
      {
        header: `${t("fields.companyName")} *`,
        accessorKey: fields.companyName,
        cell: (props) => (
          <TextInput
            disabledWhen={LegalEntityEnum.PERSON}
            nameWhen={fields.isCompanyOwned}
            rowIndex={props.row.index}
            columnId={props.column.id}
            placeholder={t("fields.companyName")}
          />
        ),
        minSize: 110,
      },
      {
        header: t("fields.organizationNumber"),
        accessorKey: fields.organizationNumber,
        cell: (props) => (
          <TextInput
            disabledWhen={LegalEntityEnum.PERSON}
            nameWhen={fields.isCompanyOwned}
            rowIndex={props.row.index}
            columnId={props.column.id}
            placeholder={t("fields.organizationNumber")}
          />
        ),
        minSize: 110,
      },
      {
        header: `${t("fields.firstName")} *`,
        accessorKey: fields.firstName,
        cell: (props) => (
          <TextInput rowIndex={props.row.index} columnId={props.column.id} placeholder={t("fields.firstNameLabel")} />
        ),
        minSize: 110,
      },
      {
        header: `${t("fields.lastName")} *`,
        accessorKey: fields.lastName,
        cell: (props) => (
          <TextInput rowIndex={props.row.index} columnId={props.column.id} placeholder={t("fields.lastNameLabel")} />
        ),
        minSize: 110,
      },
      {
        header: t("fields.email"),
        accessorKey: fields.email,
        cell: (props) => (
          <TextInput rowIndex={props.row.index} columnId={props.column.id} placeholder={t("fields.emailLabel")} />
        ),
        minSize: 134,
      },
      {
        header: `${t("fields.shares")} *`,
        accessorKey: fields.numberOfShares,
        cell: (props) => (
          <TextInput
            rowIndex={props.row.index}
            columnId={props.column.id}
            className={classes.shareClassAlign}
            type="number"
            placeholder={t("fields.shares")}
          />
        ),
        footer: ({ table }) => {
          const total = table
            .getFilteredRowModel()
            .rows.reduce((sum, row) => +sum + +(row.original.numberOfShares || 0), 0);
          return (
            <div className="text-end">
              <LinkHelpText title="" content={t("sharesOutOfInfo")}>
                <div className="d-flex">
                  {total === company?.numberOfShares ? (
                    <CheckIcon fontSize={24} color={scssVariables.positive900} />
                  ) : null}

                  <Ui.s color={total === company?.numberOfShares ? "positive900" : "foregroundHigh"}>
                    {t("sharesOutOf", {
                      currentShares: fNumber(total, "amount"),
                      totalShares: company?.numberOfShares,
                    })}
                  </Ui.s>
                </div>
              </LinkHelpText>
            </div>
          );
        },
        minSize: 80,
        meta: {
          headClass: "text-end",
        },
      },

      {
        header: `${t("fields.ownership")} %`,
        accessorKey: fields.ownership,
        cell: (props) => {
          const sharesFieldValue = Number(
            props.table
              .getRow(props.row.id)
              .getAllCells()
              ?.find((el) => el.id?.includes(fields.numberOfShares))
              ?.getValue() || 0
          );

          return (
            <TextField
              value={fNumber((sharesFieldValue / (company?.numberOfShares || 0)) * 100, "percent")}
              isDisabled
              className={cn(tableClasses.textInput, classes["text-input"])}
            />
          );
        },
        footer: ({ table }) => {
          const total =
            (table.getFilteredRowModel().rows.reduce((sum, row) => +sum + +(row.original.numberOfShares || 0), 0) /
              (company?.numberOfShares || 0)) *
            100;

          return (
            <div className="text-end">
              <LinkHelpText title="" content={t("sharesOutOfInfo")}>
                <div className="d-flex">
                  {total === company?.numberOfShares ? (
                    <CheckIcon fontSize={24} color={scssVariables.positive900} />
                  ) : null}

                  <Ui.s>{fNumber(total, "percent")}</Ui.s>
                </div>
              </LinkHelpText>
            </div>
          );
        },
        minSize: 80,
        meta: {
          headClass: "text-end",
        },
      },

      {
        header: t("fields.address"),
        accessorKey: fields.address,
        cell: (props) => (
          <TextInput rowIndex={props.row.index} columnId={props.column.id} placeholder={t("fields.address")} />
        ),
        minSize: 110,
      },
      {
        header: `${t("fields.country")}`,
        accessorKey: fields.countryId,
        cell: (props) => (
          <SelectInput
            isSearchable
            rowIndex={props.row.index}
            columnId={props.column.id}
            options={countriesWithEmpty}
          />
        ),
        minSize: 140,
      },
      {
        accessorKey: "actions",
        header: "",
        cell: ({ row, table }) => (
          <Actions
            rowIndex={row.index}
            updateData={table.options.meta?.updateData}
            globalFilter={table.getState().globalFilter}
            canDoActionField={fields.canDeleteStakeholder}
          />
        ),
        maxSize: 42,
      },
    ];

    return cells;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countriesWithEmpty, legalEntity, i18n.language]);

  return { columns, countOfActiveCheckboxes };
};

export default useFoundersColumns;
