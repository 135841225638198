import { FC, Fragment } from "react";
import cn from "classnames";

import { NewAvatar, P, Tag, Ui } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { ArrowRightIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { createTranslation, TranslationNS } from "translation";

import { Transaction, TransactionCategory } from "../../../../types";
import classes from "../transaction-item.module.scss";

type PropsTypes = {
  transaction: Transaction;
};

const t = createTranslation(TranslationNS.pages, "company.transactions.transactionItem");

const IssueSharesAndBuySellBody: FC<PropsTypes> = ({ transaction }) => {
  const fNumber = useFormatNumbers();

  return (
    <>
      {/* Avatars with transactions */}
      <div
        className={cn("d-flex justify-content-evenly align-items-stretch mb-3", {
          [classes.border]: !transaction.description,
        })}
      >
        {transaction.categoryId === TransactionCategory.Sell && (
          <>
            <div className="d-flex flex-column align-items-center justify-content-between mb-3">
              <div className="d-flex flex-column align-items-center">
                <NewAvatar
                  className="mb-2"
                  imageUrl={transaction.fromAvatar}
                  company={transaction.fromIsCompanyOwned}
                  initials={transaction.fromInitials}
                />
                <Ui.l bold className="mb-1">
                  {transaction.fromName || "-"}
                </Ui.l>
                {transaction.fromRepresentativeName && (
                  <Ui.s className="mb-2 text-medium">{transaction.fromRepresentativeName}</Ui.s>
                )}
              </div>
              <Ui.xl className="mt-1" bold style={{ color: scssVariables.critical500 }}>
                {transaction.categoryId === TransactionCategory.Sell
                  ? `- ${fNumber(transaction.numberOfShares, "amount")}`
                  : "-"}
              </Ui.xl>
            </div>
            <div className="d-flex align-items-center">
              <ArrowRightIcon width={24} height={24} color={scssVariables.foregroundLow} />
            </div>
          </>
        )}
        <div className="d-flex flex-column align-items-center justify-content-between mb-3">
          <div className="d-flex flex-column align-items-center">
            <NewAvatar
              className="mb-2"
              imageUrl={transaction.toAvatar}
              company={transaction.toIsCompanyOwned}
              initials={transaction.toInitials}
            />
            <Ui.l bold className="mb-1">
              {transaction.toName}
            </Ui.l>
            {transaction.toRepresentativeName && (
              <Ui.s className="mb-2 text-medium">{transaction.toRepresentativeName}</Ui.s>
            )}
          </div>
          <Ui.xl className="mt-1" bold style={{ color: scssVariables.positive700 }}>
            {`+ ${fNumber(transaction.numberOfShares, "amount")}`}
          </Ui.xl>
        </div>
      </div>
      {/* Description */}
      {transaction.description && (
        <P.s style={{ color: scssVariables.foregroundLow }} className={cn("mb-3 pb-3", classes.border)}>
          {transaction.description}
        </P.s>
      )}
      {/* Transaction info */}
      <div
        className={cn("d-flex flex-column mb-3 pb-3", classes.border)}
        style={{ color: scssVariables.foregroundMedium }}
      >
        <div className="d-flex justify-content-evenly mb-3">
          <div className="d-flex flex-column align-items-center w-25">
            <Ui.m bold>{t("transactionTotal")}</Ui.m>
            <Ui.m>{fNumber(transaction.transactionTotal, "amount")}</Ui.m>
          </div>
          <div className="d-flex flex-column align-items-center w-25">
            <Ui.m bold>{t("numberOfShares")}</Ui.m>
            <Ui.m>{fNumber(transaction.numberOfShares, "amount")}</Ui.m>
          </div>
        </div>
        <div className="d-flex justify-content-evenly">
          <div className="d-flex flex-column align-items-center w-25">
            <Ui.m bold>{t("sharePrice")}</Ui.m>
            <Ui.m>{fNumber(transaction.sharePrice, "sharePrice")}</Ui.m>
          </div>
          <div className="d-flex flex-column align-items-center w-25">
            <Ui.m bold>{t("shareClass")}</Ui.m>
            <Ui.m>{transaction.shareClassName}</Ui.m>
          </div>
        </div>
      </div>
      {/* Shares series */}
      {transaction.shareSeries && transaction.shareSeries.length > 0 && (
        <div className="d-flex flex-column align-items-center">
          <Ui.m bold className="mb-2" style={{ color: scssVariables.foregroundMedium }}>
            {t("shareNumbers")
              ?.split(" ")
              ?.map((el, index) => (
                <Fragment key={el}>
                  <span style={{ textTransform: index === 0 ? "capitalize" : undefined }}>{el}</span>{" "}
                </Fragment>
              ))}
          </Ui.m>
          <div className="d-flex flex-wrap justify-content-center gap-1">
            {transaction.shareSeries.map((range) => (
              <Tag variant="closed" key={range}>
                <Ui.s>
                  {range
                    .split("-")
                    .map((number) => fNumber(number, "amount"))
                    .join(" - ")}
                </Ui.s>
              </Tag>
            ))}
          </div>
        </div>
      )}
    </>
  );
};
export default IssueSharesAndBuySellBody;
