import { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useFormikContext } from "formik";

import { getEMPath } from "app/Router/RouterHelper";
import { ChecksGroup, H, PeriodPickerFormik } from "common/components/atoms";
import { useWizardStepper } from "common/layout/WizardLayout/hooks";
import WizardContent from "common/layout/WizardLayout/WizardContent/WizardContent";
import { newPlanFieldsNames as f, periodVariants } from "common/plan/planFormFields";
import { createTranslation, TranslationNS } from "translation";

import { OwnershipPlanForm } from "../../CreateGrantOneOffPlan";
import classes from "./PostTerminationExerciseClause.module.scss";

const t = createTranslation(TranslationNS.pages, "company.grantOneOffRSAPlan.terminationExercise");

const PostTerminationExerciseClause: FC = () => {
  useWizardStepper(5);
  const navigate = useNavigate();

  const handleStepBack = useCallback(() => {
    navigate(getEMPath(["createOneOffPlan", "vestingConditions"]));
  }, [navigate]);

  const { values, setFieldValue, handleSubmit } = useFormikContext<OwnershipPlanForm>();

  const submitHandler = useCallback(() => {
    handleSubmit();
  }, [handleSubmit]);

  return (
    <WizardContent.Content>
      <H.xxs className="text-center">{t("title")}</H.xxs>

      <ChecksGroup className="my-5">
        <ChecksGroup.Check
          type="radio"
          label={t("includeClauses")}
          checked={!values.isExcludedClause}
          onChange={(e) => {
            setFieldValue("isExcludedClause", false);
          }}
        />

        <ChecksGroup.Check
          type="radio"
          label={t("excludeClauses")}
          checked={values.isExcludedClause}
          onChange={() => {
            setFieldValue("isExcludedClause", true);
          }}
        />
      </ChecksGroup>
      {values.isExcludedClause ? null : (
        <div className={classes["block-wrapper"]}>
          <PeriodPickerFormik
            label={t("retirement")}
            periodVariants={periodVariants}
            className={`${classes["one-off-optins"]} mb-3`}
            nameAmount={f.retirement}
            namePeriod={f.retirementTimeUnit}
          />

          <PeriodPickerFormik
            label={t("disability")}
            periodVariants={periodVariants}
            className={`${classes["one-off-optins"]} mb-3`}
            nameAmount={f.disability}
            namePeriod={f.disabilityTimeUnit}
          />

          <PeriodPickerFormik
            label={t("terminationWithCause")}
            periodVariants={periodVariants}
            className={`${classes["one-off-optins"]} mb-3`}
            nameAmount={f.terminationWithCause}
            namePeriod={f.terminationWithCauseTimeUnit}
          />

          <PeriodPickerFormik
            label={t("involuntaryTermination")}
            periodVariants={periodVariants}
            className={`${classes["one-off-optins"]} mb-3`}
            nameAmount={f.involuntaryTermination}
            namePeriod={f.involuntaryTerminationTimeUnit}
          />

          <PeriodPickerFormik
            label={t("voluntaryTermination")}
            periodVariants={periodVariants}
            className={`${classes["one-off-optins"]} mb-3`}
            nameAmount={f.voluntaryTermination}
            namePeriod={f.voluntaryTerminationTimeUnit}
          />

          <PeriodPickerFormik
            label={t("passingAway")}
            periodVariants={periodVariants}
            className={`${classes["one-off-optins"]} mb-3`}
            nameAmount={f.byPassingAway}
            namePeriod={f.byPassingAwayTimeUnit}
          />
        </div>
      )}

      <div className="d-flex mt-8">
        <WizardContent.Controls.BackButton className="ms-auto me-2" onClick={handleStepBack} />
        <WizardContent.Controls.ContinueButton onClick={submitHandler} />
      </div>
    </WizardContent.Content>
  );
};

export default PostTerminationExerciseClause;
