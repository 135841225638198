import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import { equals, isEmpty } from "ramda";

import { Button, ContextMenu, ContextMenuProps } from "common/components/atoms";
import SearchField from "common/components/atoms/SearchField/SearchField";
import useDebounce from "common/hooks/useDebounce";
import {
  ChevronDownIcon,
  CloseIcon,
  EquityIcon,
  ShareClassesIcon,
  ShareholdersRelationshipIcon,
  UploadIcon,
  UsersIcon,
} from "common/icons/svg";
import { downloadExcelFile } from "common/utils/download";
import { createTranslation, TranslationNS } from "translation";

import Valuation from "../../FullyDiluted/Valuation/Valuation";
import CapTableStore from "../../store";
import classes from "./CaptableActions.module.scss";

const t = createTranslation(TranslationNS.pages, "company.capTable.actions");

const CapTableActions: FC<
  {
    type: "actual" | "diluted";
    setHowCalculateModalOpen?: (val: boolean) => void;
  } & JSX.IntrinsicElements["div"]
> = ({ setHowCalculateModalOpen, type = "actual", className, ...props }) => {
  const { companyId } = useParams<{ companyId: string }>();

  // internal search value to use it in debounce function
  const [search, setSearch] = useState<string>("");
  const [valuation, setValuation] = useState<string>("");

  const isDiluted = equals(type, "diluted");

  const dilutedData = CapTableStore.useStoreState((state) => state.dilutedData);
  const selectedGroup = CapTableStore.useStoreState((state) =>
    isDiluted ? state.selectedDilutedGroup : state.selectedActualGroup
  );
  const setSelectedGroupAction = CapTableStore.useStoreActions((actions) =>
    isDiluted ? actions.setSelectedDilutedGroup : actions.setSelectedActualGroup
  );
  const setSearchValue = CapTableStore.useStoreActions((actions) => actions.setSearchValue);

  const debouncedValue = useDebounce(search, 1000);

  const groupByOptions = useMemo(() => {
    const options: ContextMenuProps["items"] = [
      {
        key: "default",
        forceHideDropdown: true,
        icon: <CloseIcon />,
        selected: equals(selectedGroup, "default"),
        label: "None",
        color: "red",
        onClick: () => {
          setSelectedGroupAction("default");
        },
      },
      {
        key: "stakeholder",
        icon: <UsersIcon />,
        forceHideDropdown: true,
        selected: isDiluted ? equals(selectedGroup, "stakeholder") : equals(selectedGroup, "shareholder"),
        label: isDiluted ? t("groupBy.options.stakeholder") : t("groupBy.options.shareholder"),
        color: "red",
        onClick: () => {
          // @ts-ignore
          setSelectedGroupAction(isDiluted ? "stakeholder" : "shareholder");
        },
      },
      {
        key: "relationship",
        forceHideDropdown: true,
        icon: <ShareholdersRelationshipIcon />,
        selected: equals(selectedGroup, "relationship"),
        label: t("groupBy.options.relationship"),
        onClick: () => {
          setSelectedGroupAction("relationship");
        },
      },
      {
        key: "shareClass",
        icon: <ShareClassesIcon />,
        selected: equals(selectedGroup, "shareClass"),
        forceHideDropdown: true,
        label: t("groupBy.options.shareClass"),
        onClick: () => {
          setSelectedGroupAction("shareClass");
        },
      },
    ];

    if (isDiluted) {
      options.push({
        key: "instrument",
        forceHideDropdown: true,
        icon: <EquityIcon />,
        label: t("groupBy.options.instrument"),
        selected: equals(selectedGroup, "instrument"),
        onClick: () => {
          setSelectedGroupAction("instrument" as unknown as any);
        },
      });
    }

    return options;
  }, [isDiluted, selectedGroup, setSelectedGroupAction]);

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  }, []);

  const handleExport = useCallback(async () => {
    downloadExcelFile(
      isDiluted
        ? `/api/export/fully-diluted/${companyId}/${
            isEmpty(valuation) ? dilutedData.capTableSummary.valuation : valuation
          }`
        : `/api/export/captable/${companyId}`,
      isDiluted ? "Fully diluted capTableData" : "CapTable data"
    );
  }, [companyId, dilutedData.capTableSummary.valuation, isDiluted, valuation]);

  useEffect(() => {
    setSearchValue(debouncedValue);
  }, [debouncedValue, setSearchValue]);

  useEffect(() => {
    setSearch("");
  }, [selectedGroup]);

  return (
    <div className={classNames(className, "d-flex")} {...props}>
      <SearchField value={search} placeholder={t("search.placeholder")} onChange={handleChange} />

      {isDiluted ? <Valuation valuation={valuation} setValuation={setValuation} /> : null}

      <div className="d-flex ms-auto mt-1">
        {isDiluted ? (
          <Button
            size="s"
            variant="tertiary"
            onClick={() => {
              setHowCalculateModalOpen?.(true);
            }}
          >
            How did we calculate this?
          </Button>
        ) : null}

        <ContextMenu items={groupByOptions} chevronAnimation drop="down-centered">
          <Button
            size="s"
            variant="tertiary"
            className={classes["context-button"]}
            iconRight={<ChevronDownIcon className="chevron ms-half" />}
          >
            {t("groupBy.label")}
          </Button>
        </ContextMenu>

        <Button
          size="s"
          variant="tertiary"
          className={classNames(classes["export-btn"], "ms-1")}
          iconRight={<UploadIcon className="ms-half" />}
          onClick={handleExport}
        >
          Export
        </Button>
      </div>
    </div>
  );
};

export default CapTableActions;
