import { FC } from "react";
import { useFormikContext } from "formik";

import StakeholderSearchField from "../fields/StakeholderSearchField";
import SelectedStakeholder from "../SelectedStakeholder/SelectedStakeholder";
import { StakeholderFormValues } from "../Stakeholder.types";

type ExistingStakeholderProps = {
  isDisabled?: boolean;
  allowUpdate?: boolean;
};
const ExistingStakeholder: FC<ExistingStakeholderProps> = ({ isDisabled, allowUpdate }) => {
  const { values } = useFormikContext<StakeholderFormValues>();
  return (
    <div>
      {!values.stakeholderId ? (
        <StakeholderSearchField />
      ) : (
        <SelectedStakeholder isDisabled={isDisabled} allowUpdate={allowUpdate} />
      )}
    </div>
  );
};

export default ExistingStakeholder;
