import { forwardRef, MouseEvent, useCallback } from "react";
import { AccordionEventKey } from "react-bootstrap/AccordionContext";
import cn from "classnames";
import { isNil } from "ramda";

import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import { Button, Ui } from "common/components/atoms";
import { InstrumentTypesNamesEnum } from "common/enums/enum";
import { ChevronDownIcon, PlusIcon } from "common/icons/svg";
import { OwnershipProgramTableData } from "common/types/Collapsible.types";
import { scssVariables } from "common/utils/constants";
import { createTranslation, TranslationNS } from "translation";

import { PlanStatuses } from "../../../../ownership/documents/documents.types";
import classes from "./ProgramContainer.module.scss";

const tCommon = createTranslation(TranslationNS.common);

type ProgramHeaderProps = {
  title?: string;
  onHeadPress?: () => void;
  stockType?: keyof typeof InstrumentTypesNamesEnum;
  data: OwnershipProgramTableData[];
  description?: string;
  cliff?: number;
  vestingPeriod?: number;
  vestingPeriodTimeUnit?: string;
  cliffTimeUnit?: string;
  activeEventKey?: AccordionEventKey;
  onClick: (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => void;
  status?: string;
  handleAddPlan?: () => void;
};
const ProgramHeader = forwardRef<HTMLDivElement, ProgramHeaderProps>(
  (
    {
      title,
      onHeadPress,
      stockType,
      data,
      handleAddPlan,
      cliff,
      vestingPeriod,
      activeEventKey,
      status,
      onClick,
      vestingPeriodTimeUnit = "Months",
      cliffTimeUnit = "Months",
    },
    ref
  ) => {
    const { hasFullAccess } = useFeatures(FEATURES.managePlans);

    const handleClick = useCallback(
      (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
        onClick(e);
        if (isNil(activeEventKey)) {
          onHeadPress?.();
        }
      },
      [activeEventKey, onClick, onHeadPress]
    );

    return (
      <div
        ref={ref}
        className={cn("d-flex justify-content-between align-items-center", classes["program-header"])}
        onClick={handleClick}
      >
        <div className="d-flex flex-column">
          <div className="d-flex align-items-center">
            <Ui.xl bold>{title}</Ui.xl>
            <div className={classes["rounded-container"]}>
              <Ui.s>{data.length}</Ui.s>
            </div>
          </div>
          <div className="mt-1">
            <ul className={classes.subHeader}>
              <li
                className={cn({
                  [classes.approved]: status === PlanStatuses.approved,
                  [classes.draft]: status === PlanStatuses.draft,
                })}
              >
                <span>{status}</span>
              </li>
              {stockType && (
                <li>
                  <span>{stockType}</span>
                </li>
              )}
              {!!cliff && (
                <li>
                  <span>{tCommon(`period.vestingCliff${cliffTimeUnit}`, { count: cliff })}</span>
                </li>
              )}
              {!!vestingPeriod && (
                <li>
                  <span>{tCommon(`period.vestingPeriod${vestingPeriodTimeUnit}`, { count: vestingPeriod })}</span>
                </li>
              )}
            </ul>
          </div>
        </div>
        <div className="d-flex">
          <div className="d-flex flex-column">
            <Button
              size="s"
              isOnlyIcon
              variant="primary"
              data-testid="event-button"
              onClick={handleAddPlan}
              isDisabled={status === PlanStatuses.draft || !hasFullAccess}
              tooltipTitle={!hasFullAccess ? tCommon("noAccess.viewOnly") : undefined}
              tooltipMessage={!hasFullAccess ? tCommon("noAccess.tooltip") : undefined}
              className={classes["add-btn"]}
            >
              <PlusIcon />
            </Button>
          </div>
          <div className="ms-2 d-flex flex-column justify-content-center">
            <ChevronDownIcon
              fontSize={24}
              color={scssVariables.foregroundLow}
              direction={activeEventKey ? "top" : "bottom"}
            />
          </div>
        </div>
      </div>
    );
  }
);

export default ProgramHeader;
