import { ReactNode } from "react";
import classNames from "classnames";
import { isNil } from "ramda";

import { createTranslation, TranslationNS } from "../../../../translation";
import { CheckIcon, DeleteIcon, WarningIcon } from "../../../icons/svg";
import Button from "../Button/Button";
import { UploadedFile } from "../FileUploader/FileUploader";
import InputFeedback from "../input-blocks/input-feedback/input-feedback";
import ProgressCircle from "../ProgressCircle/ProgressCircle";
import classes from "./FileItem.module.scss";

const t = createTranslation(TranslationNS.common, "components.fileUploader.fileItem");

// THIS file item is result of developing file items with different figma UI and approaches
// now it contains 2 ideas - leave the latest one file item component that is using in figma
// keep the old one file item component that is using the statuses property and display different statuses with its own icons and messages
// this component is using typescript overload technique that might mislead devs during reading
// the main goal is to save 2 types for file property - File and UploadedFile types using.
// to do this 3 components with the same name are created to give the possibility to determine typescript the necessary types by its own

export enum FileItemStatuses {
  waiting,
  uploading,
  uploaded,
  error,
}

export type FileItemProps<T> = JSX.IntrinsicElements["div"] & {
  status?: FileItemStatuses;
  file?: T extends File ? File : UploadedFile;
  errorMessage?: ReactNode;
  uploaded?: number;
  onCancel?(): void;
  onReplace?(): void;
  onRemove?(): void;
};

// Type guard to check if the file is an UploadedFile
function isUploadedFileType(file?: any): file is UploadedFile {
  return Boolean(file && typeof file === "object" && "fileName" in file && "fileId" in file && "downloadId" in file);
}

// first overload
function FileItem(props: FileItemProps<UploadedFile>): JSX.Element;

// second overload
// eslint-disable-next-line no-redeclare
function FileItem(props: FileItemProps<File>): JSX.Element;

// actual code
// eslint-disable-next-line no-redeclare
function FileItem<T>(props: FileItemProps<T>) {
  if (isUploadedFileType(props?.file)) {
    // this jsx is the latest one got from file multiple uploader
    const { file, onRemove } = props as FileItemProps<UploadedFile>;
    return (
      <div data-testid="file-item-test-id" className={classes.fileItem}>
        <span className="text-start">{file?.fileName}</span>
        {onRemove ? (
          <div className={classes.deleteBlock}>
            <DeleteIcon
              data-testid="delete-button-test-id"
              className={classes.deleteIcon}
              fontSize="24px"
              onClick={onRemove}
            />
          </div>
        ) : null}
      </div>
    );
  } else {
    // this jsx is the previous one that has latests statuses
    const { file, className, onReplace, onRemove, onCancel, errorMessage, status, uploaded } =
      props as FileItemProps<File>;

    return (
      <div
        className={classNames(
          "py-1 ps-4 mb-1",
          classes.fileItem,
          {
            "pe-4": !onCancel && !onReplace && !onRemove,
          },
          className
        )}
        {...props}
      >
        {!isNil(status) ? (
          <div className={`me-4 ${classes["percentage"]}`}>
            {status === FileItemStatuses.waiting && <ProgressCircle className="icon-size-4" loading />}
            {status === FileItemStatuses.uploading && (
              <ProgressCircle className="icon-size-4" loading={uploaded === 0} now={uploaded} />
            )}
            {status === FileItemStatuses.uploaded && <CheckIcon className="icon-size-4 text-success" />}
            {status === FileItemStatuses.error && (
              <WarningIcon className={classNames("icon-size-4", classes["icon-error"])} />
            )}
          </div>
        ) : null}

        <div className="flex-grow-1 overflow-hidden">
          {status === FileItemStatuses.uploaded ? (
            <a
              className={`ui-s ${classes["file-link"]}`}
              href={window?.URL?.createObjectURL(!isUploadedFileType(props.file) ? (props?.file as File) : new Blob())}
              target="_blank"
              rel="noreferrer"
            >
              {file?.name}
            </a>
          ) : (
            file?.name
          )}
          {!!errorMessage && <InputFeedback isTouched error={errorMessage as string} />}
        </div>

        {!!onCancel && (
          <Button className={classes["cancel-button"]} variant="tertiary" size="s" onClick={onCancel}>
            {t("cancel")}
          </Button>
        )}
        {!!onReplace && (
          <Button variant="tertiary" size="s" onClick={onReplace}>
            {t("replace")}
          </Button>
        )}
        {!!onRemove && (
          <Button variant="tertiary" size="s" onClick={onRemove}>
            {t("remove")}
          </Button>
        )}
      </div>
    );
  }
}

export default FileItem;
