import { FC, useCallback, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as R from "ramda";

import { getEMPath } from "app/Router/RouterHelper";
import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import Button from "common/components/atoms/Button/Button";
import LoaderContainer from "common/components/atoms/LoaderContainer/LoaderContainer";
import Tag from "common/components/atoms/Tag/Tag";
import { P, Ui } from "common/components/atoms/Typography";
import EventHistory from "common/components/molecules/EventHistory/EventHistory";
import useDocumentTitleUpdate from "common/hooks/useDocumentTitleUpdate";
import PageContent from "common/layout/MainLayout/PageContent/PageContent";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { PlusIcon } from "../../../../common/icons/svg";
import Pools from "./components/Pools/Pools";
import DeleteItem from "./DeleteItem/DeleteItem";
import EditPool from "./EditPool/EditPool";
import EditProgram from "./EditProgram/EditProgram";
import classes from "./PoolsAndPrograms.module.scss";
import PoolsAndProgramsStore from "./store";

const [t, tCommon] = [
  createTranslation(TranslationNS.pages, "company.poolsAndPrograms"),
  createTranslation(TranslationNS.common, "noAccess"),
];

const PoolsAndPrograms: FC = () => {
  useDocumentTitleUpdate(t("title"));

  const navigate = useNavigate();
  const { companyId } = useParams();

  const { isLoading, poolsAndPrograms } = useStoreState((state) => state.company);
  const getPoolsAndProgramsThunk = useStoreActions((state) => state.company.getPoolsAndProgramsThunk);
  const { hasFullAccess } = useFeatures(FEATURES.poolsAndPrograms);

  const isEmpty = useMemo(
    () => R.and(R.and(!isLoading, !R.isNil(poolsAndPrograms)), R.isEmpty(poolsAndPrograms?.pools)),
    [poolsAndPrograms, isLoading]
  );

  const createNewPoolHandler = useCallback(() => navigate(getEMPath(["createPool", "basic"])), [navigate]);

  useEffect(() => {
    getPoolsAndProgramsThunk(companyId);
  }, [companyId, getPoolsAndProgramsThunk]);

  const redirectToCreatePools = useCallback(() => {
    navigate(getEMPath(["createPool", "basic"]));
  }, [navigate]);

  return (
    <PoolsAndProgramsStore.Provider>
      <PageContent>
        <PageContent.Header>
          <div className="d-flex align-items-center">
            <PageContent.Header.Title className="me-2">{t("title")}</PageContent.Header.Title>
            {!hasFullAccess && <Tag variant="access">{tCommon("viewOnly")}</Tag>}
          </div>

          <Button
            isFocusDisabled
            onClick={createNewPoolHandler}
            isDisabled={!hasFullAccess}
            tooltipTitle={!hasFullAccess ? tCommon("viewOnly") : undefined}
            tooltipMessage={!hasFullAccess ? tCommon("tooltip") : undefined}
            iconLeft={<PlusIcon />}
          >
            {t("addNewPoolOrProgram.newPool")}
          </Button>
        </PageContent.Header>

        <LoaderContainer loading={isLoading}>
          {isEmpty && (
            <div className={classes["empty"]}>
              <Ui.l bold className="mb-2">
                {t("empty.title")}
              </Ui.l>

              <P.m className="mb-4">
                {t.el("empty.description", {
                  components: [
                    <>
                      <br />
                      <br />
                    </>,
                  ],
                })}
              </P.m>

              <Button isDisabled={!hasFullAccess} tooltipMessage={tCommon("tooltip")} onClick={redirectToCreatePools}>
                {t("empty.createFirstPool")}
              </Button>
            </div>
          )}

          <div className={classes["pools-wrap"]}>
            <Pools />
          </div>

          {poolsAndPrograms?.eventHistory && !!poolsAndPrograms?.eventHistory.length && (
            <EventHistory data={poolsAndPrograms?.eventHistory || []} collapsible />
          )}
        </LoaderContainer>
      </PageContent>

      <EditPool />
      <EditProgram />
      <DeleteItem />
    </PoolsAndProgramsStore.Provider>
  );
};

export default PoolsAndPrograms;
