import { FC, useCallback, useMemo } from "react";
import { Form, useFormikContext } from "formik";
import { defaultTo, isNil } from "ramda";

import { Helper, P, SlidePanel, SubscriptionLimitsReached } from "common/components/atoms";
import StakeholderSection, {
  StakeholderContextProps,
} from "common/components/molecules/StakeholderSection/StakeholderSection";
import { PlanDocumentStatusEnum } from "common/enums/enum";
import { ToastFormikValidator } from "common/hooks/useToastFormikValidator";
import GeneratePlanDocument from "common/plan/generatePlanDocument/generatePlanDocument";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import ExerciseClause from "../../../steps/basics/sidebar/form-parts/exercise-clause/exercise-clause";
import VestingPlanDetails from "../../../steps/basics/sidebar/form-parts/VestingPlanDetails/VestingPlanDetails";
import { PlanFormType } from "../../../steps/basics/sidebar/forms/plan-form";
import { PlanForm } from "../../../types";
import Footer from "../../form-parts/footer/footer";
import StockOptionsDetails from "../../form-parts/stock-options/stock-options-details";

type FormDataProps = {
  isEdit?: boolean;
  handleClose: (isOpen: boolean) => void;
};
const t = createTranslation(TranslationNS.pages, "createPlan.basics.planSidebar");

const FormData: FC<FormDataProps> = ({ handleClose, isEdit }) => {
  const selectedProgram = useStoreState((state) => state.programModel.program);

  const { handleSubmit, isSubmitting, values } = useFormikContext<PlanForm>();

  const isFormDisabled =
    !isNil(values.subscriptionAvailable) && defaultTo(0, values.subscriptionAvailable.remainingAgreements) <= 0;

  const handleAddPlan = useCallback(() => {
    handleSubmit();
  }, [handleSubmit]);

  const updatedFields = useMemo<StakeholderContextProps["updatedFields"]>(() => {
    let fields: StakeholderContextProps["updatedFields"] = undefined;
    if (values.sendEmailInvitation) {
      fields = { email: { isTopField: true, meta: { isOptional: false } } };
    }

    if (Number(values.documentStatusId) === PlanDocumentStatusEnum.GENERATE && values.isCompanyOwned) {
      fields = { ...fields, organizationNumber: { isTopField: true, meta: { isOptional: false } } };
    }

    return fields;
  }, [values.documentStatusId, values.isCompanyOwned, values.sendEmailInvitation]);

  return (
    <Form>
      <ToastFormikValidator />
      <Helper>
        <SlidePanel.Section
          title={<Helper.Question questionId="receiverDetails">{t("receiverBasics")}</Helper.Question>}
        >
          <Helper.Answer
            className="mb-3"
            answerId="receiverDetails"
            text={t("receiverDetailsAnswer")}
            linkText={t("receiverDetailsReadMore")}
            link="/"
          />
          <StakeholderSection updatedFields={updatedFields} isAgreementsFlow />

          {isFormDisabled ? (
            <div className="mt-3">
              <SubscriptionLimitsReached numberOfPlans={values.subscriptionAvailable?.maximumNumberAgreements || 0} />
            </div>
          ) : null}
        </SlidePanel.Section>

        <SlidePanel.Section data-testid="stockOptionsCollapsible" title={t("stockOptionsDetails")}>
          <StockOptionsDetails
            shareClass={selectedProgram?.shareClassName}
            planType={selectedProgram?.instrumentTypeId}
          />
        </SlidePanel.Section>

        <SlidePanel.Section
          data-testid="vestingDetailsCollapsible"
          title={<Helper.Question questionId="vestingDetails">{t("vestingDetails")}</Helper.Question>}
        >
          <Helper.Answer
            className="mb-3"
            answerId="vestingDetails"
            text={t("vestingDetailsAnswer")}
            linkText={t("vestingDetailsReadMore")}
            link="/"
          />
          <VestingPlanDetails />
        </SlidePanel.Section>

        <SlidePanel.Section
          isDividerVisible
          data-testid="exerciseClauseCollapsible"
          title={<Helper.Question questionId="exerciseClause">{t("exerciseClause")}</Helper.Question>}
        >
          <Helper.Answer
            className="mb-3"
            answerId="exerciseClause"
            text={t("exerciseClauseAnswer")}
            linkText={t("exerciseClauseReadMore")}
            link="/"
          />

          <ExerciseClause md={9} />
        </SlidePanel.Section>

        <SlidePanel.Section
          isDividerVisible={false}
          data-testid="exerciseDocumentCollapsible"
          title={<Helper.Question questionId="document">{t("document")}</Helper.Question>}
        >
          <Helper.Answer answerId="document" text={t("documentAnswer")} />

          <P.m>{t("documentsDescription")}</P.m>

          <GeneratePlanDocument autoGenerateAgreement managerSignature planFormType={PlanFormType.SOPlan} />
        </SlidePanel.Section>

        <Footer isEdit={isEdit} handleAddPlan={handleAddPlan} handleClose={handleClose} isSubmitting={isSubmitting} />
      </Helper>
    </Form>
  );
};
export default FormData;
