import { FC, useCallback } from "react";
import { useParams } from "react-router-dom";

import { notify } from "common/utils/notify/notifyFunction";
import { createTranslation, TranslationNS } from "translation";

import { useTransactionsService } from "../../../transactions-service";
import { BuySellGetResponseDTO } from "../../../types";
import CreateBuySellTransactionForm from "./create-buy-sell-transaction-form";
import EditBuySellTransactionForm from "./edit-buy-sell-transaction-form";
import { EditBuySellFields } from "./form-fields";

type PropsTypes = {
  isBuySellFormOpen: boolean;
  buySellTransactionToEdit: BuySellGetResponseDTO | null;
  onSubmit: (transactionId?: string, keepFormOpen?: boolean) => void;
  onClose: () => void;
  isDisabled?: boolean;
};

const t = createTranslation(TranslationNS.pages, "company.transactions");

const BuySellContainer: FC<PropsTypes> = ({
  isBuySellFormOpen,
  buySellTransactionToEdit,
  onSubmit,
  onClose,
  isDisabled,
}) => {
  const { companyId = "0" } = useParams<{ companyId: string }>();
  const { postBuySellTransaction, editBuySellTransaction } = useTransactionsService(companyId);

  const handleSubmitCreateBuySellForm = useCallback(
    async (transaction: FormData, restartForm?: boolean) => {
      const createdSuccessfully = await postBuySellTransaction(transaction);

      if (createdSuccessfully) {
        notify(t("successTransactionCreation"), true, "success", 5000, false, "top-center");

        onSubmit(String(0), restartForm);
        return true;
      } else {
        return false;
      }
    },
    [onSubmit, postBuySellTransaction]
  );

  const handleSubmitEditBuySellForm = useCallback(
    async (transaction: FormData) => {
      const editedSuccessfully = await editBuySellTransaction(transaction);

      if (editedSuccessfully) {
        notify(t("successTransactionEditing"), true, "success", 5000, false, "top-center");
        if (buySellTransactionToEdit?.transactionBundleId) {
          onSubmit(String(buySellTransactionToEdit.transactionBundleId));
        }
        onSubmit(String(transaction.get(EditBuySellFields.transactionId)) || undefined);
        return true;
      } else {
        return false;
      }
    },
    [buySellTransactionToEdit?.transactionBundleId, editBuySellTransaction, onSubmit]
  );

  return (
    <>
      {isBuySellFormOpen && (
        <CreateBuySellTransactionForm
          onSubmit={handleSubmitCreateBuySellForm}
          onClose={onClose}
          isDisabled={isDisabled}
        />
      )}

      {buySellTransactionToEdit && (
        <EditBuySellTransactionForm
          onSubmit={handleSubmitEditBuySellForm}
          onClose={onClose}
          transactionToEdit={buySellTransactionToEdit}
        />
      )}
    </>
  );
};

export default BuySellContainer;
