import React, { FC, forwardRef, ReactNode, SyntheticEvent, useCallback } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { DropDirection } from "react-bootstrap/esm/DropdownContext";
import cn from "classnames";

import classes from "./ContextMenu.module.scss";

type ContextMenuToggleProps = {
  children?: ReactNode;
  withPropagation?: boolean;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  additionalButtonClick?: () => Promise<boolean>;
};

// As we have a complex dropdown solution - some default features aren't working as expected
// for issue with when we need to do some action and hide item after - you can call this function
// it will be executed - when you pass forceHideDropdown property
const clickOutsideTheDOM = () => {
  document.body.click();
};

const ContextMenuToggle = forwardRef<HTMLDivElement, ContextMenuToggleProps>(
  ({ children, withPropagation, onClick, additionalButtonClick }, ref) => {
    const handleClick = useCallback(
      (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();

        if (withPropagation) {
          e.stopPropagation();
        }

        if (additionalButtonClick) {
          additionalButtonClick().then((res) => {
            onClick(e);
          });
          return;
        }

        onClick(e);
      },
      [additionalButtonClick, onClick, withPropagation]
    );
    return (
      <div ref={ref} onClick={handleClick} className="w-100">
        {children}
      </div>
    );
  }
);

export type ContextMenuProps = {
  children?: ReactNode;
  className?: string;
  drop?: DropDirection;
  chevronAnimation?: boolean;
  // this prop is using to determine - do we need stop propagation for toggle or not
  withPropagation?: boolean;
  //
  additionalButtonClick?: () => Promise<boolean>;
  items: {
    key: string;
    label?: ReactNode;
    icon?: ReactNode;
    // to force click body
    forceHideDropdown?: boolean;
    onClick?: (e?: SyntheticEvent) => void;
    type?: "delete" | "terminate";
    color?: string;
    isDisabled?: boolean;
    noBorder?: boolean;
    selected?: boolean;
  }[];
};
const ContextMenu: FC<ContextMenuProps> = ({
  children,
  className,
  items,
  additionalButtonClick,
  drop = "end",
  withPropagation,
  chevronAnimation, // to animate it you need to add class "chevron" to the icon. Example <Button iconRight={<ChevronDownIcon className="chevron" />}>
}) => {
  return (
    <Dropdown drop={drop} className={cn(classes.dropdown, className, { [classes.chevronAnimation]: chevronAnimation })}>
      <Dropdown.Toggle
        as={ContextMenuToggle}
        withPropagation={withPropagation}
        additionalButtonClick={additionalButtonClick}
      >
        {children}
      </Dropdown.Toggle>

      <Dropdown.Menu className={classes.menu}>
        {items.map((item, index) => {
          return (
            <Dropdown.Item
              data-testid="plan-action-btn"
              disabled={item.isDisabled}
              onClick={(e) => {
                e?.stopPropagation();

                item?.onClick?.(e);

                if (item?.forceHideDropdown) {
                  clickOutsideTheDOM();
                }
              }}
              key={index}
              className={cn(classes.item, {
                [classes.delete]: item.type === "delete",
                [classes.terminate]: item.type === "terminate",
                [classes["no-border"]]: item.noBorder === true,
                [classes["selected"]]: item.selected === true,
                [classes["disabled"]]: item.isDisabled,
              })}
            >
              {item.icon && <i>{item.icon}</i>}
              {item.label && <div className={classes.label}>{item.label}</div>}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ContextMenu;
