import { FC } from "react";

import { OwnershipProgramTableData } from "common/types/Collapsible.types";

import ProgramTableRow from "./ProgramTableRow";

type ProgramTableContentProps = {
  programID?: number;
  data: OwnershipProgramTableData[];
};

const ProgramTableContent: FC<ProgramTableContentProps> = ({ data, programID }) => {
  return (
    <tbody>
      {data.map((el) => {
        return <ProgramTableRow row={el} key={el.id} programID={programID} />;
      })}
    </tbody>
  );
};

export default ProgramTableContent;
