import { FC, useCallback } from "react";
import { useParams } from "react-router-dom";

import { notify } from "common/utils/notify/notifyFunction";
import { createTranslation, TranslationNS } from "translation";

import { useTransactionsService } from "../../../transactions-service";
import CreateSplitTransactionForm from "./create-split-transaction-form";
import EditSplitTransactionForm from "./edit-split-transaction-form";
import { EditSplitFields } from "./form-fields";

type PropsTypes = {
  isSplitFormOpen: boolean;
  splitTransactionToEdit: any;
  onSubmit: (transactionId?: string, keepFormOpen?: boolean) => void;
  onClose: () => void;
  isSplitDisabled: boolean;
  minTransactionDate?: string;
};

const t = createTranslation(TranslationNS.pages, "company.transactions");

const SplitContainer: FC<PropsTypes> = ({
  isSplitFormOpen,
  onSubmit,
  onClose,
  splitTransactionToEdit,
  isSplitDisabled,
  minTransactionDate,
}) => {
  const { companyId = "0" } = useParams<{ companyId: string }>();
  const { postSplitTransaction, editSplitTransaction } = useTransactionsService(companyId);

  const handleSubmitCreateSplitForm = useCallback(
    async (transaction: FormData) => {
      const createdSuccessfully = await postSplitTransaction(transaction);

      if (createdSuccessfully) {
        notify(t("successTransactionCreation"), true, "success", 5000, false, "top-center");

        onSubmit(String(0));
        return true;
      } else {
        return false;
      }
    },
    [onSubmit, postSplitTransaction]
  );

  const handleSubmitEditSplitForm = useCallback(
    async (transaction: FormData) => {
      const editedSuccessfully = await editSplitTransaction(transaction);

      if (editedSuccessfully) {
        notify(t("successTransactionEditing"), true, "success", 5000, false, "top-center");

        onSubmit(String(transaction.get(EditSplitFields.transactionId)) || undefined);
        return true;
      } else {
        return false;
      }
    },
    [editSplitTransaction, onSubmit]
  );
  return (
    <>
      {isSplitFormOpen && (
        <CreateSplitTransactionForm
          onSubmit={handleSubmitCreateSplitForm}
          onClose={onClose}
          minTransactionDate={minTransactionDate}
          isSplitDisabled={isSplitDisabled}
        />
      )}
      {splitTransactionToEdit && (
        <EditSplitTransactionForm
          transactionToEdit={splitTransactionToEdit}
          onSubmit={handleSubmitEditSplitForm}
          onClose={onClose}
          minTransactionDate={minTransactionDate}
        />
      )}
    </>
  );
};

export default SplitContainer;
