import { useMemo } from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

const t = createTranslation(TranslationNS.validation);

const useBankDetailsForm = () => {
  const { companyId } = useParams<{ companyId: string }>();

  const bankInformation = useStoreState((state) => state.company.bankInformation);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        accountCompanyName: Yup.string().required(t("required")),
        accountNumber: Yup.string().required(t("required")),
        bankCountryId: Yup.number().min(1, t("required")).required(t("required")),
        bankCity: Yup.string().required(t("required")),
      }),
    []
  );

  const initialValues = useMemo(
    () => ({
      companyId: companyId ? +companyId : 0,
      bankInformationId: bankInformation?.bankInformationId || null,
      accountCompanyName: bankInformation?.accountCompanyName || "",
      accountNumber: bankInformation?.accountNumber || "",
      swiftBic: bankInformation?.swiftBic || "",
      bankCountryId: Number(bankInformation?.bankCountryId) || 0,
      bankCity: bankInformation?.bankCity || "",
    }),
    [companyId, bankInformation]
  );

  return { validationSchema, initialValues };
};

export default useBankDetailsForm;
